import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormControlLabel, Switch } from '@material-ui/core'

import { OzonStoresForm } from './OzonStoresForm'
import { VatEnabledSwitch } from './VatEnabledSwitch'
import { AccountCommissions, AccountCommissionsProps } from './AccountCommissions'
import { MoySkladSalesReturnForm, MoySkladSalesReturnFormProps } from './MoySkladSalesReturnForm'
import { MoySkladDataFormContainer, MoySkladDataFormContainerProps } from './MoySkladDataFormContainer'
import { StoreAccountPriceUpdateForm, StoreAccountPriceUpdateFormProps } from './StoreAccountPriceUpdateForm'

import { useApi } from '../hooks/storeHook'

import { required } from '../common/objectUtils'
import { SetStateFunc } from '../common/tsUtils'

import { OzonAccountProps } from '../types/accountProps'
import { StoreType } from '../server/mpsklad_core/Entity/StoreType'

export type MoySkladPropertiesSelectorPropsBase =
  AccountCommissionsProps
  & Pick<StoreAccountPriceUpdateFormProps, 'isUpdatePrices' | 'setUpdatePrices'>
  & {
  isLoading: boolean

  isGeneratingPayments: boolean

  setGeneratingPayments: SetStateFunc<boolean>

  msSalesReturnStoreId: MoySkladSalesReturnFormProps['selectedStore']

  setMsSalesReturn: MoySkladSalesReturnFormProps['onChange']

  setMsData: MoySkladDataFormContainerProps['onChange']
}

export type MoySkladOzonPropertiesSelectorProps =
  OzonAccountProps & MoySkladPropertiesSelectorPropsBase

export const MoySkladOzonPropertiesSelector =
  observer(
    ({
       account,
       isLoading,
       isGeneratingPayments,
       documentsCommissionsRecording,
       ordersCommissionsRecording,
       isUsingCommissions,
       msSalesReturnStoreId,
       isUpdatePrices,
       setMsData,
       setUsingCommissions,
       setDocumentsCommissionsRecording,
       setGeneratingPayments,
       setOrdersCommissionsRecording,
       setMsSalesReturn,
       setUpdatePrices
     }: MoySkladOzonPropertiesSelectorProps) => {
      const api = useApi()

      // TODO: That's a lot of prop drilling
      return (
        <Box padding={2}>
          <Box marginBottom={3}>
            <MoySkladDataFormContainer msData={null} storeType={StoreType.Ozon} storeAccount={account}
                                       onChange={setMsData}/>
          </Box>

          {
            account &&
            <>
              <Box marginBottom={3}>
                <OzonStoresForm account={account}/>
              </Box>

              <Box marginBottom={3}>
                <MoySkladSalesReturnForm
                  account={account}
                  selectedStore={msSalesReturnStoreId}
                  onChange={setMsSalesReturn}
                  onSubmitAutoReturnEnabled={api.userSync.setOzonAutoReturnEnabled}
                />
              </Box>

              <Box marginBottom={3}>
                <AccountCommissions
                  isUsingCommissions={isUsingCommissions}
                  setUsingCommissions={setUsingCommissions}
                  ordersCommissionsRecording={ordersCommissionsRecording}
                  setOrdersCommissionsRecording={setOrdersCommissionsRecording}
                  documentsCommissionsRecording={documentsCommissionsRecording}
                  setDocumentsCommissionsRecording={setDocumentsCommissionsRecording}
                />
              </Box>

              <Box marginBottom={3}>
                <StoreAccountPriceUpdateForm
                  isUpdatePrices={isUpdatePrices}
                  setUpdatePrices={setUpdatePrices}
                  onCheckPrices={api.prices.checkPriceActivationOzon}
                  onEnablePriceSync={api.prices.enablePriceSyncOzon}
                  onForceUpdatePrices={() => api.userSync.updatePricesOzon(required(account).id)}
                  description={`Все цены, имеющие поле "Цена Ozon" больше нуля, будут обновлены, если соответствуют условиям.
 Чтобы убрать зачёркнутую и Premium цены на Ozon, поставьте ноль для этих параметров.`}
                />
              </Box>

              <Box marginBottom={3} marginTop={4}>
                <VatEnabledSwitch account={account} onSubmit={api.userSync.setOzonVatEnabled}/>
              </Box>

              <Box marginBottom={3}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isLoading}
                      checked={isGeneratingPayments}
                      onChange={({target: {checked}}) => setGeneratingPayments(checked)}
                    />
                  }
                  label="Автоматически создавать входящий платёж"
                />
              </Box>
            </>
          }
        </Box>
      )
    })
import { makeStyles } from '@material-ui/core/styles'

export const useOrderSearchStyles = makeStyles(() => ({
  root: {
    '& .MuiFormLabel-root': {
      color: '#D2D2D2',
      fontSize: 12,
      lineHeight: '14.4px'
    },
    '& .MuiInputLabel-formControl': {
      top: '2px'
    },
    '& .MuiFormHelperText-root': {
      color: '#888888'
    }
  }
}))
import React from 'react'
import { observer } from 'mobx-react-lite'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'

import { MyTableHead } from './MyTableHead'

import { useStore } from '../../hooks/storeHook'

import { MyProductColumnGroupId } from '../../types/myProductColumnGroups'

export const MyProductsTableHead =
  observer(() => {
    const classes = useStyles()

    const {
      myProductColumns,
      visibleMyProductColumns,
      productStore: {myProducts}
    } = useStore()

    const getColSpan =
      (group: MyProductColumnGroupId) =>
        myProductColumns.filter(_ => _.group === group && visibleMyProductColumns.includes(_)).length

    const myColSpan = getColSpan(MyProductColumnGroupId.MyStorage)
    const ozonColSpan = getColSpan(MyProductColumnGroupId.Ozon)
    const wbColSpan = getColSpan(MyProductColumnGroupId.Wildberries)
    const ymColSpan = getColSpan(MyProductColumnGroupId.YandexMarket)

    return (
      <MyTableHead
        table={myProducts}
        visibleColumns={visibleMyProductColumns}
        groupHeader={<>
          {
            myColSpan > 0 &&
            <TableCell
              colSpan={myColSpan}
              className={clsx(classes.groupCell, classes.moySklad)}
            >
              МойСклад
            </TableCell>
          }

          {
            ozonColSpan > 0 &&
            <TableCell
              colSpan={ozonColSpan}
              className={clsx(classes.groupCell, classes.ozon)}
            >
              Ozon
            </TableCell>
          }

          {
            wbColSpan > 0 &&
            <TableCell
              colSpan={wbColSpan}
              className={clsx(classes.groupCell, classes.wildberries)}
            >
              Wildberries
            </TableCell>
          }

          {
            ymColSpan > 0 &&
            <TableCell
              colSpan={ymColSpan}
              className={clsx(classes.groupCell, classes.yandexMarket)}
            >
              Yandex.Market
            </TableCell>
          }
        </>}
      />
    )
  })

const useStyles = makeStyles(
  ({palette}) => ({
      groupCell: {
        padding: '4px 15px',
        borderRight: '2px solid #D2D2D2'
      },
      moySklad: {
        background: palette.moySklad.backgroundDim,
        color: palette.moySklad.textDim,
        borderTopLeftRadius: '5px',
        height: '32px'
      },
      ozon: {
        background: palette.ozon.background,
        color: palette.ozon.text
      },
      wildberries: {
        background: palette.wildberries.background,
        color: palette.wildberries.text
      },
      yandexMarket: {
        background: palette.yandexMarket.background,
        color: palette.yandexMarket.text
      }
    }
  ))
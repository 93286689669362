import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { SingleProductSyncError } from './SingleProductSyncError'
import { MultiProductSyncErrors } from './MultiProductSyncErrors'

import { MyRowProps } from '../../types/tableTypes'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'

export const MyProductSyncErrorsCell =
  observer(
    ({row}: MyRowProps<MyProductModel>) => {
      return (
        <TableCell className="table-border" style={{minWidth: 68}}>
          <MultiProductSyncErrors type={'Ozon'} products={row.ozonProducts}/>

          <MultiProductSyncErrors type={'Wildberries'} products={row.wbProducts}/>

          <SingleProductSyncError type={'MoySklad'} product={row}/>
        </TableCell>
      )
    })
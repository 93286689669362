import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button, CircularProgress, FormHelperText, Grid, MenuItem, Select, Typography } from '@material-ui/core'

import { AutoReturnEnabledSwitch } from './AutoReturnEnabledSwitch'
import { RemovingDocumentsOnCancelledOrderSwitch } from './RemovingDocumentsOnCancelledOrderSwitch'

import { useApi } from '../hooks/storeHook'
import { LoadState, useLoadState } from '../hooks/loadStateHook'

import { fixMsAppScrollProps } from '../common/msAppUtils'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { SetAccountToggleModel } from '../server/mpsklad_core/Models/SetAccountToggleModel'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'
import { MoySkladApiEntityOption } from '../server/mpsklad_core/Models/MoySkladApiEntityOption'

export type MoySkladSalesReturnFormProps = {
  account: StoreAccountModelBase

  selectedStore: string | undefined

  onChange: (value: string | undefined) => void

  onSubmitAutoReturnEnabled: (model: SetAccountToggleModel) => Promise<void>

  onSubmitAutoRemoveCancelledMsOrderDocs?: (model: SetAccountToggleModel) => Promise<void>
}

export const MoySkladSalesReturnForm =
  observer(
    ({
       account,
       onSubmitAutoReturnEnabled,
       onSubmitAutoRemoveCancelledMsOrderDocs,
       onChange,
       selectedStore
     }: MoySkladSalesReturnFormProps) => {
      const api = useApi()
      const {loadState, setLoading, setSuccess, setError} = useLoadState()

      const [msStores, setMsStores] = useState<MoySkladApiEntityOption[]>([])
      const menuClasses = useDefaultMenuItemStyles()

      const onLoad = async () => {
        setLoading()

        try {
          const msStores = await api.userSync.getMoySkladStores()

          setMsStores(msStores)
          setSuccess()
        } catch (e) {
          console.error('Failed to load', e)
          setError()
        }
      }

      return (
        <Box>
          <Box marginBottom={1}>
            <Typography variant="h6">
              Настройка возвратов
            </Typography>
          </Box>

          {
            (loadState === LoadState.Initial || loadState === LoadState.Error) &&
            <Button type="button" variant="contained" onClick={onLoad}>Настроить</Button>
          }

          {
            loadState === LoadState.Loading &&
            <CircularProgress size={25}/>
          }

          {
            loadState === LoadState.Success &&
            <>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={6}>
                  Склад возвратов МоегоСклада
                </Grid>

                <Grid item xs={6}>
                  <Select
                    value={selectedStore}
                    onChange={e => onChange(e.target.value as string | undefined)}
                    MenuProps={fixMsAppScrollProps}
                  >
                    <MenuItem>&mdash;</MenuItem>
                    {
                      msStores.map(_ =>
                        <MenuItem
                          className={menuClasses.menuItem}
                          key={_.id} value={_.id}
                        >
                          {_.name}
                        </MenuItem>)
                    }

                  </Select>
                </Grid>

                <Grid item xs={6}>
                  <FormHelperText>
                    Склад, на который будет создан возврат.
                    <br/>
                    Укажите отдельный склад для возвратов, отличный от основных настроек складов.
                    <br/>
                    Например, склад "Возвраты".
                  </FormHelperText>
                </Grid>

                <Grid item xs={6}>
                  <AutoReturnEnabledSwitch
                    account={account}
                    onSubmit={onSubmitAutoReturnEnabled}
                  />
                </Grid>

                {
                  !!onSubmitAutoRemoveCancelledMsOrderDocs &&
                  <Grid item xs={6}>
                    <RemovingDocumentsOnCancelledOrderSwitch
                      account={account}
                      onSubmit={onSubmitAutoRemoveCancelledMsOrderDocs}/>
                  </Grid>
                }
              </Grid>
            </>
          }
        </Box>
      )
    })
import React, { FormEvent } from 'react'
import { autorun, comparer, reaction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { add, getUnixTime, isBefore, isSameDay, set, startOfDay } from 'date-fns'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup
} from '@material-ui/core'

import { DateTimeRangePicker } from './DateTimeRangePicker'
import { PrintMsBarcodesCheckbox } from './PrintMsBarcodesCheckbox'
import { LabelsPostDateFilters, maxShipmentDate, minShipmentDate } from './LabelsPostDateFilters'

import { useApi } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { exact } from '../common/tsUtils'
import { wait } from '../common/commonUtils'
import { dateUtils } from '../common/dateTimeUtility'

import { OzonAccountIdProps } from '../types/accountProps'
import { OrdersAtDateCount } from '../types/ordersAtDateCount'
import { ResponseCancelledError } from '../types/responseCancelledError'

import { OzonLabelFormat } from '../server/mpsklad_common/Models/OzonLabelFormat'
import { OzonPrintOptions } from '../server/mpsklad_core/Models/OzonPrintOptions'
import { OzonPrintOrderModel } from '../server/mpsklad_core/Models/OzonPrintOrderModel'
import { PrintStoreOrderWithShipmentModel } from '../server/mpsklad_core/Models/PrintStoreOrderWithShipmentModel'

export type LabelsTabStateBase = {
  startDate: Date
  endDate: Date
  maxDate: Date

  pickedShipmentDate: Date | null
  shipmentDateMin: Date
  shipmentDateMax: Date

  useAutoReset: boolean
  hasPurchaseList: boolean
  hasMsBarcodes: boolean
}

export type OzonLabelsTabOptions =
  LabelsTabStateBase & {
  hasOrderNumbers: boolean
  format: OzonLabelFormat

  readonly printRequestData: Readonly<OzonPrintOptions>
  readonly previewRequestData: Readonly<OzonPrintOptions>
}

// TODO: Use or remove, search for other unused components
export const OzonLabelsTab =
  observer(
    ({ozonAccountId}: OzonAccountIdProps) => {
      const api = useApi()

      const {showSuccess} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()

      const options =
        useLocalObservable(() =>
          exact<OzonLabelsTabOptions>({
            // NOTE: Effects are required for initial values that depend on props or state
            startDate: dateUtils.startOfYesterday,
            endDate: dateUtils.endOfToday,
            maxDate: dateUtils.endOfToday,
            // TODO: Not only this is not controlled, its computation is non-trivial...
            pickedShipmentDate: null,
            shipmentDateMin: minShipmentDate,
            shipmentDateMax: maxShipmentDate,
            useAutoReset: false,
            hasPurchaseList: true,
            hasOrderNumbers: true,
            hasMsBarcodes: false,
            format: OzonLabelFormat.AmountNameCode,
            get printRequestData() {
              return exact<OzonPrintOptions>({
                accountId: ozonAccountId,
                startDateUnix: getUnixTime(this.startDate),
                endDateUnix: getUnixTime(this.endDate),
                shipmentDateMin: this.shipmentDateMin.toISOString(),
                shipmentDateMax: this.shipmentDateMax.toISOString(),
                useAutoReset: this.useAutoReset,
                hasPurchaseList: this.hasPurchaseList,
                hasOrderNumbers: this.hasOrderNumbers,
                hasMsBarcodes: this.hasMsBarcodes,
                format: this.format
              })
            },
            get previewRequestData(): Readonly<OzonPrintOptions> {
              return exact<OzonPrintOptions>({
                ...this.printRequestData,
                // TODO: Make these filters server-side for absolute consistency
                // TODO: Use startOfDay, endOfDay in Unix-time to preserve client-side timezone on the backend
                // These filters are applied client-side for the preview, thus we request with default values
                shipmentDateMin: minShipmentDate.toISOString(),
                shipmentDateMax: maxShipmentDate.toISOString()
              })
            }
          }))

      const localState =
        useLocalObservable(() => ({
          preview: null as OzonPrintOrderModel[] | null,
          previewWithShipmentFilters: null as OzonPrintOrderModel[] | null,
          get shipmentDateCounts(): OrdersAtDateCount[] {
            return getOrderShipmentDateCounts(this.preview)
          }
        }))

      useLazyEffect(() =>
        autorun(() => {
          const {previewWithShipmentFilters, shipmentDateMin, shipmentDateMax} =
            filterPreviewByShipmentDate(options.pickedShipmentDate, localState.preview, localState.shipmentDateCounts)

          localState.previewWithShipmentFilters = previewWithShipmentFilters
          options.shipmentDateMin = shipmentDateMin
          options.shipmentDateMax = shipmentDateMax
        }))

      // Fetch preview orders from backend when options change
      useLazyEffect(() =>
        reaction(
          () => options.previewRequestData,
          async () => {
            try {
              localState.preview = null
              localState.preview = await api.previewOzonLabelsPreemptive(options.previewRequestData)

              // Reset filter by shipment date when other options change
              // TODO: This is required because LabelsPostDateFilters is not controlled, make it
              options.pickedShipmentDate = null
            } catch (e) {
              if (e instanceof ResponseCancelledError) {
                // Ignore
                return
              } else {
                throw e
              }
            }
          }, {
            fireImmediately: true,
            equals: comparer.shallow
          }))

      const onDateChange =
        (startDate: Date, endDate: Date) => {
          options.startDate = startDate
          options.endDate = endDate
        }

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (isSubmitting) {
            return
          }

          setSubmitting()

          try {
            const {fileUrl, errorCount} = await api.label.printOzon(options.printRequestData)

            if (errorCount === 0) {
              showSuccess('Создано!')
            } else {
              showSuccess(`Создано! Отправлений с ошибками: ${errorCount}.`)
            }

            await wait(500)
            window.location.assign(fileUrl)
          } finally {
            setSubmitted()
          }
        }

      const onPostDateChange =
        (newDate: Date | null) =>
          options.pickedShipmentDate = newDate

      return (
        <Box padding={2}>
          <form onSubmit={onSubmit}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={4}>
                <FormHelperText>
                  {/* TODO: Take from backend? */}
                  Печать этикеток возможна только для заказов в статусе "Ожидает отгрузки".
                  <br/>
                  Возможна печать до 300 заказов в одном файле.
                </FormHelperText>
              </Grid>

              <Grid item xs={4}>
                Дата поступления заказа
              </Grid>

              <DateTimeRangePicker
                containerProps={{xs: 3}}
                startDate={options.startDate}
                endDate={options.endDate}
                maxDate={options.maxDate}
                onChange={onDateChange}
              />

              <Grid item xs={4}>
                Дата для поставки в Ozon
              </Grid>

              <LabelsPostDateFilters
                overallCount={localState.preview?.length ?? 0}
                daysCounts={localState.shipmentDateCounts}
                onFilter={onPostDateChange}
              />

              <Grid item container xs={6}>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Что печатать
                    </FormLabel>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="feedCheckbox"
                            checked
                            disabled
                          />
                        }
                        label="Лента"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="purchaseListCheckbox"
                            checked={options.hasPurchaseList}
                            onChange={e => options.hasPurchaseList = e.target.checked}
                          />
                        }
                        label="Список покупок"
                      />

                      <PrintMsBarcodesCheckbox
                        checked={options.hasMsBarcodes}
                        onChange={checked => options.hasMsBarcodes = checked}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Настройки списка покупок
                    </FormLabel>

                    <RadioGroup
                      name="formatRadioGroup"
                      value={options.format}
                      onChange={e => options.format = parseInt(e.target.value)}
                    >
                      <FormControlLabel
                        label="Шт. - Имя [КОД]"
                        control={<Radio color="primary"/>}
                        value={OzonLabelFormat.AmountNameCode}
                        disabled={!options.hasPurchaseList}
                      />

                      <FormControlLabel
                        label="КОД - шт."
                        control={<Radio color="primary"/>}
                        value={OzonLabelFormat.CodeAmount}
                        disabled={!options.hasPurchaseList}
                      />
                    </RadioGroup>

                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="includeOrderNumberCheckbox"
                          checked={options.hasOrderNumbers}
                          onChange={e => options.hasOrderNumbers = e.target.checked}
                        />
                      }
                      label="Вставлять номера заказов"
                      disabled={!options.hasPurchaseList}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="autoResetCheckbox"
                          checked={options.useAutoReset}
                          onChange={e => options.useAutoReset = e.target.checked}
                        />
                      }
                      label="Использовать автоматическое обнуление"
                    />

                    <FormHelperText>
                      Распечатанные заказы будут вычеркнуты, и в следующий раз будут взяты только новые заказы.
                    </FormHelperText>
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Button color="secondary" variant="contained" type="submit" disabled={isSubmitting}>
                  {
                    isSubmitting
                    ? 'Создаём...'
                    : 'Создать этикетки'
                  }
                </Button>
              </Grid>

              <Grid item xl={6}>
                <Box marginBottom={2}>
                  Предпросмотр
                </Box>

                <Paper>
                </Paper>
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    })

export const filterPreviewByShipmentDate =
  <TOrder extends PrintStoreOrderWithShipmentModel>(
    newDate: Date | null,
    preview: TOrder[] | null,
    dateCounts: OrdersAtDateCount[]
  ): {
    previewWithShipmentFilters: TOrder[] | null
    shipmentDateMin: Date
    shipmentDateMax: Date
  } => {
    if (preview == null || newDate == null) {
      return {
        previewWithShipmentFilters: preview,
        shipmentDateMin: minShipmentDate,
        shipmentDateMax: maxShipmentDate
      }
    }

    const maxLocalDate = set(dateCounts[dateCounts.length - 1]?.date ?? minShipmentDate, {hours: 24})

    if (isBefore(maxLocalDate, newDate)) {
      return {
        shipmentDateMin: maxLocalDate,
        shipmentDateMax: maxShipmentDate,
        previewWithShipmentFilters: preview.filter(order => isBeforeByDate(maxLocalDate, order.shipmentDate))
      }
    }

    const previewFiltered = preview.filter(
      order => order.shipmentDate && isSameDay(new Date(order.shipmentDate), newDate))

    // This relies on preview being ordered by shipmentDate by the backend
    const filteredShipmentDateMin = previewFiltered[0]?.shipmentDate
    const filteredShipmentDateMax = previewFiltered[previewFiltered.length - 1]?.shipmentDate

    return {
      previewWithShipmentFilters: previewFiltered,
      shipmentDateMin: filteredShipmentDateMin ? new Date(filteredShipmentDateMin) : minShipmentDate,
      shipmentDateMax: filteredShipmentDateMax ? new Date(filteredShipmentDateMax) : maxShipmentDate
    }
  }

/**
 * Computes amounts of orders that have shipment date that equals today, tomorrow and the day after tomorrow.
 */
export const getOrderShipmentDateCounts =
  (orders: PrintStoreOrderWithShipmentModel[] | null): OrdersAtDateCount[] => {
    if (orders == null) {
      return []
    }

    const now = dateUtils.now

    const dateCounts = [
      {date: now, count: 0},
      {date: add(now, {days: 1}), count: 0},
      {date: add(now, {days: 2}), count: 0}
    ]

    for (const order of orders) {
      if (!order.shipmentDate) {
        continue
      }

      const orderShipmentDate = new Date(order.shipmentDate)

      const orderDateCount = dateCounts.find(_ => isSameDay(_.date, orderShipmentDate))

      if (orderDateCount) {
        orderDateCount.count++
      }
    }

    return dateCounts.filter(_ => _.count > 0)
  }

/**
 * Determines whether leftDate is less than rightDate with date precision.
 * TODO: Make rightDate a Date?
 */
const isBeforeByDate =
  (leftDate: Date, rightDate: string | null | undefined): boolean =>
    !!rightDate && isBefore(startOfDay(leftDate), startOfDay(new Date(rightDate)))
import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useLogic, useStore } from '../../hooks/storeHook'
import { useMessages } from '../../hooks/snackbarHooks'
import { useBoolState } from '../../hooks/commonHooks'

import { MoySkladOrderFeeType } from '../../server/mpsklad_core/Models/MoySkladOrderFeeType'
import { MoySkladAttributeModel } from '../../server/mpsklad_core/Models/MoySkladAttributeModel'

import { required } from '../../common/objectUtils'
import { StoreType } from '../../server/mpsklad_core/Entity/StoreType'
import { AdditionalPropertiesSettingsField } from './AdditionalPropertiesSettingsField'
import { YandexMarketAdditionalPropertiesSettings } from './YandexMarket/YandexMarketAdditionalPropertiesSettings'
import { AdditionalPropertiesSettingsActionButtons } from './AdditionalPropertiesSettingsActionButtons'

export type AdditionalPropertiesSettingsProps = {
  msAttributes: MoySkladAttributeModel[]

  onCreate: (createdAttr: MoySkladAttributeModel) => void
}
export const AdditionalPropertiesSettings =
  observer(({
              msAttributes,
              onCreate
            }: AdditionalPropertiesSettingsProps) => {
    const {showSuccess, showError} = useMessages()

    const store = useStore()
    const logic = useLogic()

    /**
     *
     * Ensures that the given id exists in MS.
     * @param id
     */
    const validateMsAttributeId = useCallback(
      (id: string | undefined): string | undefined => {
        const q = msAttributes.some(_ => _.id === id)
        console.log('q' + q)
        return msAttributes.some(_ => _.id === id) ? id : undefined
      }, [msAttributes])

    const [isLoading, setLoading, setLoaded] = useBoolState()

    const [commissionId, setCommissionId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.commissionFeeAttributeId))

    const [magistralId, setMagistralId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.magistralFeeAttributeId))

    const [lastMileId, setLastMileId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.lastMileFeeAttributeId))

    const [restId, setRestId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.logisticsRestFeeAttributeId))

    const [isCreating, setCreating, setCreated] = useBoolState()

    const classes = useStyles()

    useEffect(() => {
        setMagistralId(validateMsAttributeId(store.syncStore.moySkladAccount?.magistralFeeAttributeId))
        setCommissionId(validateMsAttributeId(store.syncStore.moySkladAccount?.commissionFeeAttributeId))
        setLastMileId(validateMsAttributeId(store.syncStore.moySkladAccount?.lastMileFeeAttributeId))
        setRestId(validateMsAttributeId(store.syncStore.moySkladAccount?.logisticsRestFeeAttributeId))
      },
      [msAttributes, store.syncStore.moySkladAccount?.commissionFeeAttributeId, store.syncStore.moySkladAccount?.lastMileFeeAttributeId, store.syncStore.moySkladAccount?.logisticsRestFeeAttributeId, store.syncStore.moySkladAccount?.magistralFeeAttributeId, validateMsAttributeId])

    const onCreateAttr =
      (feeType: MoySkladOrderFeeType) =>
        async () => {
          setCreating()

          try {
            const createdAttr = await logic.api.userSync.createMoySkladOrderFeeAttribute(feeType)

            onCreate(createdAttr)

          } catch (e) {
            console.error('Failed to created MS attr', e)
            showError('Не удалось создать поле')
          } finally {
            setCreated()
          }
        }

    const onSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      setLoading()

      try {
        await logic.setMsOrderAttributes({
          commissionFeeAttributeId: commissionId,
          magistralFeeAttributeId: magistralId,
          lastMileFeeAttributeId: lastMileId,
          logisticsRestFeeAttributeId: restId
        })

        showSuccess('Поля сохранены!')
      } catch (e) {
        console.error('Failed to set MoySklad attrs', e)
        showError('Произошла ошибка при сохранении полей!')
      } finally {
        setLoaded()
      }
    }

    if (!store.syncStore.moySkladAccount || !msAttributes) {
      return null
    }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>ДОПОЛНИТЕЛЬНЫЕ ПОЛЯ ЗАКАЗА</Typography>
        <Grid container spacing={3}>

          <AdditionalPropertiesSettingsField value={commissionId} setValue={setCommissionId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttr(MoySkladOrderFeeType.Commission)}
                                             labelName={'Коммисия'}/>

          <AdditionalPropertiesSettingsField value={lastMileId} setValue={setCommissionId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttr(MoySkladOrderFeeType.LastMile)}
                                             labelName={'Последняя миля'}/>

          <AdditionalPropertiesSettingsField value={magistralId} setValue={setMagistralId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttr(MoySkladOrderFeeType.Magistral)}
                                             labelName={'Настройка работ с ценами'}/>

          <AdditionalPropertiesSettingsField value={restId} setValue={setRestId} options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttr(MoySkladOrderFeeType.Assembly)}
                                             labelName={'Сборка'}/>

        </Grid>

        <AdditionalPropertiesSettingsActionButtons isLoading={isLoading} onSubmit={onSubmit}/>

        {
          store.allowedIntegrations[StoreType.YandexMarket] &&
          <YandexMarketAdditionalPropertiesSettings msAttributes={msAttributes}
                                                    validateMsAttributeId={validateMsAttributeId}
                                                    onCreateAttribute={onCreateAttr}/>
        }
      </Box>
    )
  })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 40px 40px',
      width: '100%',
      maxWidth: 'calc(100vw - 340px)'
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      margin: '10px 10px 30px 10px'
    }
  })
)
import { IntegrationTypeKey, integrationTypeNames } from './integrationTypeUtils'

import { Replace } from '../common/tsUtils'

import { NavState } from '../server/mpsklad_core/Models/NavState'
import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'
import { MyProductState } from '../server/mpsklad_core/Models/MyProductState'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

/**
 * UI allows a nullable accountId, but not backend.
 **/
export type HomeNavState =
  Replace<NavState, {
    accountId: number | undefined
  }>

export type NavStore =
  Readonly<
    HomeNavState & {
    // TODO: (redesign) Why is header tab different from storeType? UI doesn't seem to allow it
    // TODO: (redesign) And a nullable SideBarTab is allowed (which seems to just default to Configuration now)
    headerTab: HeaderTab,
    sideBarTab: SideBarTab,
    productsStateTab: MyProductState,
  }>

// TODO: Think about string enums - they allow subsets, keys are typechecked better, but need backend to send strings
// See https://devimalplanet.com/typescript-how-to-extend-one-enum-from-another
// TODO: (redesign) Replace with IntegrationType fully?
export enum HeaderTab {
  Ozon = IntegrationType.Ozon,
  Wildberries = IntegrationType.Wildberries,
  YandexMarket = IntegrationType.YandexMarket,
  MoySklad = IntegrationType.MoySklad
}

export enum SideBarTab {
  Products,
  Orders,
  Configuration,
  Print,
  Import,
  Logs
}

export const headerTabByNavStoreType: {
  [navStoreType in NavStoreType]: HeaderTab
} = {
  [NavStoreType.Ozon]: HeaderTab.Ozon,
  [NavStoreType.Wildberries]: HeaderTab.Wildberries,
  [NavStoreType.YandexMarket]: HeaderTab.YandexMarket
}

export const integrationTypeByNavStoreType: {
  [navStoreType in NavStoreType]: IntegrationType
} = {
  [NavStoreType.Ozon]: IntegrationType.Ozon,
  [NavStoreType.Wildberries]: IntegrationType.Wildberries,
  [NavStoreType.YandexMarket]: IntegrationType.YandexMarket
}

export const integrationTypeKeyByNavStoreType: {
  [navStoreType in NavStoreType]: IntegrationTypeKey
} = {
  [NavStoreType.Ozon]: 'Ozon',
  [NavStoreType.Wildberries]: 'Wildberries',
  [NavStoreType.YandexMarket]: 'YandexMarket'
}

export const getNavStoreTypeName =
  (navStoreType: NavStoreType): string =>
    integrationTypeNames[integrationTypeByNavStoreType[navStoreType]]
import React, { ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'

import { CircularProgress } from '@material-ui/core'
import { IOSSwitch } from './IOSSwitch'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { AccountModelBase } from '../server/mpsklad_core/Models/AccountModelBase'
import { SetAccountToggleModel } from '../server/mpsklad_core/Models/SetAccountToggleModel'

export type AccountToggleSwitchProps<TAccount extends AccountModelBase> = {
  account: TAccount

  onConfirm?: (isEnabled: boolean) => Promise<boolean>

  onSubmit: (model: SetAccountToggleModel) => Promise<void>

  getAccountToggle: () => boolean

  setAccountToggle: (isEnabled: boolean) => void

  getSuccessMessage?: (isEnabled: boolean) => string
}

export const AccountToggleSwitch =
  observer(
    <TAccount extends AccountModelBase>
    ({
       account, getAccountToggle, setAccountToggle, getSuccessMessage,
       onConfirm, onSubmit
     }: AccountToggleSwitchProps<TAccount>) => {
      const {showSuccess, showError} = useMessages()

      const [isLoading, setLoading, setFinished] = useBoolState()

      const onChange =
        async (e: ChangeEvent<HTMLInputElement>) => {
          const isEnabled = e.target.checked

          setLoading()

          try {
            if (onConfirm && !await onConfirm(isEnabled)) {
              return
            }

            await onSubmit({accountId: account.id, isEnabled})
            setAccountToggle(isEnabled)

            showSuccess(getSuccessMessage?.(isEnabled) ?? (isEnabled ? 'Включёно' : 'Отключено'))
          } catch (e) {
            console.log('Failed to toggle an account flag', e)
            showError('Ошибка при сохранении')
          } finally {
            setFinished()
          }
        }

      return (
        isLoading
        ? <CircularProgress size={19}/>
        : <IOSSwitch
          color="primary"
          checked={getAccountToggle()}
          onChange={onChange}
        />
      )
    })
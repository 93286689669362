import React from 'react'
import { observer } from 'mobx-react-lite'
import { sortBy } from 'lodash'

import { SelectProps } from '@material-ui/core/Select/Select'
import { FormHelperText, MenuItem, Select } from '@material-ui/core'

import { BootstrapInput } from './MoySkladAttributeSelect'
import { WarehouseDisplayProps, WarehouseKeyProps } from './StoresFormByRelation'

import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { required } from '../common/objectUtils'
import { fixMsAppScrollProps } from '../common/msAppUtils'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { EditStoresModel } from '../server/mpsklad_core/Models/EditStoresModel'

export type StorePickerBaseProps = {
  formData: EditStoresModel
}

export type WarehousePickerProps<TWarehouse extends WarehouseModel> =
  StorePickerBaseProps
  & WarehouseKeyProps<TWarehouse>
  & Pick<WarehouseDisplayProps<TWarehouse>, 'formatWarehouseDisplayName'>
  & {
  formStore: EditStoreModel
  warehouses: TWarehouse[]
  onChange: (warehouse: TWarehouse) => void
}

export const WarehousePicker =
  observer(
    <TWarehouse extends WarehouseModel>
    ({
       formStore, formData, warehouses,
       whKeySelector, storeWhKeySelector,
       formatWarehouseDisplayName,
       onChange
     }: WarehousePickerProps<TWarehouse>) => {
      const menuClasses = useDefaultMenuItemStyles()

      const selectedWhKey = storeWhKeySelector(formStore)
      const selectedWarehouse = warehouses.find(_ => whKeySelector(_) === selectedWhKey)

      const warehouseOptions =
        sortBy(
          warehouses.filter(wh => formData.stores.every(_ => storeWhKeySelector(_) !== whKeySelector(wh)))
                    .concat(selectedWarehouse ?? []),
          whKeySelector)

      const onSelectChange: SelectProps['onChange'] =
        ({target: {value: newValue}}) => {
          switch (typeof newValue) {
            case 'number':
            case 'string':
              onChange(required(warehouses.find(wh => whKeySelector(wh) === newValue)))
              return

            default:
              throw new Error(`Bad value for warehouse select: ${newValue}, ${typeof newValue}`)
          }
        }

      return (
        <Select
          value={selectedWhKey}
          onChange={onSelectChange}
          MenuProps={fixMsAppScrollProps}
          input={<BootstrapInput/>}
          style={{maxWidth: '100%', overflow: 'hidden'}}
        >
          {
            !selectedWarehouse &&
            <MenuItem disabled className={menuClasses.menuItem} value={selectedWhKey}>
              <FormHelperText error>Не найден в маркетплейсе: {selectedWhKey}</FormHelperText>
            </MenuItem>
          }

          {
            warehouseOptions.map(warehouse =>
              <MenuItem
                className={menuClasses.menuItem}
                key={whKeySelector(warehouse)}
                value={whKeySelector(warehouse)}
              >
                {formatWarehouseDisplayName(warehouse)}
              </MenuItem>)
          }
        </Select>
      )
    })
import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'
import { useApi, useStore } from '../../../hooks/storeHook'

import { ImportScreen } from '../../ImportScreen'
import { OzonProductImportTable } from '../../OzonProductImportTable'

import { OzonProductsTableData } from '../../../store/productStore'
import { OzonProductModel } from '../../../server/mpsklad_core/Models/OzonProductModel'
import { OzonProductColumnId } from '../../../types/ozonColumns'

export const OzonImportPage =
  observer(() => {
    const api = useApi()
    const {productStore: {createOzonProductsTable}, getHomeOzonAccount} = useStore()

    const account = getHomeOzonAccount()

    const products = useMemo(
      () => createOzonProductsTable(account.id),
      [createOzonProductsTable, account])

    return (
      <ImportScreen<OzonProductsTableData, OzonProductModel, OzonProductColumnId>
        account={account}
        type="Ozon"
        importProductsFunc={api.import.importProductsOzon}
        importStocksFunc={api.import.importStocksOzon}
        products={products}
        TableComponent={OzonProductImportTable}
      />
    )
  })
import { RouteItem } from '../../common/routeItem'

export const wildberriesPrintRoutes: RouteItem[] = [
  {route: 'labels', title: 'Этикетки'},
  {route: 'files', title: 'Файлы'}
]

export const ozonPrintRoutes: RouteItem[] = [
  {route: 'labels', title: 'Этикетки'},
  {route: 'files', title: 'Файлы'}
]

export const yandexMarketPrintRoutes: RouteItem[] = [
  {route: 'labels', title: 'Этикетки'},
  {route: 'files', title: 'Файлы'}
]
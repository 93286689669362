import { computed, makeObservable, observable } from 'mobx'

import { SyncStore } from './syncStore'
import { AdminStore } from './adminStore'
import { LabelStore } from './labelStore'
import { OrderStore } from './orderStore'
import { ProductStore } from './productStore'
import { PasswordResetStore } from './passwordResetStore'
import { MyProductsTableData } from './myProductsTableData'

import { required } from '../common/objectUtils'
import { hasEnumFlag, valuesOf } from '../common/tsUtils'

import { DialogInfo } from '../types/dialogInfo'
import { myWbColumns } from '../types/wbColumns'
import { myOzonColumns } from '../types/ozonColumns'
import { HeaderTab, NavStore, SideBarTab } from '../types/navStore'
import { MyProductColumn } from '../types/myProductColumnTypes'
import { createMyStorageColumns } from '../types/myStorageColumns'
import { myYandexMarketColumns } from '../types/yandexMarketColumns'
import { AllowedIntegrationsMap } from '../types/integrationTypeUtils'
import { MyProductColumnGroupId } from '../types/myProductColumnGroups'
import { MyProductColumnId, syncErrorsColumnId } from '../types/myProductColumnIds'

import { NavState } from '../server/mpsklad_core/Models/NavState'
import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'
import { MoySkladAppType } from '../server/mpsklad_common/MoySkladAppType'
import { UserAuthModel } from '../server/mpsklad_core/Models/UserAuthModel'
import { MyProductState } from '../server/mpsklad_core/Models/MyProductState'
import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'
import { YandexMarketAccountModel } from '../server/mpsklad_core/Models/YandexMarketAccountModel'

export class Store {
  @observable
  allowRedesign: boolean

  @observable
  user: UserAuthModel | null

  /**
   * Alternative to cookie auth.
   * Used for MS app, because Safari doesn't allow third party iframe cookies.
   */
  @observable
  authToken: string | null

  /**
   * NOTE: Don't use for reactions - not readonly.
   * NOTE: Can't make the type writable - causes inconsistent initial state.
   * Use {@link getHomeNav} instead.
   */
  @observable
  private _homeNav: NavStore | null

  @observable
  readonly syncStore: SyncStore

  @observable
  readonly productStore: ProductStore

  @observable
  readonly orderStore: OrderStore

  @observable
  readonly labelStore: LabelStore

  @observable
  readonly adminStore: AdminStore

  @observable
  readonly passwordResetStore: PasswordResetStore

  @observable.ref
  dialog: DialogInfo | null

  /**
   * Ids of hidden column are stored instead of visible to automatically show future columns.
   * TODO: Move inside TableData?
   */
  @observable
  hiddenMyProductColumnIds: Set<MyProductColumnId>

  constructor() {
    makeObservable(this)

    this.allowRedesign = false

    this.user = null
    this.authToken = null

    this._homeNav = null

    this.syncStore = new SyncStore()
    this.productStore = new ProductStore(new MyProductsTableData(this.getHomeNav))
    this.orderStore = new OrderStore()
    this.labelStore = new LabelStore()

    this.adminStore = new AdminStore()
    this.passwordResetStore = new PasswordResetStore()

    // TODO: UI store?
    this.dialog = null
    this.hiddenMyProductColumnIds = new Set()
  }

  @computed
  get hasAuth(): boolean {
    return this.user !== null
  }

  @computed
  get hasMoySkladApp(): boolean {
    return this.user?.moySkladAppState != null
  }

  @computed
  get canAddYandexMarketAccount(): boolean {
    if (this.user == null) {
      return false
    }

    const {moySkladAppType} = this.user

    return moySkladAppType == null
           || moySkladAppType === MoySkladAppType.Ym
           || moySkladAppType === MoySkladAppType.OzonAndWb
  }

  /**
   * TODO: (redesign) Check usages, replace with homeNavRequired where possible
   */
  getHomeNav =
    (): NavStore | null => {
      // NOTE: Making this property a class method breaks some mobx reactions.
      // NOTE: This is a method instead of a getter to prevent tracking nested properties directly.
      // NOTE: _homeNav must not be tracked directly - it's not readonly.
      return this._homeNav
    }

  /**
   * NOTE: Don't track via mobx.
   * Using within observer components should be fine because of destructuring.
   */
  @computed
  get homeNavRequired(): NavStore {
    if (this._homeNav == null) {
      throw new Error('homeNav is not initialized')
    }

    return this._homeNav
  }

  /**
   * NOTE: Don't track via mobx.
   * Using within observer components should be fine because of destructuring.
   */
  @computed
  get navState(): NavState {
    const homeNav = this.homeNavRequired

    return {
      storeType: homeNav.storeType,
      accountId: required(homeNav.accountId)
    }
  }

  getHomeOzonAccount =
    (): OzonAccountModel => {
      if (this.homeNavRequired.storeType !== NavStoreType.Ozon) {
        throw new Error(`Bad home nav account type: ${this.homeNavRequired.storeType}, expected ${NavStoreType.Ozon}`)
      }

      const homeNavAccountId = required(this.homeNavRequired.accountId)
      return required(this.syncStore.ozonAccounts.find(_ => _.id === homeNavAccountId))
    }

  getHomeWbAccount =
    (): WbAccountModel => {
      if (this.homeNavRequired.storeType !== NavStoreType.Wildberries) {
        throw new Error(
          `Bad home nav account type: ${this.homeNavRequired.storeType}, expected ${NavStoreType.Wildberries}`)
      }

      const homeNavAccountId = required(this.homeNavRequired.accountId)
      return required(this.syncStore.wbAccounts.find(_ => _.id === homeNavAccountId))
    }

  getHomeYmAccount =
    (): YandexMarketAccountModel => {
      if (this.homeNavRequired.storeType !== NavStoreType.YandexMarket) {
        throw new Error(
          `Bad home nav account type: ${this.homeNavRequired.storeType}, expected ${NavStoreType.YandexMarket}`)
      }

      const homeNavAccountId = required(this.homeNavRequired.accountId)
      return required(this.syncStore.yandexMarketAccounts.find(_ => _.id === homeNavAccountId))
    }

  homeHasStoreType =
    (storeType: NavStoreType): boolean =>
      hasEnumFlag(this.homeNavRequired.storeType, storeType)
      && this.syncStore.getStoreAccounts(storeType).length > 0

  /**
   * TODO: (redesign) Check usages
   */
  setHomeNav =
    (storeType: NavStoreType,
     accountId: number | undefined,
     headerTab: HeaderTab,
     sideBarTab: SideBarTab) => {
      if (accountId && accountId <= 0) {
        throw new Error('accountId must be positive')
      }

      this._homeNav = {
        storeType,
        accountId,
        productsStateTab: MyProductState.Active,
        headerTab,
        sideBarTab
      }
    }

  setNavFirstAccount =
    (storeType: NavStoreType,
     sideBarTab: SideBarTab,
     headerTab: HeaderTab,
     accountId: number | undefined) => {
      if (accountId && accountId <= 0) {
        throw new Error('accountId must be positive')
      }

      const nav = this.getHomeNav()

      if (!nav) {
        return this._homeNav = {
          storeType,
          accountId,
          sideBarTab,
          headerTab,
          productsStateTab: MyProductState.Active
        }
      }

      this._homeNav = {
        ...nav,
        storeType,
        sideBarTab,
        accountId
      }
    }

  setSideBarTab =
    (sideBarTab: SideBarTab) => {
      const homeNav = this.getHomeNav()

      // TODO: (redesign) Throw errors instead of potentially invalid UI state, see methods below also
      if (homeNav) {
        this._homeNav = {
          ...homeNav,
          sideBarTab
        }
      }
    }

  setHeaderTab =
    (headerTab: HeaderTab) => {
      const homeNav = this.getHomeNav()

      if (homeNav) {
        this._homeNav = {
          ...homeNav,
          headerTab
        }
      }
    }

  unsetHomeNavAccount = () => {
    if (!this._homeNav) {
      return
    }

    this._homeNav = {...this._homeNav, accountId: undefined}
  }

  setHomeTab =
    (value: MyProductState) => {
      this._homeNav = {
        ...this.homeNavRequired,
        productsStateTab: value
      }
    }

  @computed
  get myProductColumnsGrouped(): {
    [Group in MyProductColumnGroupId]: MyProductColumn[]
  } {
    return {
      [MyProductColumnGroupId.MyStorage]:
        createMyStorageColumns(this.homeHasStoreType, this.productStore.myProductsInfo, this.allowedIntegrations),

      [MyProductColumnGroupId.Ozon]:
        this.homeHasStoreType(NavStoreType.Ozon) && this.allowedIntegrations[StoreType.Ozon]
        ? myOzonColumns
        : [],

      [MyProductColumnGroupId.Wildberries]:
        this.homeHasStoreType(NavStoreType.Wildberries) && this.allowedIntegrations[StoreType.Wildberries]
        ? myWbColumns
        : [],

      [MyProductColumnGroupId.YandexMarket]:
        this.homeHasStoreType(NavStoreType.YandexMarket) && this.allowedIntegrations[StoreType.YandexMarket]
        ? myYandexMarketColumns
        : []
    }
  }

  @computed
  get myProductColumns() {
    return valuesOf(this.myProductColumnsGrouped).flatMap((_) => _)
  }

  // TODO: Move inside TableData?
  @computed
  get visibleMyProductColumns(): MyProductColumn[] {
    let visibleColumns = this.myProductColumns.filter(column => !this.hiddenMyProductColumnIds.has(column.id))

    if (this.homeNavRequired.productsStateTab !== MyProductState.Error) {
      visibleColumns = visibleColumns.filter(column => column.id !== syncErrorsColumnId)
    }

    return visibleColumns
  }

  // Return type is checked by TypeScript
  // eslint-disable-next-line getter-return
  @computed.struct
  get allowedIntegrations(): AllowedIntegrationsMap {
    // NOTE: Making this a method will likely prevent useMemo from working properly
    switch (this.user?.moySkladAppType) {
      case null:
      case undefined:
        return {
          [StoreType.Ozon]: true,
          [StoreType.Wildberries]: true,
          [StoreType.YandexMarket]: true
        }

      // Account checking is only for existing accounts of Ozon and Wb - old MS app limitations were bad
      case MoySkladAppType.Ozon:
        return {
          [StoreType.Ozon]: true,
          [StoreType.Wildberries]: this.syncStore.wbAccounts.length > 0,
          [StoreType.YandexMarket]: false
        }

      case MoySkladAppType.Wb:
        return {
          [StoreType.Ozon]: this.syncStore.ozonAccounts.length > 0,
          [StoreType.Wildberries]: true,
          [StoreType.YandexMarket]: false
        }

      case MoySkladAppType.OzonAndWb:
        return {
          [StoreType.Ozon]: true,
          [StoreType.Wildberries]: true,
          [StoreType.YandexMarket]: true
        }

      case MoySkladAppType.Mini:
        return {
          [StoreType.Ozon]: true,
          [StoreType.Wildberries]: true,
          [StoreType.YandexMarket]: false
        }

      case MoySkladAppType.Ym:
        return {
          [StoreType.Ozon]: false,
          [StoreType.Wildberries]: false,
          [StoreType.YandexMarket]: true
        }
    }
  }
}
import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell, TableRow } from '@material-ui/core'

import { required } from '../common/objectUtils'

import { MyTableRowProps } from '../types/tableTypes'
import { CellAccessorFunc } from '../types/cellAccessor'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

export const MyTableRow =
  observer(
    <TColumnId extends string, TRow extends object>
    ({table, visibleColumns, row}: MyTableRowProps<TColumnId, TRow>) => {
      const classes = useTableRowStyles()

      return <TableRow
        hover
        className={clsx('table-border', table.isRowSelected(row) && 'selected_row')}
        tabIndex={-1}
        selected={table.isRowSelected(row)}
        classes={{
          root: classes.tableRowRoot,
          selected: classes.tableRowSelected,
          hover: classes.hover
        }}
      >
        {
          visibleColumns.map(
            ({id, accessor, cellStyle, CellComponent}) =>
              CellComponent
              ? <CellComponent key={id} row={row} table={table}/>
              :
              <TableCell
                key={id}
                className={clsx('table-border', table.isRowSelected(row) && 'selected_row')}
                style={{...cellStyle?.(row), minWidth: 68}}>
                <span>{renderCellAccessor(row, accessor)}</span>
              </TableCell>)
        }
      </TableRow>
    }
  )

const renderCellAccessor =
  <TRow extends object>(row: TRow, accessor: CellAccessorFunc<TRow>): ReactNode =>
    renderCellValue(required(accessor)(row))

export const renderCellValue =
  (value: unknown): ReactNode => {
    if (value instanceof Array) {
      // Render each item on a separate line
      return <>
        {
          value.map((item, index) => <div key={index}>{renderCellValueItem(item)}</div>)
        }
      </>
    }

    return renderCellValueItem(value)
  }

const renderCellValueItem =
  (value: unknown): ReactNode => {
    if (typeof value === 'boolean') {
      return value ? 'Да' : 'Нет'
    }

    return value as ReactNode
  }

const useTableRowStyles = makeStyles(() => ({
  tableRowRoot: {
    '&$tableRowSelected, &$tableRowSelected:hover': {
      backgroundColor: '#E7F4FF'
    }
  },
  hover: {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: '#E7F4FF'
    }
  },
  tableRowSelected: {
    backgroundColor: '#E7F4FF'
  }
}))
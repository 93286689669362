import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { Switch } from 'react-router-dom'

import { useStore } from '../../hooks/storeHook'
import { useRedirectToSubRoute } from '../../hooks/useRedirectToSubRoute'

import { appRoutes } from '../../common/appRoutes'

import { StoreType } from '../../server/mpsklad_core/Entity/StoreType'

import { AppRoute } from '../AppRoute'
import { YandexMarketSettingsPage } from './YandexMarket/YandexMarketSettingsPage'
import { WildberriesSettingsPage } from './Wildberries/WildberriesSettingsPage'
import { OzonSettingsPage } from './Ozon/OzonSettingsPage'
import { MoySkladSettingsPage } from './MoySklad/MoySkladSettingsPage'

export const SettingsRoute = observer(() => {
  const {allowedIntegrations} = useStore()

  useRedirectToSubRoute(appRoutes.Settings.base)

  return (
    <div className="body_wrapper_zero">
      <Box flex={1}>
        <Switch>
          {
            allowedIntegrations[StoreType.YandexMarket] &&
            <AppRoute
              path={appRoutes.Settings.yandexmarket}
              component={YandexMarketSettingsPage}/>
          }

          {
            allowedIntegrations[StoreType.Wildberries] &&
            <AppRoute path={appRoutes.Settings.wildberries}
                      component={WildberriesSettingsPage}/>
          }

          {
            allowedIntegrations[StoreType.Ozon] &&
            <AppRoute path={appRoutes.Settings.ozon}
                      component={OzonSettingsPage}/>
          }

          <AppRoute
            path={appRoutes.Settings.moySklad}
            component={MoySkladSettingsPage}
          />

        </Switch>
      </Box>
    </div>
  )
})
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { useApi, useStore } from '../../../hooks/storeHook'
import { ImportScreen } from '../../ImportScreen'

import { YandexMarketProductsTableData } from '../../../store/productStore'

import { YandexMarketProductModel } from '../../../server/mpsklad_core/Models/YandexMarketProductModel'
import { YandexMarketProductColumnId } from '../../../types/yandexMarketColumns'
import { YandexMarketProductImportTable } from '../../YandexMarketProductImportTable'

export const YandexMarketImportPage =
  observer(
    () => {
      const api = useApi()
      const {productStore: {createYandexMarketProductsTable}, getHomeYmAccount} = useStore()

      const account = getHomeYmAccount()

      const products = useMemo(
        () => createYandexMarketProductsTable(account.id),
        [createYandexMarketProductsTable, account])

      return (
        <ImportScreen<YandexMarketProductsTableData, YandexMarketProductModel, YandexMarketProductColumnId>
          account={account}
          type="YandexMarket"
          importProductsFunc={api.import.importProductsWb}
          importStocksFunc={api.import.importStocksWb}
          products={products}
          TableComponent={YandexMarketProductImportTable}
        />
      )
    })
import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, CircularProgress } from '@material-ui/core'
import { Route, Switch, useHistory } from 'react-router-dom'

import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { useLocalObservableOptional } from '../../../common/mobxUtils'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useLazyEffect } from '../../../hooks/commonHooks'

import { appRoutes } from '../../../common/appRoutes'
import { settingsRoutes } from '../settingsRoutes'

import { required } from '../../../common/objectUtils'
import { exact } from '../../../common/tsUtils'

import { StoreType } from '../../../server/mpsklad_core/Entity/StoreType'
import { EditStoreAccountMsDataModel } from '../../../server/mpsklad_core/Models/EditStoreAccountMsDataModel'
import { MoySkladAttributeModel } from '../../../server/mpsklad_core/Models/MoySkladAttributeModel'
import { MoySkladApiEntityOption } from '../../../server/mpsklad_core/Models/MoySkladApiEntityOption'
import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditWbAccountModel } from '../../../server/mpsklad_core/Models/EditWbAccountModel'

import { StoreAccountMsData } from '../../MoySkladDataForm'
import { CompanySettings } from '../CompanySettings'
import { PricesSettings } from '../PricesSettings'
import { AdditionalPropertiesSettings } from '../AdditionalPropertiesSettings'
import { CommissionsSettings } from '../CommissionsSettings'
import { WildberriesApiSettings } from './WildberriesApiSettings'
import { WildberriesOrdersSettings } from './WildberriesOrdersSettings'
import { WildberriesReturnsSettings } from './WildberriesReturnsSettings'
import { RouteTabItem } from '../../RouteTabItem'
import { RoutingTabs } from '../../RoutingTabs'
import { RouteItem } from '../../../common/routeItem'

export const WildberriesSettingsPage = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const api = useApi()
  const history = useHistory()
  const {showSuccess, showError} = useMessages()

  const accountId = store.getHomeNav()?.accountId
  const account = logic.tryGetWbAccount(accountId)

  const msContainer = useLocalObservableOptional<StoreAccountMsData>()

  const [msStores, setMsStores] = useState<MoySkladApiEntityOption[]>([])

  const [msAttributes, setMsAttributes] = useState<MoySkladAttributeModel[] | null>(null)

  const availableTabs: RouteItem[] = useMemo(() => {
    return accountId ? settingsRoutes : settingsRoutes.map((route: RouteItem, index) => ({
      ...route,
      isDisabled: index !== 0
    }))
  }, [accountId])

  const routes = useMemo((): RouteTabItem[] => {
    return (availableTabs)
      .map((_) => ({title: _.title, route: _.route, isDisabled: _.isDisabled}))
  }, [availableTabs])

  useEffect(() => {
    if (history.location.pathname === appRoutes.Settings.wildberries) {
      history.push(`${appRoutes.Settings.wildberries}/${routes[0].route}`)
    }
  })

  const loadMsStores = async () => {

    try {
      const msStores = await api.userSync.getMoySkladStores()

      setMsStores(msStores)
    } catch (e) {
      console.error('Failed to load', e)
    }
  }

  const loadMoySkladData = async () => {
    try {
      const {organizations, counterparties, contracts, salesChannels, projects} =
        await api.userSync.getMoySkladData(StoreType.Wildberries, account?.id)

      if (organizations.length === 0) {
        showError('Нет организаций в МоёмСкладе')
        return
      }

      if (counterparties.length === 0) {
        showError('Нет контрагентов в МоёмСкладе с типом "Юридическое лицо"')
        return
      }

      msContainer.dataOrNull = {
        organization: {
          options: organizations,
          value: organizations.find(_ => _.id === account?.msOrganization) ?? organizations[0],
          searchTerm: '',
          isSearchLoading: false
        },
        counterparty: {
          options: counterparties,
          value: counterparties.find(_ => _.id === account?.msCounterparty) ?? counterparties[0],
          searchTerm: '',
          isSearchLoading: false
        },
        contract: {
          options: contracts,
          value: contracts.find(_ => _.id === account?.msContractId) ?? null,
          searchTerm: '',
          isSearchLoading: false
        },
        salesChannel: {
          options: salesChannels,
          value: salesChannels.find(_ => _.id === account?.msSalesChannelId) ?? null,
          searchTerm: '',
          isSearchLoading: false
        },
        project: {
          options: projects,
          value: projects.find(_ => _.id === account?.msProjectId) ?? null,
          searchTerm: '',
          isSearchLoading: false
        },
        get formData() {
          return {
            msOrganization: this.organization.value.id,
            msCounterparty: this.counterparty.value.id,
            msContractId: this.contract.value?.id,
            msSalesChannelId: this.salesChannel.value?.id,
            msProjectId: this.project.value?.id
          }
        }
      }
    } catch (e) {
      console.error('Failed to get MoySklad data', e)
      showError('Не удалось загрузить данные из МоегоСклада')
    }
  }

  const onSubmit =
    async (data: EditStoreAccountMsDataModel) => {

      try {
        const formData =
          exact<Omit<EditWbAccountModel, 'id'>>({
            isFBS: account?.isFBS ?? false,
            isUpdatePrices: account?.isUpdatePrices ?? false,
            isGeneratingPayments: account?.isGeneratingPayments ?? false,
            isUsingCommissions: account?.isUsingCommissions ?? false,
            ordersCommissionsRecording: account?.ordersCommissionsRecording
                                        ?? CommissionsRecordingType.Nothing,
            documentsCommissionsRecording: account?.documentsCommissionsRecording
                                           ?? CommissionsRecordingType.Nothing,
            newToken: account?.newToken,
            statTokenNew: undefined,
            tokenCombined: undefined,
            name: account?.name,
            msSalesReturnStoreId: account?.msSalesReturnStoreId,
            msData: data
          })

        if (account) {
          await logic.editWbAccount({id: account.id, ...formData})
        } else {
          await logic.createWbAccount({id: 0, ...formData})
        }

        showSuccess('Сохранено!')
      } catch (e) {
        console.error('Failed to save Wildberries account', e)
      } finally {
      }
    }

  const loadMsAttributes = async () => {
    try {
      const msAttr = await api.userSync.getMoySkladAttributes()
      setMsAttributes(msAttr)
    } catch (e) {
      console.error('Failed to get MoySklad attrs', e)
      showError('Не удалось загрузить')
    }
  }

  useLazyEffect(() => {
    setTimeout(loadMoySkladData)
    setTimeout(loadMsAttributes)
    setTimeout(loadMsStores)
  }, [accountId])

  if (!msContainer.dataOrNull) {
    return <Box
      width="calc(100vw - 240px)" height="100vh" display="flex" alignItems="center"
      justifyContent={'center'}>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <CircularProgress/>
        <p>Загружаем данные из Моего Склада, подождите!</p>
      </Box>
    </Box>
  }

  return <>
    <RoutingTabs
      routeTabs={routes}
      baseRoute={appRoutes.Settings.wildberries}
      subRoute={logic.getSubRoute(store.getHomeNav()?.storeType)}>
      <Switch>
        <Route path={`${appRoutes.Settings.wildberries}/api`}
               render={() => <WildberriesApiSettings/>}/>

        {account &&
         <>
           <Route
             path={`${appRoutes.Settings.wildberries}/company`}
             render={() =>
               <CompanySettings
                 msData={msContainer.data}
                 onChange={onSubmit}/>}
           />
           <Route
             path={`${appRoutes.Settings.wildberries}/orders`}
             render={() =>
               <WildberriesOrdersSettings account={account}/>}
           />
           <Route
             path={`${appRoutes.Settings.wildberries}/warehouses`}
             render={() =>
               <WildberriesOrdersSettings account={account}/>}
           />

           <Route
             path={`${appRoutes.Settings.wildberries}/returns`}
             render={() =>
               <WildberriesReturnsSettings msStores={msStores}/>}
           />
           <Route
             path={`${appRoutes.Settings.wildberries}/commissions`}
             render={() =>
               <CommissionsSettings account={account}/>
             }
           />
           <Route
             path={`${appRoutes.Settings.wildberries}/prices`}
             render={() =>
               <PricesSettings
                 account={account}
                 onCheckPrices={api.prices.checkPriceActivationWb}
                 onEnablePriceSync={api.prices.enablePriceSyncWb}
                 onForceUpdatePrices={() => api.userSync.updatePricesWb(required(account).id)}
               />}
           />
           <Route
             path={`${appRoutes.Settings.wildberries}/additional`}
             render={() =>
               <AdditionalPropertiesSettings
                 msAttributes={required(msAttributes)}
                 onCreate={(createdAttr: MoySkladAttributeModel) => setMsAttributes(
                   [...required(msAttributes), createdAttr])}/>}/>
         </>
        }
      </Switch>
    </RoutingTabs>
  </>
})
import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'

import { useLogic, useStore } from '../../../hooks/storeHook'

import { appRoutes } from '../../../common/appRoutes'
import { yandexMarketPrintRoutes } from '../printRoutes'

import { YandexMarketFilesTab } from '../../YandexMarketFilesTab'
import { YandexMarketLabelsPrintForm } from './YandexMarketLabelsPrint'
import { RoutingTabs } from '../../RoutingTabs'
import { RouteTabItem } from '../../RouteTabItem'

export const YandexMarketPrintPage = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const history = useHistory()

  const account = store.getHomeYmAccount()

  const routes = useMemo((): RouteTabItem[] => {
    const possibleRoutes = (yandexMarketPrintRoutes)
      .map((_) => ({title: _.title, route: _.route}))

    if (!account || account.isFBS) {
      return possibleRoutes
    }

    return possibleRoutes.filter(_ => _.route === 'feeds')

  }, [account])

  useEffect(() => {
    if (history.location.pathname === appRoutes.Print.yandexmarket) {
      history.push(`${appRoutes.Print.yandexmarket}/${routes[0].route}`)
    }
  })

  return (
    <>
      <RoutingTabs
        routeTabs={routes}
        baseRoute={appRoutes.Print.yandexmarket}
        subRoute={logic.getSubRoute(store.getHomeNav()?.storeType)}
      >
        <Switch>
          {
            account.isFBS &&
            <Route
              path={`${appRoutes.Print.yandexmarket}/labels`}
              render={() => <YandexMarketLabelsPrintForm yandexMarketAccountId={account.id}/>}
            />
          }

          {
            account.isFBS &&
            <Route
              path={`${appRoutes.Print.yandexmarket}/files`}
              render={() => <YandexMarketFilesTab yandexMarketAccountId={account.id}/>}
            />
          }

          {
            !account.isFBS &&
            <Box padding={3}>
              Печать этикеток доступна только при схеме работы FBS.
            </Box>
          }
        </Switch>
      </RoutingTabs>
    </>
  )
})
import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { MyTable } from './MyTable'
import { ScreenLoader } from './ScreenLoader'

import { useLogic, useStore } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { yandexMarketFilesColumns } from '../types/yandexMarketFilesColumns'
import { YandexMarketAccountIdProps } from '../types/accountProps'

export const YandexMarketFilesTab =
  observer(
    ({yandexMarketAccountId}: YandexMarketAccountIdProps) => {
      const {labelStore: {yandexMarketFiles}} = useStore()
      const {loadYandexMarketFiles} = useLogic()

      const [isLoading, , setLoaded] = useBoolState(true)

      useLazyEffect(() => loadYandexMarketFiles(yandexMarketAccountId).finally(setLoaded))

      return (
        <Box padding={2} width={'calc(100vw - 300px)'}>
          {
            isLoading
            ? <ScreenLoader/>
            :
            <MyTable
              table={yandexMarketFiles}
              columns={yandexMarketFilesColumns}
              labelNoRows={'Файлов нет'}
              labelNotFound={'Файлы не найдены'}
              labelRowsPerPage={'Файлов на странице:'}
            />
          }

          {
            yandexMarketFiles.totalRowCount >= 500 &&
            <small>
              Показаны последние 500 файлов.
            </small>
          }
        </Box>
      )
    })
import React from 'react'
import { observer } from 'mobx-react-lite'

import {
  CircularProgress
} from '@material-ui/core'

import { MyTable } from './MyTable'

import { ITableData } from '../types/tableTypes'
import { WbPrintOrderColumnId, wbPrintOrderColumns } from '../types/wbColumns'
import { WbPrintOrderModel } from '../server/mpsklad_core/Models/WbPrintOrderModel'
import { BootstrapInput } from './MoySkladAttributeSelect'

export type WbPreviewPrintTableProps = {
  orders: ITableData<WbPrintOrderModel, WbPrintOrderColumnId>
}

export const WbPreviewPrintTable =
  observer(
    ({orders}: WbPreviewPrintTableProps) => {
      if (orders == null) {
        return <CircularProgress size={20}/>
      }

      return (
        <>
          <BootstrapInput
            disabled
            fullWidth
            value={`Выбрано заказов: ${orders.selectedRowCount}`}
            style={{margin: 0}}>
          </BootstrapInput>

          <MyTable
            table={orders}
            columns={wbPrintOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />
        </>)
    })
//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

/**
* TODO: (redesign) Replace with <see cref="T:mpsklad_core.Entity.StoreType" />?
*               Values will be different for outdated frontends - might be a problem
*/
export enum NavStoreType {
  Ozon = 1,
  Wildberries = 2,
  YandexMarket = 4
}

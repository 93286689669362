import { makeStyles } from '@material-ui/core/styles'

export const useCheckboxStyles = makeStyles(
  _ => ({
    checkbox: {
      '& span': {
        color: '#1F364D',
        fontFamily: 'Roboto Regular',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
        textAlign: 'left'
      },
      '& .Mui-disabled span': {
        color: '#888888 !important'
      },
      '& span:not(.MuiTypography-root)': {
        color: '#3987CF'
      }
    },
    checkedCheckbox: {
      '& .MuiTypography-root': {
        color: '#3987CF',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '14.4px',
        textAlign: 'left'
      }
    }
  }))
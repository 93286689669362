import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid, TextField, Typography } from '@material-ui/core'

import { MyServerTable } from './MyServerTable'
import { StoreTableHasMsProductFilter } from './StoreTableHasMsProductFilter'

import { YandexMarketProductsTableData } from '../store/productStore'

import { TableColumn } from '../types/tableTypes'
import { YandexMarketProductColumnId } from '../types/yandexMarketColumns'
import { YandexMarketProductModel } from '../server/mpsklad_core/Models/YandexMarketProductModel'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

export type YandexMarketProductsTableProps = {
  products: YandexMarketProductsTableData

  columns: Array<TableColumn<YandexMarketProductColumnId, YandexMarketProductModel>>
}

export const YandexMarketProductsTable =
  observer(
    ({products, columns}: YandexMarketProductsTableProps) => {
      const classes = useStyles()

      return <Grid item lg={12} xl={12}>
        <Box className={clsx(classes.container, 'default-border')}>
          <Box paddingTop={1} marginY={2} marginLeft={2} display="flex">
            <Typography className={classes.label}>
              Товары
            </Typography>

            <Box marginLeft={25}>
              <TextField
                id="search-yandexMarket"
                size="small"
                variant="outlined"
                label={<span className={classes.input}>Поиск</span>}
                helperText="Id / Наименование / Ваш SKU / Артикул / Штрихкод"
                onChange={e => products.setGlobalFilter(e.target.value)}
                InputProps={{
                  className: classes.input
                }}
              />
            </Box>

            <Box marginTop={1} marginLeft={5}>
              <StoreTableHasMsProductFilter products={products}/>
            </Box>
          </Box>

          <MyServerTable
            tableProps={{size: 'small'}}
            table={products}
            columns={columns}
            labelNoRows="Нет товаров"
            labelNotFound="Товары не найдены"
            labelRowsPerPage="Товаров на странице:"
          />
        </Box>
      </Grid>
    })

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(100vw - 340px)',
    borderRadius: 12,
    padding: 15,
    margin: '20px 10px 0 10px'
  },
  label: {
    fontSize: 14,
    padding: 10
  },
  input: {
    fontSize: 12
  }
}))
import { RouteItem } from '../../common/routeItem'

export const settingsRoutes: RouteItem[] = [
  {route: 'api', title: 'API'},
  {route: 'company', title: 'Компания'},
  {route: 'orders', title: 'Заказы'},
  {route: 'warehouses', title: 'Склады'},
  {route: 'returns', title: 'Возвраты'},
  {route: 'commissions', title: 'Комиссии'},
  {route: 'prices', title: 'Цены'},
  {route: 'additional', title: 'Доп.Поля'}
]

export const moySkladSettingsRoutes: RouteItem[] =
  [
    {route: 'status', title: 'Статусы'},
    {route: 'products', title: 'Товары'},
    {route: 'settings', title: 'Настройка'}
  ]
import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import {
  Box, FormHelperText,
  FormLabel,
  Grid, MenuItem, Select,
  Typography
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { IOSSwitch } from '../../IOSSwitch'

import clsx from 'clsx'
import { useBoolState, useToggleState } from '../../../hooks/commonHooks'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { MoySkladApiEntityOption } from '../../../server/mpsklad_core/Models/MoySkladApiEntityOption'
import { fixMsAppScrollProps } from '../../../common/msAppUtils'
import { BootstrapInput } from '../../MoySkladAttributeSelect'
import { useDefaultMenuItemStyles } from '../../../hooks/defaultSelectStylesHook'

export type YandexMarketReturnsSettingsProps = {
  msStores: MoySkladApiEntityOption[]
}

export const WildberriesReturnsSettings =
  observer(({msStores}: YandexMarketReturnsSettingsProps) => {
    const {showSuccess, showError} = useMessages()

    const logic = useLogic()
    const store = useStore()
    const {
      userSync: {
        setWildberriesReturnsSettings
      }
    } = useApi()

    const account = store.getHomeWbAccount()

    const [isLoading, setIsLoading, setNotLoading] = useBoolState()

    const [msSalesReturnStoreId, setMsSalesReturn] = useState<string | undefined>(account?.msSalesReturnStoreId)

    const [autoReturnsEnabled, toggleAutoReturnEnabled] = useToggleState(account?.isAutoReturnEnabled ?? false)

    const [autoRemoveCancelledMsOrderDocs, toggleAutoRemoveCancelledMsOrderDocs] = useToggleState(
      account?.autoRemoveCancelledMsOrderDocs ?? false)

    const classes = useStyles()

    const menuClasses = useDefaultMenuItemStyles()

    const onSubmit = async () => {
      try {
        setIsLoading()
        await setWildberriesReturnsSettings({
          accountId: account.id,
          isAutoReturnEnabled: autoReturnsEnabled,
          msSalesReturnId: msSalesReturnStoreId,
          autoRemoveCancelledMsOrderDocs: autoRemoveCancelledMsOrderDocs
        })

        setTimeout(logic.loadUserAccounts)

        showSuccess('Настройки возвратов обновлены!')
      } catch (e) {
        showError('Произошла ошибка при настройке возвратов!')
      } finally {
        setNotLoading()
      }
    }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>НАСТРОЙКИ ВОЗВРАТОВ</Typography>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>Склад возвратов МоегоСклада
                  </FormLabel>
                </Box>

                <Select
                  value={msSalesReturnStoreId}
                  onChange={e => setMsSalesReturn(e.target.value as string | undefined)}
                  MenuProps={fixMsAppScrollProps}
                  fullWidth
                  input={<BootstrapInput/>}
                >
                  <MenuItem className={menuClasses.menuItem}>&mdash;</MenuItem>
                  {
                    msStores.map(_ =>
                      <MenuItem
                        className={menuClasses.menuItem}
                        key={_.id}
                        value={_.id}
                      >
                        {_.name}
                      </MenuItem>)
                  }

                </Select>

              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Создание документов о возврате
                  </FormLabel>
                  <IOSSwitch checked={autoReturnsEnabled} onChange={toggleAutoReturnEnabled}/>
                </Box>

              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Удалять документы при статусе Wildberries Отменён
                  </FormLabel>
                  <IOSSwitch checked={autoRemoveCancelledMsOrderDocs} onChange={toggleAutoRemoveCancelledMsOrderDocs}/>
                </Box>

                <FormHelperText className={classes.helperText}>
                  В заказе МоегоСклада будут удалены Отгрузка и Входящий платёж, если они были привязаны.
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <Box display="flex">

          <button className={clsx(classes.cancelButton, 'default-button')} disabled={isLoading}>
            <p>Отменить</p>
          </button>

          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}>
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>

        </Box>
      </Box>
    )
  })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12,
      paddingTop: 10,
      paddingLeft: 10,
      paddingBottom: 10
    },
    label: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    helperText: {
      fontFamily: 'Roboto Regular',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left'
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    unifiedPricesLabel: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      color: '#1F364D'
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      margin: '10px 10px 30px 10px'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    }
  })
)
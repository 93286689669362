import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { AppLoader } from './AppLoader'
import { AppRouter } from './AppRouter'
import { AppAuthRouter } from './AppAuthRouter'
import { AppNotHealthy } from './AppNotHealthy'
import { MoySkladAppRoute } from './MoySkladAppRoute'
import { RedesignPasswordForm } from './RedesignPasswordForm'

import { useLogic, useStore } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { initJivo } from '../common/jivo'
import { __DEV__ } from '../common/devUtils'
import { isMoySkladAppRoute } from '../common/appRoutes'
import { startMoySkladAppHeightInterval } from '../common/msAppUtils'

export const AppContent =
  observer(() => {
    const logic = useLogic()
    const {user, allowRedesign} = useStore()

    const [isLoaded, setLoaded] = useBoolState()
    const [isApiHealthy, setApiHealthy] = useState(false)

    useLazyEffect(
      async () => {
        try {
          const isHealthy = await logic.healthCheck()

          setApiHealthy(isHealthy)

          if (!isHealthy) {
            return
          }

          if (isMoySkladAppRoute()) {
            // Trigger MS app auth immediately
            return
          }

          await logic.checkAuth()
        } finally {
          setLoaded()
        }
      })

    useLazyEffect(() => {
      if (isMoySkladAppRoute()) {
        window.document.documentElement.classList.add('ms-app')
        return startMoySkladAppHeightInterval()
      }
    })

    useLazyEffect(() => {
      if (!__DEV__) {
        initJivo()
      }
    })

    if (!isLoaded) {
      return <AppLoader/>
    }

    if (!isApiHealthy) {
      return <AppNotHealthy/>
    }

    if (!allowRedesign) {
      return <RedesignPasswordForm/>
    }

    if (!user) {
      return <AppAuthRouter/>
    }

    if (user.moySkladAppState != null) {
      return <MoySkladAppRoute/>
    }

    return <AppRouter/>
  })
import { useHistory, useLocation } from 'react-router-dom'
import { useStore, useLogic } from './storeHook'
import { useLazyEffect } from './commonHooks'

export const useRedirectToSubRoute = (basePath: string) => {
  const history = useHistory()
  const location = useLocation()
  const {getHomeNav} = useStore()
  const {getSubRoute} = useLogic()

  useLazyEffect(() => {
    const homeNav = getHomeNav()
    const currentStoreType = homeNav?.storeType
    const subRoute = getSubRoute(currentStoreType)
    const targetPath = `${basePath}/${subRoute}`

    if (subRoute && location.pathname === basePath) {
      history.push(targetPath)
    }
  }, [basePath, history, location.pathname, getHomeNav, getSubRoute])
}
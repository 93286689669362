import React from 'react'
import { observer } from 'mobx-react-lite'
import { HomeScreenTable } from './HomeScreenTable'
import { Box } from '@material-ui/core'

export const HomeScreen =
  observer(() => {
    return (
      <div className="body_wrapper_zero border_left">
        <Box>
          <HomeScreenTable/>
        </Box>
      </div>
    )
  })
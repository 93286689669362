import { Dispatch, SetStateAction } from 'react'

export type SetStateFunc<S> = Dispatch<SetStateAction<S>>

/**
 * Constraints the type `T` to satisfy the `TConstraint`.
 */
export type Constrained<TConstraint, T extends TConstraint> =
  T extends NI<TConstraint> ? T : never

/**
 * Type with keys of `T`, but with values of type `TValue`.
 */
export type ValueMap<T, TValue = unknown> = {
  [K in keyof T]: TValue
}

export type Nullable<T> = T | null

export type Optional<T> = T | undefined

export type NullableMap<T> = {
  [K in keyof T]: Nullable<T[K]>
}

export type OptionalMap<T> = {
  [K in keyof T]: Optional<T[K]>
}

export type NonNullableMap<T> = {
  [K in keyof T]: NonNullable<T[K]>
}

export type SomeNonNullable<T, TKeys extends keyof T> =
  OmitStrict<T, TKeys> &
  NonNullableMap<Pick<T, TKeys>>

export type StringKeyOf<T> = keyof T & string

export type KeyForValueOf<T, TKey extends StringKeyOf<T>, TValue> =
  T[TKey] extends TValue ? TKey : never

/**
 * Omit that preserves discriminated unions.
 * See https://github.com/microsoft/TypeScript/issues/31501
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Omit2<T, K extends keyof any> =
  T extends unknown ? Omit<T, K>
                    : never

/**
 * Omit that constraints K to keys of T and preserves discriminated unions.
 */
export type OmitStrict<T, K extends keyof T> = Omit2<T, K>

export type Replace<
  TSource,
  TReplacement extends Partial<Record<keyof TSource, unknown>>
    // Disallow keys that don't exist in TSource
    & Record<Exclude<keyof TReplacement, keyof TSource>, never>> =
  OmitStrict<TSource, keyof TSource & keyof TReplacement>
  & TReplacement

/**
 * Makes the properties at the given keys partial.
 * @remarks Not using {@link Replace}, because it doesn't like the keys constraints.
 */
export type PartialMap<T, KPartial extends keyof T> =
  OmitStrict<T, KPartial> & Partial<Pick<T, KPartial>>

/**
 * Omits from `T` properties with keys matching `TShape`.
 */
export type OmitShape<T, TShape> = Omit<T, keyof TShape>

/**
 * Keys of required properties of `T`.
 */
export type RequiredKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? never
                                          : K
}[keyof T]

/**
 * Keys of optional properties of `T`.
 */
export type OptionalKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? K
                                          : never
}[keyof T]

/**
 * Picks required properties from `T`.
 */
export type PickRequired<T> = Pick<T, RequiredKeys<T>>

/**
 * Picks optional properties from `T`.
 */
export type PickOptional<T> = Pick<T, OptionalKeys<T>>

/**
 * Omits required properties from `T`.
 */
export type OmitRequired<T> = OmitStrict<T, RequiredKeys<T>>

/**
 * Omits optional properties from `T`.
 */
export type OmitOptional<T> = OmitStrict<T, OptionalKeys<T>>

/**
 * Make all properties in `T` writable.
 */
export type Writable<T> = {
  -readonly [P in keyof T]: T[P]
}

export type StringMap<TValue = never> = {
  [key: string]: TValue
}

export type KeyValuePair<TKey, TValue> = {
  key: TKey
  value: TValue
}

/**
 * @example
 * EnumKey<typeof MyEnum>
 */
export type EnumKey<TEnum> =
  keyof TEnum

export type EnumConstraint<TEnum> = {
  [K in keyof TEnum]: TEnum[K]
}

/**
 * TODO: Is this useful outside of this file?
 * @example
 * EnumValue<typeof MyEnum>
 */
type EnumValue<TEnum> =
  TEnum[EnumKey<TEnum>]

/**
 * NoInfer - prevents inference of generic type parameters.
 */
export type NI<T> = [T][T extends never ? never : 0]

/**
 * `never` if `T` is `never`, otherwise, `unknown`.
 */
export type NeverIfNever<T> = T extends never ? never : unknown

/**
 * Infers `true` if `T` is equivalent to `U`, otherwise `false`.
 *
 * Example:
 *
 * const test1: Equals&lt;string, string&gt; = true // ok
 *
 * const test2: Equals&lt;string, number&gt; = true // error at compile time
 */
export type Equals<T, U> =
  [T] extends [U]
  ? [U] extends [T]
    ? true
    : false
  : false

/**
 * Identity function, ensures that the given `value` has type `T`.
 * TODO: Replace with satisfies keyword
 */
export const exact = <T = never>(value: NI<T>): NI<T> => value

/**
 * Identity function, ensures that the given `key` is indeed a key of type `T`.
 */
export const exactKeyOf =
  <T = never>() =>
    <K extends keyof T>(key: keyof Pick<NI<T>, K>) => key

export const keysOf = <T extends object>(value: T) =>
  Object.keys(value) as Array<keyof T>

export const valuesOf = <T extends object>(value: T) =>
  Object.values(value) as Array<T[keyof T]>

export const isNotNull =
  <T>(value: T | null): value is T extends null ? never : T =>
    value !== null

export const isNotUndefined =
  <T>(value: T | undefined): value is T extends undefined ? never : T =>
    value !== undefined

export const isNotNullOrUndefined =
  <T>(value: T | null | undefined): value is NonNullable<T> =>
    value !== null && value !== undefined

export const isNumber =
  (value: unknown): value is number =>
    typeof value === 'number'

export const isNumberAnd =
  <T>() =>
    (value: unknown): value is number & T =>
      typeof value === 'number'

export const valuesOfNumericEnum =
  <TEnum extends object>(enumType: TEnum): (EnumValue<TEnum> & number)[] =>
    Object.values(enumType)
          .filter(isNumberAnd<EnumValue<TEnum>>())
      // Preserve order
          .sort((value1, value2) => value1 - value2)

export const keysOfNumericEnum =
  <TEnum extends object>(enumType: TEnum): (EnumKey<TEnum> & string)[] =>
    valuesOfNumericEnum(enumType)
      // Assert types because TS enums suck
      .map(_ => enumType[_ as unknown as keyof TEnum] as unknown as EnumKey<TEnum> & string)

export const hasEnumFlag =
  <T extends number>(targetValue: T, enumValue: T) =>
    (targetValue & enumValue) === enumValue

class TestClass {
  testProp: unknown
}

export const checkTranspilerForClassProperties =
  () => new TestClass().hasOwnProperty('testProp' satisfies keyof TestClass)
import React, { FormEvent, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'

import { useBoolState } from '../../../hooks/commonHooks'
import { exact } from '../../../common/tsUtils'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useLogic, useStore } from '../../../hooks/storeHook'
import { useRadioGroupStyles } from '../../../hooks/radioGroupStylesHook'

import { IOSSwitch } from '../../IOSSwitch'
import { DefaultRadioGroup } from '../../DefaultRadioGroup'

import { EditYandexMarketAccountModel } from '../../../server/mpsklad_core/Models/EditYandexMarketAccountModel'
import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { YandexMarketModelType } from '../../../server/mpsklad_core/Entity/YandexMarket/Enums/YandexMarketModelType'

import clsx from 'clsx'

export const YandexMarketApiSettings =
  observer(() => {
    const {showSuccess, showError} = useMessages()

    const logic = useLogic()
    const store = useStore()

    const accountId = store.getHomeNav()?.accountId
    const account = logic.tryGetYandexMarketAccount(accountId)

    const [isLoading, setIsLoading, setNotLoading] = useBoolState()

    const [isFBS] = useState(account?.isFBS ?? true)

    const [campaignId, setCampaignId] = useState(account?.campaignId?.toString() ?? '')
    const [businessId, setBusinessId] = useState(account?.businessId?.toString() ?? '')
    const [userCode, setUserCode] = useState(store.syncStore.yandexMarketUserCode?.toString() ?? '')
    const [name, setName] = useState(account?.name ?? '')

    const [usingUnifiedPrices, setUsingUnifiedPrices] = useState(account?.usingUnifiedPrices ?? false)
    const toggleUsingUnifiedPrices = () => setUsingUnifiedPrices(!usingUnifiedPrices)

    const [modelType, setModelType] = useState(account?.modelType ?? YandexMarketModelType.None)

    const classes = useStyles()
    const radioGroupClasses = useRadioGroupStyles()

    const getWorkflowClasses =
      (checked: boolean) => clsx(radioGroupClasses.radioButton, checked && radioGroupClasses.selectedRadioButton)

    const workflowOptions = useMemo(() => [
      {
        value: YandexMarketModelType.FBY,
        label: 'Продажа со склада Yandex.Market (FBY)'
      },
      {
        value: YandexMarketModelType.FBS,
        label: 'Продажа со своего склада (FBS)'
      },
      {
        value: YandexMarketModelType.Express,
        label: 'Продажа со своего склада с доставкой силами Yandex.Market (Express)'
      },
      {
        value: YandexMarketModelType.DBS,
        label: 'Продажа со своего склада и доставка силами продавца (DBS)',
        disabled: true
      }
    ], [])

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()

        if (!userCode) {
          showError('Необходимо ввести код подтверждения.')
          return
        }

        setIsLoading()

        try {
          const formData =
            exact<Omit<EditYandexMarketAccountModel, 'id'>>({
              campaignId: parseInt(campaignId),
              userCode: parseInt(userCode),
              usingUnifiedPrices,
              isFBS,
              modelType,
              isUpdatePrices: account?.isUpdatePrices ?? false,
              isGeneratingPayments: account?.isGeneratingPayments ?? false,
              isUsingCommissions: account?.isUsingCommissions ?? false,
              ordersCommissionsRecording: account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing,
              documentsCommissionsRecording: account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing,
              name: name || undefined,
              msSalesReturnStoreId: account?.msSalesReturnStoreId,
              msData: {
                msOrganization: account?.msOrganization ?? '',
                msCounterparty: account?.msCounterparty ?? '',
                msContractId: account?.msContractId ?? undefined,
                msSalesChannelId: account?.msSalesChannelId ?? undefined,
                msProjectId: account?.msProjectId ?? undefined
              }
            })

          if (account) {
            await logic.editYandexMarketAccount({id: account.id, ...formData})
          } else {
            await logic.createYandexMarketAccount({id: 0, ...formData})
          }

          store.syncStore.setYandexMarketUserCode(parseInt(userCode))
          showSuccess('Сохранено!')
        } catch (e) {
          console.error('Failed to save YandexMarket account', e)
        } finally {
          setNotLoading()
        }
      }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>Настройки API</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>Номер компании
                    <span className={classes.star}>*</span>
                  </FormLabel>
                </Box>

                <TextField
                  required
                  id="campaignId"
                  placeholder="1337"
                  variant="outlined"
                  size="small"
                  value={campaignId}
                  onChange={e => setCampaignId(e.target.value)}
                  fullWidth
                  InputProps={{
                    className: classes.input
                  }}
                />

                <FormHelperText className={classes.helperText}>
                  Номер кампании можно узнать в личном кабинете продавца в разделе:
                  Настройки - Настройки API - Настройка запросов к Маркету.
                </FormHelperText>

              </Grid>

              <Grid item className={classes.root} xs={12}>

                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>Идентификатор бизнеса
                  </FormLabel>
                </Box>

                <TextField
                  disabled
                  className={classes.textField}
                  id="bussinesId"
                  variant="outlined"
                  size="small"
                  value={businessId === '' ? 'Значение появится при успешном добавлении аккаунта' : businessId}
                  onChange={e => setBusinessId(e.target.value)}
                  fullWidth
                  InputProps={{
                    className: classes.input
                  }}
                />

              </Grid>

              <Grid item className={classes.root} xs={12}>

                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>Код подтверждения
                    <span className={classes.star}>*</span>
                  </FormLabel>

                </Box>

                <TextField
                  required
                  id="userCode"
                  placeholder="12228"
                  variant="outlined"
                  size="small"
                  value={userCode}
                  onChange={e => setUserCode(e.target.value)}
                  fullWidth
                  InputProps={{
                    className: classes.input
                  }}
                />

                <FormHelperText className={classes.helperText}>
                  Чтобы получить код, перейдите по данной <a
                  href="https://oauth.yandex.ru/authorize?response_type=code&client_id=bf8796a163424ce2b6ee8124a3a84a5b"
                  target="_blank" rel="noopener noreferrer">ссылке</a>.
                  Код активен 10 минут, после чего генерировать его придётся снова.
                </FormHelperText>

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>
                    Настройка работ с ценами
                  </FormLabel>
                </Box>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Использовать общие цены
                  </FormLabel>
                  <IOSSwitch checked={usingUnifiedPrices} onChange={toggleUsingUnifiedPrices}/>
                </Box>


                <FormHelperText className={classes.helperText}>
                  Если ваша кампания использует разные цены в разных магазинах (стандартно для
                  давно зарегистрированных магазинов), то пропустите этот этап.
                </FormHelperText>
              </Grid>

              <Grid item className={classes.root} xs={12}>

                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>Название аккаунта
                  </FormLabel>
                </Box>

                <TextField
                  required
                  id="campaignId"
                  placeholder="Yandex Market 1000"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  fullWidth
                  InputProps={{
                    className: classes.input
                  }}
                />

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>
                    Схема работы
                  </FormLabel>
                </Box>

                <DefaultRadioGroup<YandexMarketModelType>
                  name="ModelType"
                  value={modelType}
                  options={workflowOptions}
                  onChange={setModelType}
                  getOptionClassName={getWorkflowClasses}
                  optionLabelClassName={radioGroupClasses.radioLabel}
                />

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box display="flex">
          <button className={clsx(classes.cancelButton, 'default-button')} disabled={isLoading}>
            <p>Отменить</p>
          </button>

          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}>
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>

        </Box>
      </Box>
    )
  })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12,
      paddingTop: 10,
      paddingBottom: 10
    },
    label: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    helperText: {
      fontFamily: 'Roboto Regular',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left'
    },
    textField: {
      '& .Mui-disabled': {
        backgroundColor: '#888888',
        opacity: 0.2
      }
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    star: {
      color: '#3987CF',
      marginLeft: 2
    },
    unifiedPricesLabel: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      color: '#1F364D'
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      margin: '10px 10px 30px 10px'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    }
  })
)
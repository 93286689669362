import React, { FormEvent, useState } from 'react'

import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { ButtonLoader } from './ButtonLoader'
import { DropdownItemProps } from './DropdownButton'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { pluralize } from '../common/stringUtility'
import { fixMsAppScrollProps } from '../common/msAppUtils'
import { BootstrapInput } from './MoySkladAttributeSelect'
import clsx from 'clsx'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

export type MatchProductsButtonProps<TFromProp extends number, TToProp extends number> =
  DropdownItemProps & {
  buttonText: string

  title: string

  fromLabel: string

  toLabel: string

  fromProps: Array<[TFromProp, string]>

  toProps: Array<[TToProp, string]>

  accountIdFrom: number

  accountIdTo: number

  submit: (fromProp: TFromProp, toProp: TToProp, accountIdFrom: number, accountIdTo: number) => Promise<number>
}

export const MatchProductsButton =
  <TFromProp extends number, TToProp extends number>(
    {
      buttonText, title, fromLabel, toLabel,
      fromProps, toProps,
      submit, setClosed,
      accountIdFrom, accountIdTo
    }: MatchProductsButtonProps<TFromProp, TToProp>) => {
    const classes = useStyles()
    const menuItemClasses = useDefaultMenuItemStyles()

    const {showSuccess, showWarning} = useMessages()

    const [isOpen, openModal, closeModal] = useBoolState()

    const [isLoading, setLoading, setDone] = useBoolState()

    const [fromProp, setFromProp] = useState(fromProps[0][0])

    const [toProp, setToProp] = useState(toProps[0][0])

    const onClose = () => {
      closeModal()
      setClosed()
    }

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()
        setLoading()

        const productCount = await submit(fromProp, toProp, accountIdFrom, accountIdTo)

        if (productCount > 0) {
          showSuccess(pluralize(productCount, 'товар сопоставлен', 'товара сопоставлено', 'товаров сопоставлено'))
        } else {
          showWarning('Товары с совпадающими полями не найдены')
        }

        setDone()
        onClose()
      }

    return <>
      <ListItem className={menuItemClasses.menuItem} button onClick={openModal}>
        <ListItemText primary={buttonText}/>
      </ListItem>

      <Dialog open={isOpen} onClose={closeModal} classes={{paper: classes.dialogPaper}}>
        <Box className={classes.title}>
          {title}
        </Box>

        <DialogContent>
          <Box paddingY={1}>
            <form onSubmit={onSubmit}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="from-label">
                    <span className={classes.dash}>—</span>{fromLabel}
                  </InputLabel>

                  <Select
                    labelId="from-label"
                    value={fromProp}
                    onChange={e => setFromProp(e.target.value as TFromProp)}
                    MenuProps={fixMsAppScrollProps}
                    input={<BootstrapInput/>}
                  >
                    {
                      fromProps.map(prop =>
                        <MenuItem
                          className={menuItemClasses.menuItem}
                          key={prop[0]}
                          value={prop[0]}
                        >
                          {prop[1]}
                        </MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>

              <Box marginTop={3}>
                <FormControl fullWidth>
                  <InputLabel id="to-label" shrink>
                    <span className={classes.dash}>—</span>{toLabel}
                  </InputLabel>

                  <Select
                    labelId="to-label"
                    value={toProp}
                    onChange={e => setToProp(e.target.value as TToProp)}
                    MenuProps={fixMsAppScrollProps}
                    input={<BootstrapInput/>}
                  >
                    {
                      toProps.map(prop =>
                        <MenuItem
                          className={menuItemClasses.menuItem}
                          key={prop[0]}
                          value={prop[0]}>{prop[1]}
                        </MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>

              <Box marginTop={3}>
                <FormHelperText className={classes.helperText}>
                  Сопоставление может занять пару минут для большого количества товаров.
                </FormHelperText>
              </Box>

              <Box className={classes.actions}>


                <button
                  className={clsx(classes.cancelButton, 'default-button')}
                  onClick={onClose}
                  disabled={isLoading}
                >
                  <p>Отменить</p>
                </button>

                <button
                  className={clsx(classes.saveButton, 'default-button')}
                  disabled={isLoading}
                >
                  <p>
                    {
                      isLoading
                      ? <ButtonLoader text="Сопоставляем..."/>
                      : 'Сопоставить'
                    }
                  </p>
                </button>

              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  }

const useStyles =
  makeStyles((/*theme: Theme*/) =>
    createStyles({
      dialogPaper: {
        // At the top
        alignSelf: 'flex-start'
      },
      dash: {
        color: '#3987CF',
        marginRight: 3
      },
      title: {
        padding: 20,
        fontFamily: 'Roboto Regular',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1F364D'
      },
      saveButton: {
        width: 160,
        height: 40,
        color: '#FFFFFF',
        alignItems: 'center',
        marginLeft: 10
      },
      cancelButton: {
        width: 160,
        height: 40,
        color: '#FFFFFF',
        backgroundColor: '#D2D2D2',
        alignItems: 'center'
      },
      actions: {
        display: 'flex',
        justifyContent: 'center !important'
      },
      helperText: {
        fontFamily: 'Roboto Regular',
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '12px',
        textAlign: 'left',
        marginTop: 5
      }
    }))
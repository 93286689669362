import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { SyncErrorIcon } from './SyncErrorIcon'
import { OrderRetryIcon } from './OrderRetryIcon'

import { MyRowProps } from '../../types/tableTypes'
import { IntegrationTypeKey } from '../../types/integrationTypeUtils'
import { OrderModelBase } from '../../server/mpsklad_core/Models/Orders/OrderModelBase'

export type OrderSyncErrorCellProps =
  MyRowProps<OrderModelBase> & {
  type: IntegrationTypeKey
}

export const OrderSyncErrorCell =
  observer(
    ({row, type}: OrderSyncErrorCellProps) =>
      // NOTE: Box is slower that div with styles, useStyles is about the same as div
      <TableCell className="table-border">
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          {
            !!row.syncErrorId &&
            <SyncErrorIcon type={type} errorId={row.syncErrorId}/>
          }

          <OrderRetryIcon type={type} orderId={row.id} accountId={row.accountId}/>
        </div>
      </TableCell>
  )
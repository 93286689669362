import React from 'react'
import { observer } from 'mobx-react-lite'

import { WbAccountInfo } from './WbAccountInfo'
import { OzonAccountInfo } from './OzonAccountInfo'
import { MyProductsTable } from './MyProductsTable'
import { YandexMarketAccountInfo } from './YandexMarketAccountInfo'

import { useStore } from '../hooks/storeHook'

import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'
import { MyProductState } from '../server/mpsklad_core/Models/MyProductState'

export const HomeProductsTable =
  observer(() => {
    const {
      homeNavRequired: {productsStateTab, storeType},
      getHomeOzonAccount, getHomeWbAccount, getHomeYmAccount
    } = useStore()

    if (productsStateTab !== MyProductState.FromMarketplace) {
      return <MyProductsTable/>
    }

    switch (storeType) {
      case NavStoreType.Ozon:
        return <OzonAccountInfo account={getHomeOzonAccount()}/>

      case NavStoreType.Wildberries:
        return <WbAccountInfo account={getHomeWbAccount()}/>

      case NavStoreType.YandexMarket:
        return <YandexMarketAccountInfo account={getHomeYmAccount()}/>

      default:
        throw new Error(`Unprocessed store type: ${storeType}`)
    }
  })
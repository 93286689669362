import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountStoresForm } from './AccountStoresForm'

import { useApi } from '../hooks/storeHook'

import { required } from '../common/objectUtils'

import { YandexMarketAccountRequiredProps } from '../types/accountProps'

export const YandexMarketStoresForm =
  observer(({account}: YandexMarketAccountRequiredProps) => {
    const api = useApi()

    // TODO: Show mainWarehouseId, mainWarehouseCampaignId?
    return <AccountStoresForm
      account={account}
      loadStores={api.userSync.getYandexMarketStores}
      loadWarehouses={api.userSync.getYandexMarketWarehouses}
      editStores={api.userSync.editYandexMarketStores}
      whKeySelector={warehouse => required(warehouse.id)}
      storeWhKeySelector={store => required(store.warehouseId)}
      formatWarehouseNameHint={warehouse => warehouse.warehouseGroupName ? ` | ${warehouse.warehouseGroupName}` : ''}
    />
  })
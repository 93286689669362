import React from 'react'
import { observer } from 'mobx-react-lite'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'

import { SideBarNavigation } from './SideBarNavigation'
import { Header } from './Header'
import { Footer } from './Footer'

import clsx from 'clsx'

import { AppRoute } from './AppRoute'

import { SyncRoute } from './SyncRoute'
import { OrdersRoute } from './OrdersRoute'
import { SettingsRoute } from './Settings/SettingsRoute'
import { PrintRoute } from './Print/PrintRoute'
import { ImportRoute } from './Import/ImportRoute'

import { HomeScreen } from './HomeScreen'
import { AccountScreen } from './AccountScreen'
import { SyncLogsScreen } from './SyncLogsScreen'
import { AdminUsersScreen } from './AdminUsersScreen'
import { ProductDiffsScreen } from './ProductDiffsScreen'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

export const AppRouter =
  observer(() => {
    const {hasMoySkladApp} = useStore()

    return (
      <div className={clsx('App', {'ms-app': hasMoySkladApp})}>
        <BrowserRouter>
          <div className="app_container">
            <Header/>
            <div className="main_content">
              <SideBarNavigation/>
              <Switch>
                <AppRoute exact path={appRoutes.Home.base} component={HomeScreen}/>

                <AppRoute path={appRoutes.Sync.base} component={SyncRoute}/>

                <AppRoute path={appRoutes.Settings.base} component={SettingsRoute}/>

                <AppRoute path={appRoutes.Print.base} component={PrintRoute}/>

                <AppRoute path={appRoutes.Diffs} component={ProductDiffsScreen}/>

                <AppRoute path={appRoutes.Orders.base} component={OrdersRoute}/>

                <AppRoute path={appRoutes.Import.base} component={ImportRoute}/>

                <AppRoute path={appRoutes.Account} component={AccountScreen}/>

                <AppRoute path={appRoutes.AdminUsers} component={AdminUsersScreen}/>

                <AppRoute path={appRoutes.SyncLogs.template} component={SyncLogsScreen}/>

                <Redirect from={appRoutes.MoySkladApp.template} to={appRoutes.Home.base}/>

                <Redirect from={appRoutes.MoySkladAppOld} to={appRoutes.Home.base}/>

                <Redirect from={appRoutes.StatusOld} to={appRoutes.Sync.status}/>

                <Redirect from={'*'} to={appRoutes.Home.base}/>
                {/* NOTE: Fallback route, must remain the last one */}
              </Switch>
            </div>
            <Footer/>
          </div>
        </BrowserRouter>
      </div>
    )
  })
import React, { ReactNode, ReactText, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { reaction } from 'mobx'

import debounce from 'lodash/debounce'

import { Autocomplete } from '@material-ui/lab'
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete'
import { Box, FormHelperText, FormLabel, TextField } from '@material-ui/core'

import { useLazyEffect } from '../hooks/commonHooks'

import { emDash } from '../common/stringUtility'
import { required } from '../common/objectUtils'

import { MoySkladApiEntityOption } from '../server/mpsklad_core/Models/MoySkladApiEntityOption'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

export type MsEntityDataBase = {
  options: MoySkladApiEntityOption[]
  searchTerm: string
  isSearchLoading: boolean
}

export type MsEntityDataRequired =
  MsEntityDataBase & {
  value: MoySkladApiEntityOption
}

export type MsEntityDataOptional =
  MsEntityDataBase & {
  value: MoySkladApiEntityOption | null
}

export type MoySkladSearchableEntityInputProps =
// Don't inherit AutocompleteProps - conflicts with our nullability inference, add picked properties as needed
  Pick<AutocompleteProps<MoySkladApiEntityOption, undefined, undefined, undefined>, 'id' | 'loading' | 'loadingText'>
  & ({
  isRequired: true
  data: MsEntityDataRequired
} | {
  isRequired?: false | undefined
  data: MsEntityDataOptional
}) & {
  searchEntities: (searchTerm: string) => Promise<MoySkladApiEntityOption[]>
  label: ReactText
  hint?: ReactNode
}

export const MoySkladSearchableEntityInput =
  observer(
    ({isRequired, data, searchEntities, label, hint, ...passProps}: MoySkladSearchableEntityInputProps) => {
      const innerOptions = useMemo(
        () => isRequired ? data.options : [null, ...data.options],
        [isRequired, data.options])

      const classes = useStyles()
      const menuClasses = useDefaultMenuItemStyles()
      const searchEntitiesDebounced = useSearchEntitiesDebounced(data, searchEntities)

      useLazyEffect(() =>
          reaction(() => data.searchTerm, () => {
            data.isSearchLoading = true
            searchEntitiesDebounced()
          }),
        [data, searchEntitiesDebounced])

      // NOTE: inputValue (searchTerm) should not be controlled
      // NOTE: Loading text is shown only if there are no local matches
      return <>
        <Box marginBottom={1}>
          <FormLabel component="legend" className={classes.label}>
            <span className={classes.dash}>—</span>{label}{isRequired && <span className={classes.star}>*</span>}
          </FormLabel>
        </Box>

        <Autocomplete
          loading={data.isSearchLoading}
          loadingText={'Поиск...'}
          value={data.value}
          options={innerOptions}
          disableClearable={isRequired}
          getOptionLabel={option => option?.name ?? emDash}
          getOptionSelected={(option, value) => (!option && !value) || option?.id === value?.id}
          onChange={(_, selectedValue) => data.value = isRequired ? required(selectedValue) : selectedValue}
          onInputChange={
            (_, newSearchTerm, reason) => {
              if (reason !== 'reset') {
                data.searchTerm = newSearchTerm
              }
            }
          }
          fullWidth
          renderInput={params =>
            <TextField
              {...params}
              variant="outlined"
              size="small"
              fullWidth

            />}
          {...passProps}
          classes={
            {
              option: clsx(menuClasses.menuItem)
            }
          }
        />

        {
          !!hint &&
          <FormHelperText className={classes.helperText}>
            {hint}
          </FormHelperText>
        }
      </>
    })

const useStyles = makeStyles(
  () => ({
    label: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 15
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    star: {
      color: '#3987CF',
      marginLeft: 2
    },
    helperText: {
      fontSize: 11,
      marginTop: 15
    },
    input: {
      fontSize: 12
    }
  })
)

const useSearchEntitiesDebounced =
  (data: MsEntityDataBase, searchEntities: (searchTerm: string) => Promise<MoySkladApiEntityOption[]>) =>
    useMemo(() =>
        debounce(async () => {
            data.isSearchLoading = true

            try {
              data.options = await searchEntities(data.searchTerm)
            } finally {
              data.isSearchLoading = false
            }
          },
          800),
      [data, searchEntities])
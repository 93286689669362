import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { MyTable } from './MyTable'
import { ScreenLoader } from './ScreenLoader'

import { useLogic, useStore } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { wbFilesColumns } from '../types/wbFilesColumns'
import { WbAccountIdProps } from '../types/accountProps'

export const WbFilesTab =
  observer(
    ({wbAccountId}: WbAccountIdProps) => {
      const {labelStore: {wbFiles}} = useStore()
      const {loadWbFiles} = useLogic()

      const [isLoading, , setLoaded] = useBoolState(true)

      useLazyEffect(() => loadWbFiles(wbAccountId).finally(setLoaded))

      return (
        <Box padding={2} width={'calc(100vw - 300px)'}>
          {
            isLoading
            ? <ScreenLoader/>
            :
            <MyTable
              table={wbFiles}
              columns={wbFilesColumns}
              labelNoRows={'Файлов нет'}
              labelNotFound={'Файлы не найдены'}
              labelRowsPerPage={'Файлов на странице:'}
            />
          }

          {
            wbFiles.totalRowCount >= 500 &&
            <small>
              Показаны последние 500 файлов.
            </small>
          }
        </Box>
      )
    })
import React, { FormEvent, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { autorun, comparer, reaction } from 'mobx'
import { getUnixTime } from 'date-fns'

import {
  Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons'

import { useApi } from '../../../hooks/storeHook'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../../../hooks/commonHooks'
import { useCheckboxStyles } from '../../../hooks/checkboxStylesHook'

import { exact } from '../../../common/tsUtils'
import { wait } from '../../../common/commonUtils'
import { dateUtils } from '../../../common/dateTimeUtility'
import clsx from 'clsx'

import { YandexMarketAccountIdProps } from '../../../types/accountProps'
import { ResponseCancelledError } from '../../../types/responseCancelledError'
import { OrdersAtDateCount } from '../../../types/ordersAtDateCount'
import { YandexMarketPrintOptions } from '../../../server/mpsklad_core/Models/YandexMarketPrintOptions'
import { YandexMarketPrintOrderModel } from '../../../server/mpsklad_core/Models/YandexMarketPrintOrderModel'
import { YandexMarketPrintOrderColumnId } from '../../../types/yandexMarketColumns'
import { MemoryTableData } from '../../../store/memoryTableData'

import { PrintMsBarcodesCheckbox } from '../../PrintMsBarcodesCheckbox'
import { LabelsPostDateFilters, maxShipmentDate, minShipmentDate } from '../../LabelsPostDateFilters'
import { DateTimeRangePicker } from '../../DateTimeRangePicker'
import { YandexMarketPreviewPrintTable } from '../../YandexMarketPreviewPrintTable'

import {
  filterPreviewByShipmentDate,
  getOrderShipmentDateCounts
} from '../../OzonLabelsTab'

export type LabelsTabStateBase = {
  startDate: Date
  endDate: Date
  maxDate: Date

  pickedShipmentDate: Date | null
  shipmentDateMin: Date
  shipmentDateMax: Date

  useAutoReset: boolean
  hasPurchaseList: boolean
  hasMsBarcodes: boolean
}

export type YandexMarketLabelsTabState =
  LabelsTabStateBase & {
  readonly printRequestData: Readonly<YandexMarketPrintOptions>
  readonly previewRequestData: Readonly<YandexMarketPrintOptions>
}

export const YandexMarketLabelsPrintForm =
  observer(
    ({yandexMarketAccountId}: YandexMarketAccountIdProps) => {
      const api = useApi()

      const {showSuccess} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()
      const [submitError] = useState(null as string | null)

      const classes = useStyles()
      const checkboxClasses = useCheckboxStyles()

      const options =
        useLocalObservable(() =>
          exact<YandexMarketLabelsTabState>({
            // NOTE: Effects are required for initial values that depend on props or state
            startDate: dateUtils.startOfYesterday,
            endDate: dateUtils.endOfToday,
            maxDate: dateUtils.endOfToday,
            // TODO: Not only this is not controlled, its computation is non-trivial...
            pickedShipmentDate: null,
            shipmentDateMin: minShipmentDate,
            shipmentDateMax: maxShipmentDate,
            useAutoReset: false,
            hasPurchaseList: true,
            hasMsBarcodes: false,
            get printRequestData() {
              return exact<YandexMarketPrintOptions>({
                accountId: yandexMarketAccountId,
                startDateUnix: getUnixTime(this.startDate),
                endDateUnix: getUnixTime(this.endDate),
                shipmentDateMin: this.shipmentDateMin.toISOString(),
                shipmentDateMax: this.shipmentDateMax.toISOString(),
                useAutoReset: this.useAutoReset,
                hasPurchaseList: this.hasPurchaseList,
                hasMsBarcodes: this.hasMsBarcodes
              })
            },
            get previewRequestData(): Readonly<YandexMarketPrintOptions> {
              return exact<YandexMarketPrintOptions>({
                ...this.printRequestData,
                // TODO: Make these filters server-side for absolute consistency
                // TODO: Use startOfDay, endOfDay in Unix-time to preserve client-side timezone on the backend
                // These filters are applied client-side for the preview, thus we request with default values
                shipmentDateMin: minShipmentDate.toISOString(),
                shipmentDateMax: maxShipmentDate.toISOString()
              })
            }
          }))

      const localState =
        useLocalObservable(() => ({
          preview: null as YandexMarketPrintOrderModel[] | null,
          previewWithShipmentFilters: null as YandexMarketPrintOrderModel[] | null,
          get shipmentDateCounts(): OrdersAtDateCount[] {
            return getOrderShipmentDateCounts(this.preview)
          }
        }))

      const [yandexMarketPrintOrders] = useState(
        () => new MemoryTableData<YandexMarketPrintOrderModel, YandexMarketPrintOrderColumnId>(
          'yandexMarketPrintOrders'))

      useLazyEffect(() =>
        autorun(() => {
          const {previewWithShipmentFilters, shipmentDateMin, shipmentDateMax} =
            filterPreviewByShipmentDate(options.pickedShipmentDate, localState.preview, localState.shipmentDateCounts)

          localState.previewWithShipmentFilters = previewWithShipmentFilters
          options.shipmentDateMin = shipmentDateMin
          options.shipmentDateMax = shipmentDateMax
        }))

      // Fetch preview orders from backend when options change
      useLazyEffect(() =>
        reaction(
          () => options.previewRequestData,
          async () => {
            yandexMarketPrintOrders.data = []

            try {
              localState.preview = null
              localState.preview = await api.previewYandexMarketLabelsPreemptive(options.previewRequestData)
              yandexMarketPrintOrders.data = localState.preview

              // Reset filter by shipment date when other options change
              // TODO: This is required because LabelsPostDateFilters is not controlled, make it
              options.pickedShipmentDate = null
            } catch (e) {
              if (e instanceof ResponseCancelledError) {
                // Ignore
                return
              } else {
                throw e
              }
            }
          }, {
            fireImmediately: true,
            equals: comparer.shallow
          }))

      const onDateChange =
        (startDate: Date, endDate: Date) => {
          options.startDate = startDate
          options.endDate = endDate
        }

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (isSubmitting) {
            return
          }

          setSubmitting()

          try {
            const {fileUrl, errorCount} = await api.label.printYandexMarket(options.printRequestData)

            if (errorCount === 0) {
              showSuccess('Создано!')
            } else {
              showSuccess(`Создано! Отправлений с ошибками: ${errorCount}.`)
            }

            await wait(500)
            window.location.assign(fileUrl)
          } finally {
            setSubmitted()
          }
        }

      const onPostDateChange =
        (newDate: Date | null) =>
          options.pickedShipmentDate = newDate

      return (
        <Box className={classes.container} padding={2} paddingTop={0}>
          <FormHelperText className={classes.helperText}>
            {/* TODO: Take from backend? */}
            Печать этикеток возможна только для заказов в статусе "Ожидает отгрузки".
            Возможна печать до 300 заказов в одном файле.
          </FormHelperText>

          <form onSubmit={onSubmit}>
            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column"
              xs={12}
              spacing={3}
            >
              <Grid item container xs={12}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" className={classes.supplyLabel}>
                    Дата поступления заказа
                  </FormLabel>

                  <DateTimeRangePicker
                    containerProps={{xs: 7}}
                    startDate={options.startDate}
                    endDate={options.endDate}
                    maxDate={options.maxDate}
                    onChange={onDateChange}
                  />

                  <FormLabel component="legend" className={classes.supplyLabel}>
                    Дата для поставки в YandexMarket
                  </FormLabel>

                  <LabelsPostDateFilters
                    overallCount={localState.preview?.length ?? 0}
                    daysCounts={localState.shipmentDateCounts}
                    onFilter={onPostDateChange}
                  />


                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item container xs={6}>
                    <FormControl component="fieldset">

                      <Box>
                        <FormLabel component="legend" className={classes.label}>
                          <span className={classes.dash}>—</span>
                          Что печатать
                        </FormLabel>
                      </Box>

                      <FormGroup>
                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="feedCheckbox"
                              checked
                              disabled
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircleOutline/>}
                            />
                          }
                          label="Лента"
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.hasPurchaseList && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="purchaseListCheckbox"
                              checked={options.hasPurchaseList}
                              onChange={e => options.hasPurchaseList = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Список покупок"
                        />

                        <PrintMsBarcodesCheckbox
                          checked={options.hasMsBarcodes}
                          onChange={checked => options.hasMsBarcodes = checked}
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.useAutoReset && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="autoResetCheckbox"
                              checked={options.useAutoReset}
                              onChange={e => options.useAutoReset = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Использовать автоматическое обнуление"
                        />

                        <FormHelperText>
                          Распечатанные заказы будут вычеркнуты, и в следующий раз будут взяты только новые заказы.
                        </FormHelperText>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>

            <Box>
              <button
                className={clsx(classes.printButton, 'default-button')}
                disabled={isSubmitting}
                onClick={onSubmit}>
                <p>{isSubmitting ? 'Создаём...' : 'Создать этикетки'}</p>
              </button>
            </Box>

            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column">
              {
                !!submitError &&
                <Grid item>
                  <FormLabel error>
                    {submitError}
                  </FormLabel>
                </Grid>
              }

              <Grid item xl={12}>
                <Box>
                  <FormLabel component="legend" className={clsx(classes.label, classes.previewLabel)}>
                    <span className={classes.dash}>—</span>
                    Предпросмотр
                  </FormLabel>
                </Box>

                <YandexMarketPreviewPrintTable orders={yandexMarketPrintOrders}/>
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    container: {
      padding: '16px 0px 0px 16px',
      width: 'calc(100vw - 300px)'
    },
    supplyLabel: {
      color: '#1F364D',
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    helperText: {
      fontFamily: 'Roboto Regular',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    },
    closeSupplyHelperText: {
      marginLeft: 10
    },
    supplySettingsContainer: {
      flexWrap: 'unset',
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 0,
      padding: 20,
      borderRadius: 12
    },
    label: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15,
      marginTop: 10,
      marginLeft: 12
    },
    previewLabel: {
      marginLeft: 0
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    printButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    }
  }))
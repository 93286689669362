import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { CircularProgress } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { useLogic } from '../../hooks/storeHook'
import { useBoolState } from '../../hooks/commonHooks'
import { useMessages } from '../../hooks/snackbarHooks'

import { IntegrationTypeKey } from '../../types/integrationTypeUtils'

import refresh from '../../assets/refreshErrors.svg'

export const OrderRetryIcon =
  observer(
    ({type, accountId, orderId}: {
      type: IntegrationTypeKey
      accountId: number
      orderId: number
    }) => {
      const logic = useLogic()
      const {showSuccess} = useMessages()

      const [isLoading, setLoading, setLoaded] = useBoolState()

      const classes = useStyles()

      const onRetryClick = async () => {
        if (isLoading) {
          return
        }

        setLoading()

        try {
          switch (type) {
            case 'Ozon':
              await logic.pullOzonOrder(accountId, orderId)
              break

            case 'Wildberries':
              await logic.pullWbOrder(accountId, orderId)
              break

            case 'MoySklad':
              await logic.pullMoySkladOrder(orderId)
              break

            case 'YandexMarket':
              await logic.pullYandexMarketOrder(accountId, orderId)
              break

            default:
              throw new Error(`Unhandled integration type: ${type}`)
          }

          showSuccess('Заказ обновлён')
        } finally {
          setLoaded()
        }
      }

      // NOTE: Box is slower that div with styles, useStyles is about the same as div
      return (
        <div
          className={clsx(classes.wrap, !isLoading && classes.wrapClickable)}
          title={isLoading ? 'Обновляем...' : 'Обновить заказ'}
          onClick={onRetryClick}
        >
          {
            isLoading
            ? <CircularProgress size={24}/>
            : <img src={refresh} alt="Retry"/>
          }
        </div>
      )
    })

const useStyles =
  makeStyles(theme =>
    createStyles({
      wrap: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'inline-flex'
      },
      wrapClickable: {
        cursor: 'pointer'
      }
    }))
import React, { FunctionComponent, ReactNode, ReactNodeArray, useState } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Tab, Tabs } from '@material-ui/core'

import { tabA11yProps, TabPanel } from './TabPanel'
import { AppBarProps } from '@material-ui/core/AppBar/AppBar'

type AutoTabsBaseProps = {
  titles: ReactNode[]

  secondary?: boolean

  appBarProps?: Omit<AppBarProps, 'position' | 'className'>

  children: ReactNodeArray

  AfterTabsComponent?: FunctionComponent
}

type AutoTabsUncontrolledProps =
  AutoTabsBaseProps & {
  /**
   * If specified, this component will be controlled. Use `onTabChange` to update the `tab`.
   */
  tab?: never

  onTabChange?: never
}

type AutoTabsControlledProps =
  AutoTabsBaseProps & {
  /**
   * If specified, this component will be controlled. Use `onTabChange` to update the `tab`.
   */
  tab: number

  onTabChange: (newTabIndex: number) => void
}

export type AutoTabsProps = AutoTabsUncontrolledProps | AutoTabsControlledProps

/**
 * Fragments are not supported as children.
 * Children arrays are flattened.
 */
export const AutoTabs =
  ({tab, titles, secondary, appBarProps, onTabChange, children, AfterTabsComponent}: AutoTabsProps) => {
    // Flatten nested arrays
    children = children.flatMap(_ => _)

    // Skip falsy children
    children = children.filter(child => !!child)

    const classes = useStyles()

    const [autoTab, setAutoTab] = useState(0)

    if (titles.length !== children.length) {
      throw new Error(
        `The amount of children (${children.length}) must match the amount of titles (${titles.length})`)
    }

    const activeTabIndex = tab ?? autoTab

    const onTabsChange =
      (event: unknown, newTabIndex: number) => {
        if (onTabChange) {
          onTabChange(newTabIndex)
        } else {
          setAutoTab(newTabIndex)
        }
      }

    if (activeTabIndex < 0 || activeTabIndex >= titles.length) {
      // This means a tab was deleted
      onTabsChange(null, 0)
      return null
    }

    // TODO: Altrenative indicator for active tab to fix multi-row bugs or is Tabs[scrollable] good enough?
    return <>
      <AppBar
        position="relative"
        className={clsx(classes.appBar, classes.appBarPrimary, secondary && classes.appBarSecondary)}
        {...appBarProps}
      >
        <Tabs variant="scrollable" value={activeTabIndex} onChange={onTabsChange}>
          {
            titles.map(
              (title, index) => (
                <Tab key={index} label={title} {...tabA11yProps(index)} />
              ))
          }
        </Tabs>

        {
          AfterTabsComponent !== undefined &&
          <AfterTabsComponent/>
        }
      </AppBar>

      {
        children.map(
          (child, index) =>
            <TabPanel key={index} value={activeTabIndex} index={index}>
              {child}
            </TabPanel>
        )
      }
    </>
  }

export const useStyles = makeStyles(
  (/*theme*/) => ({
    appBar: {
      display: 'flex',
      flex: 1
    },
    appBarPrimary: {
      backgroundColor: '#fff',
      color: '#888888'
    },
    appBarSecondary: {
      backgroundColor: '#ececec',
      color: '#3987CF'
    }
  })
)
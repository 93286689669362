import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from 'react-router-dom'
import { Box } from '@material-ui/core'

import { useStore } from '../../hooks/storeHook'
import { useRedirectToSubRoute } from '../../hooks/useRedirectToSubRoute'

import { appRoutes } from '../../common/appRoutes'

import { StoreType } from '../../server/mpsklad_core/Entity/StoreType'

import { AppRoute } from '../AppRoute'
import { OzonImportPage } from './Ozon/OzonImportPage'
import { YandexMarketImportPage } from './YandexMarket/YandexMarketImportPage'
import { WildberriesImportPage } from './Wildberries/WildberriesImportPage'

export const ImportRoute = observer(() => {
  const {allowedIntegrations} = useStore()

  useRedirectToSubRoute(appRoutes.Import.base)

  return (
    <div className="body_wrapper_zero">
      <Box flex={1}>
        <Switch>
          {
            allowedIntegrations[StoreType.YandexMarket] &&
            <AppRoute
              path={appRoutes.Import.yandexmarket}
              component={YandexMarketImportPage}/>
          }

          {
            allowedIntegrations[StoreType.Wildberries] &&
            <AppRoute path={appRoutes.Import.wildberries}
                      component={WildberriesImportPage}/>
          }

          {
            allowedIntegrations[StoreType.Ozon] &&
            <AppRoute path={appRoutes.Import.ozon}
                      component={OzonImportPage}/>
          }
        </Switch>
      </Box>
    </div>
  )
})
import React from 'react'
import { observer } from 'mobx-react-lite'

import { MenuItem, OutlinedInput, Select, TableCell, TableRow, TextField } from '@material-ui/core'

import { fixMsAppScrollProps } from '../common/msAppUtils'
import { myOrderStatusNames } from '../common/orderStatuses'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { MoySkladStatusMapModel } from '../server/mpsklad_core/Models/MoySkladStatusMapModel'
import { MoySkladApiOrderStatus } from '../server/mpsklad_core/Models/ApiModels/MoySkladApiOrderStatus'
import { makeStyles } from '@material-ui/core/styles'

type MoySkladStatusMapRowProps = {
  map: MoySkladStatusMapModel

  statuses: MoySkladApiOrderStatus[]

  onChange: (map: MoySkladStatusMapModel, statusId: string) => void
}

export const MoySkladStatusMapRow =
  observer(
    ({map, statuses, onChange}: MoySkladStatusMapRowProps) => {

      const classes = useStyles()
      const menuClasses = useDefaultMenuItemStyles()

      return <TableRow>
        <TableCell className={classes.cell}>
          <TextField
            className={classes.textField}
            disabled
            required
            id="campaignId"
            placeholder="Ozon 1234"
            variant="outlined"
            size="small"
            value={myOrderStatusNames[map.myOrderStatus]}
            fullWidth
            InputProps={{
              className: classes.input
            }}
          />
        </TableCell>

        <TableCell className={classes.cell}>
          <Select
            value={map.moySkladId ?? ''}
            onChange={e => onChange(map, e.target.value as string)}
            MenuProps={fixMsAppScrollProps}
            fullWidth
            input={<OutlinedInput className={classes.input}/>}
          >
            <MenuItem value={''} className={menuClasses.menuItem}>
              -
            </MenuItem>

            {
              statuses.map(
                status => {
                  return <MenuItem
                    className={menuClasses.menuItem}
                    key={status.id}
                    value={status.id}>
                    {status.name}
                  </MenuItem>
                }
              )
            }
          </Select>
        </TableCell>
      </TableRow>
    }
  )

const useStyles = makeStyles(
  () => ({
    cell: {
      width: 400,
      backgroundColor: '#FFF',
      border: 'none'
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left',
      height: 35
    },
    textField: {
      '& .Mui-disabled': {
        backgroundColor: '#fff',
        color: '#000'
      }
    }
  })
)
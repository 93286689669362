import React, { useEffect, useMemo } from 'react'

import { Route, Switch, useHistory } from 'react-router-dom'

import { observer } from 'mobx-react-lite'
import { useLogic, useStore } from '../../../hooks/storeHook'

import { OzonFeedsTab } from '../../OzonFeedsTab'
import { OzonFilesTab } from '../../OzonFilesTab'
import { OzonLabelsPrintForm } from './OzonLabelsPrint'
import { RouteTabItem } from '../../RouteTabItem'
import { RoutingTabs } from '../../RoutingTabs'

import { ozonPrintRoutes } from '../printRoutes'
import { appRoutes } from '../../../common/appRoutes'

export const OzonPrintPage = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const history = useHistory()

  const account = store.getHomeOzonAccount()

  const routes = useMemo((): RouteTabItem[] =>
    (ozonPrintRoutes)
      .map((_) => ({title: _.title, route: _.route})), [])

  useEffect(() => {
    if (history.location.pathname === appRoutes.Print.ozon) {
      history.push(`${appRoutes.Print.ozon}/${routes[0].route}`)
    }
  })

  return (
    <RoutingTabs
      routeTabs={routes}
      baseRoute={appRoutes.Print.ozon}
      subRoute={logic.getSubRoute(store.getHomeNav()?.storeType)}
    >
      <Switch>
        {
          account.isFBS &&
          <Route
            path={`${appRoutes.Print.ozon}/labels`}
            render={() => <OzonLabelsPrintForm ozonAccountId={account.id}/>}
          />
        }

        {
          account.isFBS &&
          <Route
            path={`${appRoutes.Print.ozon}/files`}
            render={() => <OzonFilesTab ozonAccountId={account.id}/>}
          />
        }

        <Route
          path={`${appRoutes.Print.ozon}/feeds`}
          render={() => <OzonFeedsTab ozonAccountId={account.id}/>}
        />
      </Switch>
    </RoutingTabs>
  )
})
import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@material-ui/core'
import { Route, Switch, useHistory } from 'react-router-dom'

import { useLogic, useStore } from '../../../hooks/storeHook'

import { appRoutes } from '../../../common/appRoutes'
import { wildberriesPrintRoutes } from '../printRoutes'

import { WildberriesLabelsPrint } from './WildberriesLabelsPrint'
import { WbFilesTab } from '../../WbFilesTab'
import { RouteTabItem } from '../../RouteTabItem'
import { RoutingTabs } from '../../RoutingTabs'

export const WildberriesPrintPage = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const history = useHistory()

  const account = store.getHomeWbAccount()

  const routes = useMemo((): RouteTabItem[] => {
    return (wildberriesPrintRoutes)
      .map((_) => ({title: _.title, route: _.route}))
  }, [])

  useEffect(() => {
    if (history.location.pathname === appRoutes.Print.wildberries) {
      history.push(`${appRoutes.Print.wildberries}/${routes[0].route}`)
    }
  })

  if (!account?.isFBS) {
    return (
      <Box padding={3}>
        Печать этикеток недоступна при схеме работы FBW.
      </Box>
    )
  }

  return (
    <>
      <RoutingTabs
        routeTabs={routes}
        baseRoute={appRoutes.Print.wildberries}
        subRoute={logic.getSubRoute(store.getHomeNav()?.storeType)}
      >
        <Switch>
          <Route
            path={`${appRoutes.Print.wildberries}/labels`}
            render={() => <WildberriesLabelsPrint account={account}/>}
          />

          <Route
            path={`${appRoutes.Print.wildberries}/files`}
            render={() => <WbFilesTab wbAccountId={account.id}/>}
          />
        </Switch>
      </RoutingTabs>
    </>
  )
})
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { AppRoute } from './AppRoute'
import { WbOrdersTabsScreen } from './WbOrdersScreen'
import { OzonOrdersTabsScreen } from './OzonOrdersScreen'
import { MoySkladOrdersScreen } from './MoySkladOrdersScreen'
import { YandexMarketOrdersTabsScreen } from './YandexMarketOrdersTabsScreen'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { useRedirectToSubRoute } from '../hooks/useRedirectToSubRoute'

export const OrdersRoute =
  observer(() => {
    const {allowedIntegrations} = useStore()

    useRedirectToSubRoute(appRoutes.Orders.base)

    return (
      <div className="body_wrapper_zero body_wrapper__home border_left" style={{minWidth: 'calc(100vw - 300px)'}}>
        <Box flex={1}>
          <Switch>
            {
              allowedIntegrations[StoreType.Ozon] &&
              <AppRoute path={appRoutes.Orders.ozon} component={OzonOrdersTabsScreen}/>
            }

            {
              allowedIntegrations[StoreType.Wildberries] &&
              <AppRoute path={appRoutes.Orders.wildberries} component={WbOrdersTabsScreen}/>
            }

            {
              allowedIntegrations[StoreType.YandexMarket] &&
              <AppRoute path={appRoutes.Orders.yandexmarket} component={YandexMarketOrdersTabsScreen}/>
            }

            <AppRoute path={appRoutes.Orders.moySklad} component={MoySkladOrdersScreen}/>
          </Switch>
        </Box>
      </div>
    )
  })
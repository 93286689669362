import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountStoresForm } from './AccountStoresForm'

import { useApi } from '../hooks/storeHook'

import { required } from '../common/objectUtils'

import { OzonAccountRequiredProps } from '../types/accountProps'

export const OzonStoresForm =
  observer(
    ({account}: OzonAccountRequiredProps) => {
      const api = useApi()

      return <AccountStoresForm
        account={account}
        loadStores={api.userSync.getOzonStores}
        loadWarehouses={api.userSync.getOzonWarehouses}
        editStores={api.userSync.editOzonStores}
        whKeySelector={_ => required(_.id)}
        storeWhKeySelector={_ => required(_.warehouseId)}
      />
    })
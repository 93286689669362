import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { FormHelperText, FormLabel, Grid, IconButton } from '@material-ui/core'

import arrowLeft from '../assets/arrowLeft.svg'
import deleteIcon from '../assets/deleteIcon.svg'

import { WarehousePicker } from './WarehousePicker'
import { useStoresFormStyles } from './StoresFormDirect'
import { MoySkladStorePicker } from './MoySkladStorePicker'
import { AccountStoresInnerProps } from './StoresFormByRelation'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { required } from '../common/objectUtils'

import { getNavStoreTypeName } from '../types/navStore'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { EditStoresModel } from '../server/mpsklad_core/Models/EditStoresModel'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'

/**
 * See {@link StoreRelationType.SumFromMoySklad}.
 */
export const StoresFormSumFromMoySklad =
  observer(
    <TWarehouse extends WarehouseModel>
    ({
       disabled,
       storeType,
       serverData, formData,
       whKeySelector, storeWhKeySelector,
       formatWarehouseDisplayName
     }: AccountStoresInnerProps<TWarehouse>) => {
      const classes = useStoresFormStyles()
      const menuClasses = useDefaultMenuItemStyles()

      const {showWarning} = useMessages()

      const {showDialog} = useLogic()

      const storeTypeName = getNavStoreTypeName(storeType)

      if (serverData.warehouses.length === 0) {
        return <FormHelperText error>Нет складов {storeTypeName}.</FormHelperText>
      }

      const formStore = tryGetFormStore(formData)

      const onAddStore =
        () => {
          if (formStore) {
            throw new Error(`formStore already exists when adding a new one, total: ${formData.stores.length}!`)
          }

          if (serverData.warehouses.length === 0) {
            showWarning(`Нет складов ${storeTypeName}!`)
            return
          }

          if (serverData.msStores.length === 0) {
            showWarning('Нет складов МоегоСклада!')
            return
          }

          formData.stores = [{
            storeId: undefined,
            warehouseId: serverData.warehouses[0].id,
            warehouseName: serverData.warehouses[0].name,
            msStoreIds: [serverData.msStores[0].id],
            stocksSplitPercentage: undefined
          }]
        }

      if (!formStore) {
        return (
          <Grid item container xs={12} spacing={3}>
            <Grid item container xs={6} spacing={3}>
              <Grid item xs={6}>
                <button
                  disabled={disabled}
                  className={clsx(classes.addButton, 'default-button')}
                  onClick={onAddStore}>
                  <p>Добавить</p>
                </button>
              </Grid>
            </Grid>
          </Grid>
        )
      }

      const onChangeWarehouse =
        (warehouse: TWarehouse) => {
          if (formStore == null) {
            throw new Error('No formStore to change warehouse in!')
          }

          formStore.warehouseId = warehouse.id
          formStore.warehouseName = warehouse.name
        }

      const onDeleteStore =
        async () => {
          if (formStore.storeId != null && !await showDialog(
            `Заказы для склада ${storeTypeName} будут удалены из MPsklad после сохранения!`, {
              title: 'Удалить сопоставление складов?',
              acceptButton: 'Удалить'
            })) {
            return
          }

          formData.stores = []
        }

      const onAddMsStoreId =
        () => {
          if (formStore == null) {
            throw new Error('No formStore to add MS store to!')
          }

          const unmatchedMsStore = serverData.msStores.find(msStore => !formStore.msStoreIds.includes(msStore.id))

          if (!unmatchedMsStore) {
            showWarning('Все склады МоегоСклада сопоставлены!')
            return
          }

          formStore.msStoreIds.push(unmatchedMsStore.id)
        }

      const onChangeMsStore =
        (msStoreId: string, newMsStore: MoySkladStoreModel | null) => {
          if (formStore == null) {
            throw new Error('No formStore to change MS store in!')
          }

          formStore.msStoreIds = formStore.msStoreIds.filter(_ => _ !== msStoreId)
                                          .concat(required(newMsStore).id)
        }

      const onDeleteMsStoreId =
        (msStoreId: string) => {
          if (formStore == null) {
            throw new Error('No formStore to delete MS store from!')
          }

          formStore.msStoreIds = formStore.msStoreIds.filter(_ => _ !== msStoreId)
        }

      return (
        <Grid item container xs={12} spacing={3}>
          <Grid item container xs={6} spacing={3} alignItems="center">
            <Grid item xs="auto">
              <WarehousePicker
                formStore={formStore}
                formData={formData}
                warehouses={serverData.warehouses}
                whKeySelector={whKeySelector}
                storeWhKeySelector={storeWhKeySelector}
                formatWarehouseDisplayName={formatWarehouseDisplayName}
                onChange={onChangeWarehouse}
              />
            </Grid>

            <Grid item xs="auto">
              <IconButton
                title="Удалить"
                disabled={disabled}
                className={clsx(menuClasses.iconButtonOutlined)}
                onClick={onDeleteStore}
              >
                <img src={deleteIcon} alt=""/>
              </IconButton>
            </Grid>

            <Grid item xs="auto">
              <img src={arrowLeft} alt="" style={{width: 20, height: 20}}/>
            </Grid>
          </Grid>

          <Grid item container direction="column" wrap="nowrap" xs={6} spacing={3}>
            {
              formStore.msStoreIds.map(
                msStoreId =>
                  <Grid item container xs={12} spacing={3} key={msStoreId}>
                    <Grid item xs="auto">
                      <MoySkladStorePicker
                        filterMatchedStores
                        msStoreId={msStoreId}
                        msStores={serverData.msStores}
                        formData={formData}
                        onChange={newMsStore => onChangeMsStore(msStoreId, newMsStore)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <IconButton
                        title="Удалить"
                        disabled={disabled}
                        className={clsx(menuClasses.iconButtonOutlined)}
                        onClick={() => onDeleteMsStoreId(msStoreId)}
                      >
                        <img src={deleteIcon} alt=""/>
                      </IconButton>
                    </Grid>
                  </Grid>)
            }

            <Grid item xs="auto">
              <button
                disabled={disabled}
                className={clsx(classes.addButton, 'default-button')}
                onClick={onAddMsStoreId}>
                <p>Добавить</p>
              </button>
            </Grid>
          </Grid>

          <Grid item container direction="row" xs={12} spacing={3} style={{marginTop: 20}}>
            <Grid item xs="auto" alignContent="center">
              <FormLabel>
                Склад МоегоСклада, на который будут поступать заказы покупателя
              </FormLabel>
            </Grid>

            <Grid item xs="auto">
              <MoySkladStorePicker
                allowEmpty
                msStoreId={formData.ordersMsStoreId}
                msStores={serverData.msStores}
                formData={formData}
                onChange={msStore => formData.ordersMsStoreId = required(msStore).id}
              />
            </Grid>
          </Grid>
        </Grid>
      )
    })

const tryGetFormStore =
  (formData: EditStoresModel): EditStoreModel | null => {
    if (formData.stores.length === 0) {
      return null
    }

    if (formData.stores.length === 1) {
      return formData.stores[0]
    }

    throw new Error(`Bad form store count for SumFromMoySklad: ${formData.stores.length}.`)
  }
import React from 'react'
import chunk from 'lodash/chunk'

import { Box } from '@material-ui/core'

import { PropFilter, PropFilterItem } from './PropFilter'

export type PropFiltersProps = {
  filters: Array<PropFilterItem | null>
}

export const PropFilters =
  ({filters}: PropFiltersProps) =>
    <Box className="filter_wrapper default-border">
      {
        chunk(filters, 2).map(([filter1, filter2], index) =>
          <Box key={index} display="flex" flexDirection="column" marginX={2}>
            {
              filter1 &&
              <PropFilter filter={filter1}/>
            }

            {
              filter2 &&
              <PropFilter filter={filter2}/>
            }
          </Box>)
      }
    </Box>
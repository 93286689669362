import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { useBoolState } from '../../hooks/commonHooks'
import { useApi } from '../../hooks/storeHook'

import { MoySkladDataFormProps } from '../MoySkladDataForm'
import { MoySkladSearchableEntityInput } from '../MoySkladSearchableEntityInput'

export const CompanySettings =
  observer(
    ({msData, onChange}: MoySkladDataFormProps) => {
      const [isLoading] = useBoolState()
      const api = useApi()

      const classes = useStyles()

      return (
        <Box className={classes.pageContainer}>
          <Typography className={classes.header}>НАСТРОЙКИ ОРГАНИЗАЦИИ</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>

                  <Box>
                    <MoySkladSearchableEntityInput
                      isRequired
                      id="msOrganization"
                      data={msData.organization}
                      searchEntities={api.userSync.getMoySkladOrganizations}
                      label="Организация продавца"
                      hint="Введите наименование или код для поиска."
                    />
                  </Box>

                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>

                  <Box>
                    <MoySkladSearchableEntityInput
                      id="msSalesChannel"
                      data={msData.salesChannel}
                      searchEntities={api.userSync.getMoySkladSalesChannels}
                      label="Канал продаж"
                      hint="Введите наименование или описание для поиска."
                    />
                  </Box>

                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>

                  <Box>
                    <MoySkladSearchableEntityInput
                      isRequired
                      id="msCounterparty"
                      data={msData.counterparty}
                      searchEntities={api.userSync.getMoySkladCounterparties}
                      label="Контрагент покупателя"
                      hint={
                        <>
                          Введите наименование или код для поиска.
                          <br/>
                          Возможен выбор контрагента только с типом "Юридическое лицо".
                        </>
                      }
                    />
                  </Box>

                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>

                  <Box>
                    <MoySkladSearchableEntityInput
                      id="msProject"
                      data={msData.project}
                      searchEntities={api.userSync.getMoySkladProjects}
                      label="Проект"
                      hint="Введите название проекта."
                    />
                  </Box>

                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>

                  <Box>
                    <MoySkladSearchableEntityInput
                      id="msContract"
                      data={msData.contract}
                      searchEntities={api.userSync.getMoySkladContracts}
                      label="Договор"
                      hint={
                        <>
                          Введите номер договора или комментарий для поиска.
                          <br/>
                          Возможен выбор только договора комиссии.
                        </>
                      }
                    />
                  </Box>

                </Grid>
              </Grid>
            </Grid>

          </Grid>

          <Box display="flex">
            <button className={clsx(classes.cancelButton, 'default-button')} disabled={isLoading}>
              <p>Отменить</p>
            </button>

            <button
              className={clsx(classes.saveButton, 'default-button')}
              disabled={isLoading}
              onClick={() => onChange(msData.formData)}>
              <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
            </button>

          </Box>
        </Box>)
    }
  )

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    root: {
      padding: 25
    },
    settingsTopic: {
      borderRadius: 12,
      paddingTop: 10,
      paddingBottom: 10
    },
    label: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    helperText: {
      fontFamily: 'Roboto Regular',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left'
    },
    textField: {
      '& .Mui-disabled': {
        backgroundColor: '#888888',
        opacity: 0.2
      }
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    star: {
      color: '#3987CF',
      marginLeft: 2
    },
    unifiedPricesLabel: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      color: '#1F364D'
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      margin: '10px 10px 30px 10px'
    },
    workflow: {
      '& span': {
        color: '#1F364D',
        fontFamily: 'Roboto Regular',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
        textAlign: 'left'
      },
      '& .Mui-disabled span': {
        color: '#888888 !important'
      },
      '& span:not(.Mui-disabled)': {
        color: '#3987CF'
      }
    },
    selectedWorkflow: {
      '& span': {
        color: '#3987CF !important',
        fontFamily: 'Roboto Regular',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '14.4px',
        textAlign: 'left'
      }
    },
    workflowLabel: {
      color: '#1F364D !important'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    }
  })
)
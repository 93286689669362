import React from 'react'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress, FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core'

import { StoreRelationTypeRadio } from './StoreRelationTypeRadio'
import { StoresFormByRelation, WarehouseKeyProps } from './StoresFormByRelation'

import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useLogic } from '../hooks/storeHook'
import { LoadState, useLoadState } from '../hooks/loadStateHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { useLocalObservableOptional } from '../common/mobxUtils'

import { getNavStoreTypeName } from '../types/navStore'
import { StoreAccountRequiredProps } from '../types/accountProps'

import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'
import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { StoreModelBase } from '../server/mpsklad_core/Models/StoreModelBase'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { EditStoresModel } from '../server/mpsklad_core/Models/EditStoresModel'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'
import { CreateMsStoresModel } from '../server/mpsklad_core/Models/CreateMsStoresModel'
import { StoreRelationType } from '../server/mpsklad_core/Entity/Base/StoreRelationType'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'

export type AccountStoresFormProps<TAccount extends StoreAccountModelBase, TWarehouse extends WarehouseModel> =
  WarehouseKeyProps<TWarehouse>
  & StoreAccountRequiredProps<TAccount>
  & {
  hint?: string

  formatWarehouseNameHint?: (warehouse: TWarehouse) => string

  loadWarehouses: (accountId: number) => Promise<TWarehouse[]>

  loadStores: (accountId: number) => Promise<StoreModelBase[]>

  editStores: (model: EditStoresModel) => Promise<void>
}

export type AccountStoresServerData<TWarehouse extends WarehouseModel> = {
  stores: StoreModelBase[]

  warehouses: TWarehouse[]

  msStores: MoySkladStoreModel[]
}

export const AccountStoresForm =
  observer(
    <TAccount extends StoreAccountModelBase, TWarehouse extends WarehouseModel>
    ({
       account, hint,
       loadWarehouses, loadStores, editStores,
       whKeySelector, storeWhKeySelector,
       formatWarehouseNameHint
     }: AccountStoresFormProps<TAccount, TWarehouse>) => {
      const classes = useStyles()
      const {showSuccess, showError} = useMessages()

      const api = useApi()
      const {showDialog} = useLogic()

      const {loadState, setLoading, setSuccess, setError} = useLoadState(LoadState.Loading)

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()

      const serverData =
        useLocalObservable(() => ({
          stores: Array.of<StoreModelBase>(),
          warehouses: Array.of<TWarehouse>(),
          msStores: Array.of<MoySkladStoreModel>()
        } satisfies AccountStoresServerData<TWarehouse>))

      const formData = useLocalObservableOptional<EditStoresModel>()

      const importWarehousesToMs =
        async (warehouses: CreateMsStoresModel) => {
          await api.userSync.importWarehousesToMs(warehouses)
          await loadMsStores()
        }

      // TODO: Pass formatters deeper
      const formatWarehouseName =
        (warehouse: TWarehouse): string =>
          `${warehouse.id ? `${warehouse.id} ` : ''}${warehouse.name}`

      const formatWarehouseDisplayName =
        (warehouse: TWarehouse) =>
          `${formatWarehouseName(warehouse)}${formatWarehouseNameHint ? ` ${formatWarehouseNameHint(warehouse)}` : ''}`

      const loadMsStores =
        async () =>
          serverData.msStores = await api.userSync.getMoySkladStoresForAccount(account.storeType, account.id)

      const initFormData = () =>
        formData.dataOrNull = {
          accountId: account.id,
          relationType: account.storeRelationType,
          ordersMsStoreId: account.ordersMsStoreId,
          stores: serverData.stores.map(_ => ({
            storeId: _.id,
            warehouseId: _.warehouseId,
            warehouseName: _.name,
            stocksSplitPercentage: _.stocksSplitPercentage,
            msStoreIds: _.msStoreIds
          }))
        }

      const onLoad =
        async (onBeforeInit?: () => void) => {
          setLoading()

          try {
            serverData.stores = await loadStores(account.id)
            serverData.warehouses = await loadWarehouses(account.id)

            await loadMsStores()

            runInAction(() => {
              onBeforeInit?.()
              initFormData()
            })

            if (serverData.stores.length === 0 && serverData.msStores.length === 0) {
              showError(`Не найдены склады ни ${getNavStoreTypeName(account.storeType)}, ни МоегоСклада!`)
              setError()
              return
            }

            setSuccess()
          } catch (e) {
            console.error('Failed to load', e)
            setError()
          }
        }

      const onImport =
        async (warehouse: TWarehouse) => {
          setSubmitting()

          try {
            if (warehouse) {
              await importWarehousesToMs({warehousesNames: [formatWarehouseName(warehouse)]})
              showSuccess(`Склад создан в МоёмСкладе!`)
            }
          } catch (e) {
            console.error('Failed to import warehouse to MS', e)
          } finally {
            setSubmitted()
          }
        }

      const onImportAll =
        async () => {
          setSubmitting()

          try {
            const warehousesNames = serverData.warehouses.map(warehouse => formatWarehouseName(warehouse))
            await importWarehousesToMs({warehousesNames})
            showSuccess(`Склады созданы в МоёмСкладе!`)
          } catch (e) {
            console.error('Failed to import all warehouses to MS', e)
            setError()
            return
          } finally {
            setSubmitted()
          }

          await onLoad()
        }

      const onCancel =
        async () => {
          if (await showDialog('Матчинг складов будет сброшен к предыдущему состоянию.', {
            title: 'Отменить изменения?',
            acceptButton: 'Отменить изменения',
            declineButton: 'Продолжить матчинг'
          })) {
            initFormData()
          }
        }

      const onSubmit =
        async () => {
          // Omit unmatched stores
          formData.data.stores = formData.data.stores.filter(_ => _.msStoreIds.length > 0)

          setSubmitting()

          try {
            await editStores(formData.data)
            showSuccess('Склады сохранены!')

            await onLoad(() => {
              // Update account props atomically, otherwise validation throws errors
              account.storeRelationType = formData.data.relationType
              account.ordersMsStoreId = formData.data.ordersMsStoreId
            })
          } catch (e) {
            console.error('Failed to save stores', e)
          } finally {
            setSubmitted()
          }
        }

      const onRelationTypeChange =
        async (newValue: StoreRelationType) => {
          const {data} = formData

          switch (newValue) {
            case StoreRelationType.Direct: {
              if (!await showDialog(
                `Необходимо выбрать склады ${getNavStoreTypeName(account.storeType)} и МоегоСклада без повторений!`)) {
                return
              }

              runInAction(() => {
                const extraStores: EditStoreModel[] = []

                for (const formStore of data.stores) {
                  if (formStore.msStoreIds.length > 1) {
                    extraStores.push(
                      ...formStore.msStoreIds
                                  .slice(1)
                                  .map(msStoreId => ({
                                    ...formStore,
                                    storeId: undefined,
                                    msStoreIds: [msStoreId]
                                  } satisfies EditStoreModel)))

                    formStore.msStoreIds = [formStore.msStoreIds[0]]
                  }
                }

                data.stores.push(...extraStores)
                data.ordersMsStoreId = undefined
                data.relationType = StoreRelationType.Direct
              })
              return
            }

            case StoreRelationType.SumFromMoySklad: {
              if (data.stores.length > 1
                  && !await showDialog(`Матчинг складов ${getNavStoreTypeName(account.storeType)} будет сброшен!`)) {
                return
              }

              runInAction(() => {
                if (data.stores.length > 0) {
                  const msStoreIds = data.stores.flatMap(_ => _.msStoreIds)

                  data.stores = [{
                    ...data.stores[0],
                    msStoreIds: msStoreIds.length > 0 ? [msStoreIds[0]] : []
                  }]
                } else {
                  data.stores = []
                }

                data.ordersMsStoreId = serverData.msStores[0]?.id
                data.relationType = StoreRelationType.SumFromMoySklad
              })
              return
            }

            case StoreRelationType.SplitFromMoySklad: {
              const msStoreIds = data.stores.flatMap(_ => _.msStoreIds)

              if (msStoreIds.length > 1 && !await showDialog('Матчинг складов МоегоСклада будет сброшен!')) {
                return
              }

              runInAction(() => {
                if (msStoreIds.length > 0) {
                  for (const formStore of data.stores) {
                    formStore.msStoreIds = [msStoreIds[0]]
                    formStore.stocksSplitPercentage = 0
                  }
                }

                data.ordersMsStoreId = undefined
                data.relationType = StoreRelationType.SplitFromMoySklad
              })
              return
            }

            default:
              throw new Error(`Unprocessed value of StoreRelationType: ${newValue}.`)
          }
        }

      useLazyEffect(onLoad)

      return (
        <Box className={classes.pageContainer}>
          <Typography className={classes.header}>
            Настройка складов
          </Typography>

          <Grid container spacing={3}>
            {
              (loadState === LoadState.Initial || loadState === LoadState.Error) &&
              <button className={clsx(classes.saveButton, 'default-button')} onClick={() => onLoad()}>
                <p>Загрузить</p>
              </button>
            }

            {
              loadState === LoadState.Loading &&
              <>
                <CircularProgress size={25}/>

                {
                  account.storeType === NavStoreType.Wildberries && !account.isFBS &&
                  <FormHelperText>
                    Загрузка складов FBW может занять пару минут (ограничение API WB).
                  </FormHelperText>
                }
              </>
            }

            {
              loadState === LoadState.Success &&
              <Grid item xs={12}>
                <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                  <Grid item className={classes.root} xs={12}>
                    <Grid container direction="column" spacing={3}>
                      <StoreRelationTypeRadio
                        disabled={isSubmitting}
                        value={formData.data.relationType}
                        onChange={onRelationTypeChange}
                      />

                      <Grid item container xs={12} spacing={3}>
                        {/* Double container to fix the margins by matching deeper components */}
                        <Grid item container xs={12} spacing={3}>
                          <Grid item container xs={12} spacing={3}>
                            <Grid item xs={6}>
                              <FormLabel component="legend" className={classes.label}>
                                <span className={classes.dash}>&mdash;</span>
                                Склад {getNavStoreTypeName(account.storeType)}
                              </FormLabel>
                            </Grid>

                            <Grid item xs={6}>
                              <FormLabel component="legend" className={classes.label}>
                                <span className={classes.dash}>—</span>
                                Склад МоегоСклада
                              </FormLabel>
                            </Grid>
                          </Grid>
                        </Grid>

                        {
                          !!hint &&
                          <Grid item xs={4}>
                            <FormHelperText>
                              {hint}
                            </FormHelperText>
                          </Grid>
                        }

                        <StoresFormByRelation
                          disabled={isSubmitting}
                          storeType={account.storeType}
                          serverData={serverData}
                          formData={formData.data}
                          whKeySelector={whKeySelector}
                          storeWhKeySelector={storeWhKeySelector}
                          formatWarehouseDisplayName={formatWarehouseDisplayName}
                          onImport={onImport}
                        />

                        <Grid item xs={12}>
                          <button
                            disabled={isSubmitting}
                            className={clsx(classes.createAllButton, 'default-button')}
                            onClick={onImportAll}>
                            <p>
                              Создать все склады {getNavStoreTypeName(account.storeType)}
                              <br/>
                              в МоёмСкладе
                            </p>
                          </button>
                        </Grid>

                        <Grid item container xs={12} spacing={3}>
                          <Grid item xs="auto">
                            <button
                              disabled={isSubmitting}
                              className={clsx(classes.saveButton, 'default-button')}
                              onClick={onSubmit}>
                              <p>Сохранить</p>
                            </button>
                          </Grid>

                          <Grid item xs="auto">
                            <button
                              disabled={isSubmitting}
                              className={clsx(classes.cancelButton, 'default-button')}
                              onClick={onCancel}>
                              <p>Отменить</p>
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Box>
      )
    })

const useStyles = makeStyles(
  ({spacing, palette}) => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12,
      padding: spacing(1),
      paddingTop: spacing(2)
    },
    label: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      margin: '10px 10px 30px 10px',
      textTransform: 'uppercase'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center'
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      backgroundColor: palette.grey.A100
    },
    createAllButton: {
      width: 300,
      maxWidth: 300,
      height: 46,
      marginLeft: 0,
      fontSize: 14,
      color: '#FFFFFF',
      alignItems: 'center',
      padding: '8px 32px'
    }
  }))
import React, { FormEvent } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { Box, FormLabel, Grid } from '@material-ui/core'
import { CircleCheckbox } from './CircleCheckbox'

import { ImportProductsProps } from './ImportScreen'

import { useImportEvents } from '../hooks/importHooks'

import { IServerTableData } from '../types/tableTypes'
import { StoreAccountBaseProps } from '../types/accountProps'
import { StoreProductModel } from '../server/mpsklad_core/Models/StoreProductModel'
import { ImportProductsOptionsModel } from '../server/mpsklad_core/Models/ImportModels/ImportProductsModels/ImportProductsOptionsModel'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

export type ImportProductsFormProps<
  TProducts extends IServerTableData<TRow, TColumnId>, TRow extends StoreProductModel, TColumnId> =
  StoreAccountBaseProps
  & ImportProductsProps<TProducts, TRow, TColumnId>
  & {
  importProductsFunc: (accountId: number, productOptionsModel: ImportProductsOptionsModel) => Promise<void>
}

export const ImportProductsForm =
  observer(
    <TProducts extends IServerTableData<TRow, TColumnId>, TRow extends StoreProductModel, TColumnId>
    ({
       account,
       importProductsFunc,
       products
     }: ImportProductsFormProps<TProducts, TRow, TColumnId>) => {
      const classes = useStyles()

      const {onProductImport, isImportDisabled} = useImportEvents(account, products)

      const productImportOptions =
        useLocalObservable<ImportProductsOptionsModel>(
          () => ({
            addNewProducts: true,
            addOptions: {
              variantsAsStandalone: false,
              images: true,
              barcodes: true,
              prices: true
            },
            updateExistingProducts: false,
            updateOptions: {
              name: true,
              article: true,
              code: true,
              images: true,
              barcodes: true,
              prices: true
            },
            productIds: []
          } satisfies ImportProductsOptionsModel))

      const onImportProducts = onProductImport(importProductsFunc, productImportOptions)

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()
          await onImportProducts()
        }

      return (
        <Box className={classes.container}>
          <form onSubmit={onSubmit}>
            <Grid container direction="column"
                  className={clsx(classes.settingsBlock, 'default-border')}
            >
              <Grid item xs={4}>
                <FormLabel component="legend" className={classes.settingsBlockLabel}>
                  Статус последнего импорта
                </FormLabel>
              </Grid>

              <Grid item container direction="row">
                <Grid item xs={6}>
                  <CircleCheckbox
                    label="Добавить новые товары в МойСклад"
                    checked={productImportOptions.addNewProducts}
                    onChange={e => productImportOptions.addNewProducts = e.target.checked}
                  />

                  {
                    productImportOptions.addNewProducts &&
                    <Box display="flex" flexDirection="column" ml={3}>

                      <CircleCheckbox
                        label="Создать модификации товаров отдельными карточками"
                        checked={productImportOptions.addOptions.variantsAsStandalone}
                        onChange={e => productImportOptions.addOptions.variantsAsStandalone = e.target.checked}
                      />

                      <CircleCheckbox
                        label="Добавить картинки"
                        checked={productImportOptions.addOptions.images}
                        onChange={e => productImportOptions.addOptions.images = e.target.checked}
                      />

                      <CircleCheckbox
                        label="Добавить баркод"
                        checked={productImportOptions.addOptions.barcodes}
                        onChange={e => productImportOptions.addOptions.barcodes = e.target.checked}
                      />

                      <CircleCheckbox
                        label="Добавить цену"
                        checked={productImportOptions.addOptions.prices}
                        onChange={e => productImportOptions.addOptions.prices = e.target.checked}
                      />

                    </Box>
                  }
                </Grid>

                <Grid item xs={6}>
                  <CircleCheckbox
                    label="Обновить существующие в МоёмСкладе товары (скоро™)"
                    checked={productImportOptions.updateExistingProducts}
                    onChange={e => productImportOptions.updateExistingProducts = e.target.checked}
                    disabled={true}
                  />

                  {productImportOptions.updateExistingProducts &&
                   <Box display="flex" flexDirection="column" ml={3}>
                     <CircleCheckbox
                       label="Добавить картинки"
                       checked={productImportOptions.updateOptions.images}
                       onChange={e => productImportOptions.updateOptions.images = e.target.checked}
                     />

                     <CircleCheckbox
                       label="Добавить баркод"
                       checked={productImportOptions.updateOptions.barcodes}
                       onChange={e => productImportOptions.updateOptions.barcodes = e.target.checked}
                     />

                     <CircleCheckbox
                       label="Добавить цену"
                       checked={productImportOptions.updateOptions.prices}
                       onChange={e => productImportOptions.updateOptions.prices = e.target.checked}
                     />
                   </Box>
                  }
                </Grid>
              </Grid>
            </Grid>

            <button
              type="submit"
              className={clsx(classes.printButton, 'default-button')}
              disabled={
                isImportDisabled
                || !(productImportOptions.addNewProducts || productImportOptions.updateExistingProducts)}>
              <p>
                {isImportDisabled ? 'Импортируем...' : 'Импортировать товары'}
              </p>
            </button>
          </form>
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    container: {
      padding: '16px 0px 0px 16px',
      width: 'calc(100vw - 350px)'
    },
    settingsBlock: {
      flexWrap: 'unset',
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 0,
      padding: 30,
      borderRadius: 12
    },
    settingsBlockLabel: {
      color: '#1F364D',
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 15,
      marginLeft: 12
    },
    printButton: {
      width: 221,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center'
    }
  }))
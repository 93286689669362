import React, { FormEvent, useEffect, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { autorun } from 'mobx'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem, Select
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons'

import { BootstrapInput } from '../../MoySkladAttributeSelect'
import { WbPreviewPrintTable } from '../../WbPreviewPrintTable'
import { PrintMsBarcodesCheckbox } from '../../PrintMsBarcodesCheckbox'
import { formatWbSupplyDisplayName } from '../../WbOrdersSupplyDropdown'

import { useApi } from '../../../hooks/storeHook'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useCheckboxStyles } from '../../../hooks/checkboxStylesHook'
import { useBoolState, useLazyEffect } from '../../../hooks/commonHooks'
import { useDefaultMenuItemStyles } from '../../../hooks/defaultSelectStylesHook'

import { MemoryTableData } from '../../../store/memoryTableData'

import { exact } from '../../../common/tsUtils'
import { wait } from '../../../common/commonUtils'
import { fixMsAppScrollProps } from '../../../common/msAppUtils'
import { isUiException } from '../../../common/requestValidationError'

import { WbAccountIdProps } from '../../../types/accountProps'
import { WbPrintOrderColumnId } from '../../../types/wbColumns'
import { ResponseCancelledError } from '../../../types/responseCancelledError'

import { WbSupplyModel } from '../../../server/mpsklad_core/Models/WbSupplyModel'
import { WbPrintOptions } from '../../../server/mpsklad_core/Models/WbPrintOptions'
import { WbPrintOrderModel } from '../../../server/mpsklad_core/Models/WbPrintOrderModel'

export type WildberriesLabelsPrintFormProps = {
  wbSupplies: WbSupplyModel[]

  onPrinted: VoidFunction
} & WbAccountIdProps

export const WildberriesLabelsPrintForm =
  observer(
    ({wbAccountId, wbSupplies, onPrinted}: WildberriesLabelsPrintFormProps) => {
      const api = useApi()

      const {showSuccess} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()
      const [submitError, setSubmitError] = useState(null as string | null)

      const classes = useStyles()
      const menuClasses = useDefaultMenuItemStyles()
      const checkboxClasses = useCheckboxStyles()

      const options =
        useLocalObservable(() => ({
          // NOTE: Effects are required for initial values that depend on props or state
          wbSupplyId: wbSupplies[0].id,
          hasPurchaseList: true,
          hasMsBarcodes: false,
          hasArticleCounts: false,
          hasWbBarcode: false,
          doubleUpLabels: false,
          hasWbOrderId: false,

          get requestData(): Readonly<WbPrintOptions> {
            return exact<WbPrintOptions>({
              accountId: wbAccountId,
              wbSupplyId: this.wbSupplyId,
              hasPurchaseList: this.hasPurchaseList,
              hasMsBarcodes: this.hasMsBarcodes,
              hasArticleCounts: this.hasArticleCounts,
              hasWbBarcode: this.hasWbBarcode,
              doubleUpLabels: this.doubleUpLabels,
              hasWbOrderId: this.hasWbOrderId
            })
          }
        }))

      const [wbPrintOrders] = useState(
        () => new MemoryTableData<WbPrintOrderModel, WbPrintOrderColumnId>('wbPrintOrders'))

      useEffect(() => {options.wbSupplyId = wbSupplies[0].id}, [options, wbSupplies])

      useLazyEffect(() =>
        autorun(async () => {
          wbPrintOrders.data = []

          try {
            wbPrintOrders.data = await api.previewWbLabelsPreemptive(options.requestData)
          } catch (e) {
            if (e instanceof ResponseCancelledError) {
              // Ignore
              return
            } else {
              //throw e
            }
          }
        }))

      const onWbSupplyIdChange =
        (e: React.ChangeEvent<{value: unknown}>) =>
          options.wbSupplyId = e.target.value as string

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (isSubmitting) {
            return
          }

          setSubmitting()
          setSubmitError(null)

          try {
            const fileUrl = await api.label.printWbSupply(options.requestData)

            showSuccess('Создано!')

            await wait(500)
            window.location.assign(fileUrl)

            onPrinted()
          } catch (e) {
            if (isUiException(e)) {
              setSubmitError(e.response.data.uiMessage)
            } else {
              throw e
            }
          } finally {
            setSubmitted()
          }
        }

      return (
        <Box padding={2} paddingTop={0}>
          <FormHelperText className={classes.helperText}>
            Печать этикеток возможна только для заказов, добавленных в поставку.
            Возможна печать до 300 заказов в одном файле.
          </FormHelperText>

          <form onSubmit={onSubmit}>
            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column"
              xs={12}
              spacing={3}
            >
              <Grid item container xs={12}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" className={classes.supplyLabel}>
                    Поставка
                  </FormLabel>

                  <Select
                    value={options.wbSupplyId}
                    onChange={onWbSupplyIdChange}
                    MenuProps={fixMsAppScrollProps}
                    fullWidth
                    input={<BootstrapInput/>}
                  >
                    {
                      wbSupplies.map(wbSupply =>
                        <MenuItem
                          className={menuClasses.menuItem}
                          key={wbSupply.id}
                          value={wbSupply.id}
                        >
                          {formatWbSupplyDisplayName(wbSupply)}
                        </MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item container xs={6}>
                    <FormControl component="fieldset">

                      <Box>
                        <FormLabel component="legend" className={classes.label}>
                          <span className={classes.dash}>—</span>
                          Что печатать
                        </FormLabel>
                      </Box>

                      <FormGroup>
                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="feedCheckbox"
                              checked
                              disabled
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircleOutline/>}
                            />
                          }
                          label="Лента"
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.hasPurchaseList && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="purchaseListCheckbox"
                              checked={options.hasPurchaseList}
                              onChange={e => options.hasPurchaseList = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Список покупок"
                        />

                        <PrintMsBarcodesCheckbox
                          checked={options.hasMsBarcodes}
                          onChange={checked => options.hasMsBarcodes = checked}
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.hasWbBarcode && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="hasWbBarcodeCheckbox"
                              checked={options.hasWbBarcode}
                              onChange={e => options.hasWbBarcode = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Штрихкод товара в Wildberries"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item container xs={6}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">

                        <Box>
                          <FormLabel component="legend" className={classes.label}>
                            <span className={classes.dash}>—</span>
                            Опции печати
                          </FormLabel>
                        </Box>

                        <FormGroup>
                          <FormControlLabel
                            className={clsx(checkboxClasses.checkbox,
                              options.doubleUpLabels && checkboxClasses.checkedCheckbox)}
                            control={
                              <Checkbox
                                color="primary"
                                name="doubleUpLabelsCheckbox"
                                checked={options.doubleUpLabels}
                                onChange={e => options.doubleUpLabels = e.target.checked}
                                icon={<CircleUnchecked/>}
                                checkedIcon={<CheckCircle/>}
                              />
                            }
                            label="Задваивать каждую этикетку"
                          />

                          <FormControlLabel
                            className={clsx(checkboxClasses.checkbox,
                              options.hasArticleCounts && checkboxClasses.checkedCheckbox)}
                            control={
                              <Checkbox
                                color="primary"
                                name="hasArticleCountsCheckbox"
                                checked={options.hasArticleCounts}
                                onChange={e => options.hasArticleCounts = e.target.checked}
                                icon={<CircleUnchecked/>}
                                checkedIcon={<CheckCircle/>}
                              />
                            }
                            label="Добавить список артикулов"
                          />

                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                className={clsx(checkboxClasses.checkbox)}
                                control={
                                  <Checkbox
                                    checked
                                    disabled
                                    color="primary"
                                    name="closeSupplyCheckbox"
                                    icon={<CircleUnchecked/>}
                                    checkedIcon={<CheckCircleOutline/>}
                                  />
                                }
                                label="Закрыть поставку после печати"
                              />

                              <FormHelperText
                                className={clsx(classes.helperText, classes.closeSupplyHelperText)}
                              >
                                Печать этикетки для поставки возможна только при закрытии поставки.
                                <br/>
                                При успешном создании этикеток поставка будет закрыта (передана в доставку).
                              </FormHelperText>
                            </FormGroup>
                          </FormControl>

                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>

            <Box>
              <button
                className={clsx(classes.printButton, 'default-button')}
                disabled={isSubmitting}
                onClick={onSubmit}>
                <p>{isSubmitting ? 'Создаём...' : 'Создать этикетки'}</p>
              </button>
            </Box>

            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column">
              {
                !!submitError &&
                <Grid item>
                  <FormLabel error>
                    {submitError}
                  </FormLabel>
                </Grid>
              }

              <Grid item xl={12}>
                <Box>
                  <FormLabel component="legend" className={clsx(classes.label, classes.previewLabel)}>
                    <span className={classes.dash}>—</span>
                    Предпросмотр
                  </FormLabel>
                </Box>

                <WbPreviewPrintTable orders={wbPrintOrders}/>
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    supplyLabel: {
      color: '#1F364D',
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    helperText: {
      fontFamily: 'Roboto Regular',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    },
    closeSupplyHelperText: {
      marginLeft: 10
    },
    supplySettingsContainer: {
      flexWrap: 'unset',
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 0,
      padding: 20,
      borderRadius: 12
    },
    label: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15,
      marginTop: 10,
      marginLeft: 12
    },
    previewLabel: {
      marginLeft: 0
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    printButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    }
  }))
import { useLocalObservable } from 'mobx-react-lite'

import { required } from './objectUtils'

export type MobxOptionalContainer<T> = {
  dataOrNull: T | null

  readonly data: T
}

// NOTE: Wrapping into an object is required for observability
export const useLocalObservableOptional =
  <T>() =>
    useLocalObservable<MobxOptionalContainer<T>>(() => ({
      dataOrNull: null as T | null,
      get data() {
        return required(this.dataOrNull)
      }
    }))
//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum StoreRelationType {
  /**
  * MS stores and marketplace stores are matched directly, one to one.
  *               Stocks from every MS store are sent directly to the matched marketplace store.
  *               Any number of store matchings is allowed, but a store can't be repeated
  *               and <see cref="P:mpsklad_core.Entity.Base.StoreBase.MsStoreIds" /> must have exactly one element.
  */
  Direct = 0,
  /**
  * Stocks from all stores in MS are added up and sent to a single marketplace store.
  *               Only a single marketplace store is allowed
  *               and <see cref="P:mpsklad_core.Entity.Base.StoreBase.MsStoreIds" /> is allowed to have multiple elements.
  *               <see cref="P:mpsklad_core.Entity.Base.StoreAccountBase.OrdersMsStoreId" /> must be set.
  */
  SumFromMoySklad = 1,
  /**
  * Stocks from a single MS store are split up proportionally (<see cref="P:mpsklad_core.Entity.Base.StoreBase.StocksSplitPercentage" />)
  *               and sent to matched marketplace stores.
  *               Any number of store matchings is allowed, but a store can't be repeated
  *               and <see cref="P:mpsklad_core.Entity.Base.StoreBase.MsStoreIds" /> must have exactly one MS store Id, which must be the same for all stores.
  */
  SplitFromMoySklad = 2
}

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { IOSSwitch } from '../IOSSwitch'

import { useApi } from '../../hooks/storeHook'
import { useMessages } from '../../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../../hooks/commonHooks'

import { getNavStoreTypeName } from '../../types/navStore'
import { StoreAccountModelBase } from '../../server/mpsklad_core/Models/StoreAccountModelBase'

export type PricesSettingsProps = {
  account: StoreAccountModelBase

  onCheckPrices: () => Promise<boolean>

  onEnablePriceSync: () => Promise<void>

  onForceUpdatePrices: () => Promise<void>
}

export const PricesSettings =
  observer(
    ({
       account,
       onCheckPrices,
       onEnablePriceSync,
       onForceUpdatePrices
     }: PricesSettingsProps) => {
      const classes = useStyles()

      const {showSuccess, showWarning} = useMessages()
      const [isUpdatePrices, setUpdatePrices] = useState<boolean>(account.isUpdatePrices)
      const {userSync: {setIsUpdatePrices}} = useApi()

      const [hasPrices, setHasPrices, setNoPrices] = useBoolState()

      const [isEnableLoading, setEnableLoading, setEnableLoaded] = useBoolState()

      const [isForceUpdateLoading, setForceUpdateLoading, setForceUpdateLoaded] = useBoolState()

      const isLoading = isEnableLoading || isForceUpdateLoading

      const checkPrices = async () => {
        if (await onCheckPrices()) {
          setHasPrices()
        } else {
          setNoPrices()
          showWarning('Требуется добавить типы цен в МойСклад')
        }
      }

      const onEnablePriceSyncClick = async () => {
        try {
          setEnableLoading()

          await onEnablePriceSync()

          setHasPrices()
          showSuccess('Активировано!')
        } catch {
          setNoPrices()
        } finally {
          setEnableLoaded()
        }
      }

      const onForceUpdateClick = async () => {
        try {
          setForceUpdateLoading()

          await onForceUpdatePrices()

          showSuccess('Обновление в процессе...')
        } finally {
          setForceUpdateLoaded()
        }
      }

      useLazyEffect(checkPrices)

      const onSetUpdatePrices = async () => {
        await setIsUpdatePrices({
          accountId: account.id,
          storeType: account.storeType,
          isUpdatePrices: !isUpdatePrices
        })

        setUpdatePrices(!isUpdatePrices)
        showSuccess('Успешно!')
      }

      return (
        <Box className={classes.pageContainer}>
          <Typography className={classes.header}>НАСТРОЙКИ ЦЕН</Typography>
          {hasPrices ?
           <>
             <Grid container spacing={3}>

               <Grid item xs={9}>
                 <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                   <Grid item className={classes.root} xs={12}>

                     <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                       <Box>
                         <FormLabel className={classes.isUpdatePrices}>
                           Автоматическое обновление цен
                         </FormLabel>

                         <FormHelperText className={classes.helperText}>
                           Обновление происходит в 10:00 по МСК
                         </FormHelperText>
                       </Box>

                       <IOSSwitch checked={isUpdatePrices} onChange={onSetUpdatePrices}/>

                     </Box>
                   </Grid>
                 </Grid>
               </Grid>
               <Grid item xs={9}>
                 <Box width="313px">

                   <button
                     className={clsx(classes.updateButton, 'default-button')}
                     disabled={isLoading}
                     onClick={onForceUpdateClick}>
                     <p>{isLoading ? 'Обновляем...' : 'Обновить'}</p>
                   </button>

                   <FormHelperText className={classes.helperText}>
                     Все цены, имеющие поле “Цена {getNavStoreTypeName(account.storeType)}” больше нуля,
                     будут обновлены, если
                     соответсвуют
                     условиям
                   </FormHelperText>

                 </Box>
               </Grid>
             </Grid>
           </> :
           <>
             <Box display="flex">
               <button
                 className={clsx(classes.saveButton, 'default-button')}
                 onClick={onEnablePriceSyncClick}
                 disabled={isLoading}>
                 <p>Добавить цены в МС</p>
               </button>

             </Box>
           </>}
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12,
      paddingBottom: 10,
      paddingTop: 5
    },
    label: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 15
    },
    helperText: {
      fontSize: 11,
      fontWeight: 400,
      marginTop: 5
    },
    input: {
      fontSize: 12,
      fontWeight: 400
    },
    isUpdatePrices: {
      fontSize: 12,
      fontWeight: 600,
      color: '#1F364D'
    },
    header: {
      fontSize: 18,
      fontWeight: 600,
      margin: '10px 10px 30px 10px'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    updateButton: {
      width: 100,
      height: 32,
      color: '#FFFFFF',
      alignItems: 'center'
    }
  })
)
import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableHead, TableRow } from '@material-ui/core'

import { MyHeadCell } from './MyHeadCell'

import { MyTableHeadProps } from '../../types/tableTypes'

export const MyTableHead =
  observer(
    <TColumnId extends string, TRow extends object>
    ({table, visibleColumns, groupHeader}: MyTableHeadProps<TColumnId, TRow>) =>
      <TableHead>
        {
          groupHeader &&
          <TableRow>
            {groupHeader}
          </TableRow>
        }

        <TableRow className="table_head_row" style={{background: '#AED8FF', color: 'red !important'}}>
          {
            visibleColumns.map(column => <MyHeadCell key={column.id} table={table} column={column}/>)
          }
        </TableRow>
      </TableHead>
  )
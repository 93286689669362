import React from 'react'

import { FormControlLabel, FormHelperText, Radio, RadioGroup, RadioGroupProps, Typography } from '@material-ui/core'

import checkedRadio from '../assets/checkedRadio.svg'

import { Replace } from '../common/tsUtils'
import { required } from '../common/objectUtils'
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel/FormControlLabel'
import clsx from 'clsx'

export type CustomRadioOption<TValue> = {
  value: TValue

  label: string

  hint?: string

  disabled?: boolean
}

export type  CustomRadioGroupProps<TValue> =
  Replace<RadioGroupProps, {
    value: TValue

    /**
     * The {@link newValue} parameter preserves reference equality with the {@link value}.
     * @param value
     */
    onChange: (newValue: TValue) => void
  }> & {
  disabled?: boolean

  options: CustomRadioOption<TValue>[]

  optionProps?: Omit<FormControlLabelProps, 'key' | 'value' | 'className' | 'label' | 'control'>

  optionClassName?: string

  optionRadioClassName?:string

  optionLabelClassName?: string

  optionHintClassName?: string

  getOptionClassName?: (checked: boolean) => string

  getOptionLabelClassName?: (checked: boolean) => string
}

export const DefaultRadioGroup =
  <TValue extends unknown>
  ({
     disabled, value, options,
     onChange,
     optionProps, optionClassName,
     optionRadioClassName, optionLabelClassName, optionHintClassName,
     getOptionClassName, getOptionLabelClassName,
     ...passProps
   }: CustomRadioGroupProps<TValue>) => {
    // All of option mapping is to preserve reference equality of options/value in onChange
    const getOptionKey =
      (option: TValue) => JSON.stringify(option)

    const internalOptions =
      options.map(option => ({
        option,
        key: getOptionKey(option.value)
      }))

    const onInternalChange: RadioGroupProps['onChange'] =
      ({target: {value: newKey}}) => {
        if (!disabled) {
          onChange(required(internalOptions.find(_ => _.key === newKey)).option.value)
        }
      }

    return (
      <RadioGroup
        value={getOptionKey(value)}
        onChange={onInternalChange}
        {...passProps}>
        {
          internalOptions.map(
            ({key, option}) =>
              <FormControlLabel
                key={key}
                value={key}
                disabled={disabled || option.disabled}
                className={clsx(
                  optionClassName,
                  getOptionClassName && getOptionClassName(value === option.value)
                )}
                control={
                  <Radio
                    checkedIcon={<img src={checkedRadio} alt="checked" className="MuiSvgIcon-root"/>}
                    className={optionRadioClassName}/>
                }
                label={
                  <>
                    <Typography
                      className={clsx(
                        optionLabelClassName,
                        getOptionLabelClassName && getOptionLabelClassName(value === option.value))
                      }>
                      {option.label}
                    </Typography>

                    {
                      option.hint &&
                      <FormHelperText className={optionHintClassName}>
                        {option.hint}
                      </FormHelperText>
                    }
                  </>
                }
                {...optionProps}
              />)
        }
      </RadioGroup>
    )
  }
import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton } from '@material-ui/core'

import deleteIcon from '../assets/deleteIcon.svg'

import { WarehousePicker } from './WarehousePicker'
import { MoySkladStorePicker } from './MoySkladStorePicker'
import { AccountStoresInnerProps } from './StoresFormByRelation'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { required } from '../common/objectUtils'

import { getNavStoreTypeName } from '../types/navStore'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'

export const StoresFormDirect =
  observer(
    <TWarehouse extends WarehouseModel>
    ({
       disabled,
       storeType,
       serverData, formData,
       whKeySelector, storeWhKeySelector,
       formatWarehouseDisplayName,
       onImport
     }: AccountStoresInnerProps<TWarehouse>) => {
      const classes = useStoresFormStyles()
      const menuClasses = useDefaultMenuItemStyles()

      const {showWarning} = useMessages()

      const {showDialog} = useLogic()

      const storeTypeName = getNavStoreTypeName(storeType)

      const onAddStore =
        () => {
          const unmatchedWarehouse = serverData.warehouses.find(
            warehouse => formData.stores.every(store => storeWhKeySelector(store) !== whKeySelector(warehouse)))

          if (!unmatchedWarehouse) {
            showWarning(`Все склады ${storeTypeName} сопоставлены!`)
            return
          }

          formData.stores.push({
            storeId: undefined,
            warehouseId: unmatchedWarehouse.id,
            warehouseName: unmatchedWarehouse.name,
            msStoreIds: [/* Start without selection to allow import into MS */],
            stocksSplitPercentage: undefined
          })
        }

      const onDeleteStore =
        async (formStore: EditStoreModel) => {
          if (formStore.storeId != null && !await showDialog(
            `Заказы для склада ${storeTypeName} будут удалены из MPsklad после сохранения!`, {
              title: 'Удалить сопоставление складов?',
              acceptButton: 'Удалить'
            })) {
            return
          }

          formData.stores = formData.stores.filter(_ => _ !== formStore)
        }

      const onChangeWarehouse =
        (formStore: EditStoreModel, warehouse: TWarehouse) => {
          formStore.warehouseId = warehouse.id
          formStore.warehouseName = warehouse.name
        }

      const onChangeMsStore =
        (formStore: EditStoreModel, msStore: MoySkladStoreModel | null) =>
          formStore.msStoreIds = msStore != null ? [msStore.id] : []

      const onImportWarehouse =
        async (formStore: EditStoreModel) => {
          const warehouse =
            required(serverData.warehouses.find(_ => whKeySelector(_) === storeWhKeySelector(formStore)))

          await onImport(warehouse)
        }

      return (
        <>
          <Grid item container xs={12} spacing={3}>
            {
              formData.stores.map((formStore, index) =>
                <Grid item container xs={12} spacing={3} key={index}>
                  <Grid item xs={6}>
                    <WarehousePicker
                      formStore={formStore}
                      formData={formData}
                      warehouses={serverData.warehouses}
                      whKeySelector={whKeySelector}
                      storeWhKeySelector={storeWhKeySelector}
                      formatWarehouseDisplayName={formatWarehouseDisplayName}
                      onChange={warehouse => onChangeWarehouse(formStore, warehouse)}
                    />
                  </Grid>

                  <Grid item container xs={6} spacing={3}>
                    <Grid item xs={4}>
                      <MoySkladStorePicker
                        filterMatchedStores
                        allowEmpty={formStore.storeId == null}
                        msStoreId={formStore.msStoreIds[0]}
                        formData={formData}
                        msStores={serverData.msStores}
                        onChange={msStore => onChangeMsStore(formStore, msStore)}
                      />
                    </Grid>

                    <Grid item container xs={8} spacing={3} alignContent="center" style={{paddingLeft: 20}}>
                      <Grid item xs="auto" alignContent="center">
                        <IconButton
                          title="Удалить"
                          disabled={disabled}
                          className={clsx(menuClasses.iconButtonOutlined)}
                          onClick={() => onDeleteStore(formStore)}
                        >
                          <img src={deleteIcon} alt=""/>
                        </IconButton>
                      </Grid>

                      <Grid item xs="auto" alignContent="center">
                        <button
                          disabled={disabled}
                          className={clsx(classes.createButton, 'default-button')}
                          onClick={() => onImportWarehouse(formStore)}>
                          <p>Создать склад в МоёмСкладе</p>
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          </Grid>

          <Grid item xs={6}>
            <button disabled={disabled} className={clsx(classes.addButton, 'default-button')} onClick={onAddStore}>
              <p>Добавить</p>
            </button>
          </Grid>
        </>
      )
    })

export const useStoresFormStyles =
  makeStyles(
    () => ({
      createButton: {
        width: 170,
        maxWidth: 170,
        height: 46,
        color: '#FFFFFF',
        alignItems: 'center',
        margin: 0,
        fontSize: 14,
        padding: '8px 32px'
      },
      addButton: {
        width: 160,
        height: 40,
        marginTop: 0,
        color: '#FFFFFF',
        alignItems: 'center'
      }
    }))
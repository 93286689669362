import React, { ReactElement, useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import { required } from '../common/objectUtils'
import { makeStyles } from '@material-ui/core/styles'

import { Tab, Tabs } from '@material-ui/core'

import { RouteTabItem } from './RouteTabItem'

export type RouteTabsProps = {
  routeTabs: RouteTabItem[],
  baseRoute: string,

  /**
   * TODO: (redesign) Inline calls to logic.getSubRoute within RoutingTabs
   */
  subRoute?: string

  /**
   * Must be a single Switch component.
   */
  children: ReactElement
}

export const RoutingTabs = ({routeTabs, baseRoute, subRoute, children}: RouteTabsProps) => {
  const tabClasses = useRouteTabsStyles()

  const history = useHistory()

  const activeTabIndex = Math.max(0, routeTabs.findIndex(_ => history.location.pathname.includes(_.route)))

  const onTabChange = useCallback(
    (_: unknown, newActiveTabIndex: number) => {
      const targetPath = subRoute + '/' + required(routeTabs[newActiveTabIndex]).route
      if (subRoute && history.location.pathname === baseRoute) {
        history.push(targetPath)
        return
      }

      if (subRoute && history.location.pathname.includes(baseRoute) &&
          (history.location.pathname !== targetPath)) {
        history.push(required(routeTabs[newActiveTabIndex].route))
      }
    }, [baseRoute, history, routeTabs, subRoute])

  return (<>
      <div style={{width: '100%'}}>
        <Tabs
          value={activeTabIndex}
          onChange={onTabChange}
          className={tabClasses.tabs}
          TabIndicatorProps={{hidden: true}}
        >
          {routeTabs.map((route, index) => (
            <Tab key={index} label={<p>{route.title}</p>} disableRipple
                 disabled={route.isDisabled}/>
          ))}
        </Tabs>
      </div>

      {children}
    </>
  )
}

const useRouteTabsStyles = makeStyles(
  () => ({
    tabs: {
      maxWidth: '100%',
      minWidth: 0,
      '& button': {
        maxWidth: '100px'
      },
      '& p': {
        fontFamily: 'Roboto Regular',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '14.4px',
        textAlign: 'left',
        color: '#888888'
      },
      '& .Mui-selected p': {
        color: '#3987CF',
        fontFamily: 'Roboto Regular',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '14.4px',
        textAlign: 'left',
        borderBottom: '2px solid'
      },
      '& .MuiTab-root': {
        minWidth: 100
      }
    }
  })
)
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Table, TableBody, TableContainer } from '@material-ui/core'

import { ScreenLoader } from './ScreenLoader'
import { SyncLogRow } from './SyncLogRow'
import { SyncLogsTableHead } from './SyncLogsTableHead'

import { useApi } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'
import { RouteParamsParser, useRouteParams } from '../hooks/routeHooks'

import { keysOfNumericEnum } from '../common/tsUtils'

import { integrationNames, IntegrationTypeKey, integrationTypeByKey } from '../types/integrationTypeUtils'

import { SyncLogModel } from '../server/mpsklad_core/Models/SyncLogModel'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

export type SyncLogsScreenParams = {
  integration: IntegrationTypeKey

  accountId: number
}

export const SyncLogsScreen =
  () => {
    const {integration, accountId} = parseRouteParams(useRouteParams<SyncLogsScreenParams>())

    const api = useApi()

    const history = useHistory()

    const [isLoading, , setLoaded] = useBoolState(true)
    const [syncErrors, setSyncErrors] = useState<SyncLogModel[]>([])

    useLazyEffect(
      () => api.userSync.syncLogs({integration: integrationTypeByKey[integration], accountId})
               .then(setSyncErrors)
               .finally(setLoaded))

    if (isLoading) {
      return <ScreenLoader/>
    }

    if (syncErrors.length === 0) {
      return (
        <div className="body_wrapper_zero">
          <div className="body_wrapper__two">
            Нет логов синхронизации {integrationNames[integration]}
          </div>

          <button className="button_grey" onClick={history.goBack}>
            Назад
          </button>
        </div>
      )
    }

    // TODO: Pagination and stuff
    return (
      <div className="body_wrapper_zero">
        <div className="body_wrapper">
          <Box display="flex" alignItems="center" marginBottom={2}>
            <h1 style={{marginLeft: '2rem'}}>
              Логи синхронизации {integrationNames[integration]}
            </h1>
          </Box>
        </div>

        <div className="body_wrapper__two default-border"
             style={{borderRadius: '10px', width: '80%', marginLeft: '2rem'}}>
          <TableContainer>
            <Table>
              <SyncLogsTableHead/>

              <TableBody>
                {
                  syncErrors.map(error => <SyncLogRow key={error.id} log={error}/>)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
  }

const integrationTypeKeys = keysOfNumericEnum(IntegrationType)

const isIntegrationType =
  (integration: string): integration is IntegrationTypeKey =>
    (integrationTypeKeys as string[]).includes(integration)

const parseRouteParams: RouteParamsParser<SyncLogsScreenParams> =
  stringRouteParams => {
    const {integration, accountId: accountIdString} = stringRouteParams

    if (!isIntegrationType(integration)) {
      throw new Error(`Bad integration type: ${stringRouteParams.integration}`)
    }

    const accountId = parseInt(accountIdString)

    if (isNaN(accountId) || accountId <= 0) {
      throw new Error(`Bad accountId: ${accountIdString}`)
    }

    return {integration, accountId}
  }
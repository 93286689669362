import { observer } from 'mobx-react-lite'
import { Box, FormLabel, Grid } from '@material-ui/core'
import clsx from 'clsx'
import { MoySkladAttributeSelect, MoySkladAttributeSelectProps } from '../MoySkladAttributeSelect'
import { makeStyles } from '@material-ui/core/styles'

export type AdditionalPropertiesSettingsFieldProps = MoySkladAttributeSelectProps & {
  labelName: string
}

export const AdditionalPropertiesSettingsField = observer(
  ({labelName, value, setValue, options, isCreating, onCreate}: AdditionalPropertiesSettingsFieldProps) => {
    const classes = useStyles()

    return (
      <Grid item xs={6}>
        <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
          <Grid item className={classes.root} xs={12}>

            <Box>
              <FormLabel component="legend" className={classes.label}>
                <span className={classes.dash}>—</span>
                {labelName}
              </FormLabel>
            </Box>

            <MoySkladAttributeSelect
              value={value}
              setValue={setValue}
              options={options}
              isCreating={isCreating}
              onCreate={onCreate}
            />

          </Grid>
        </Grid>
      </Grid>
    )
  })

const useStyles = makeStyles(() => ({
  root: {
    padding: 15,
    marginLeft: 10
  },
  settingsTopic: {
    borderRadius: 12,
    paddingTop: 10,
    paddingBottom: 10
  },
  label: {
    fontFamily: 'Roboto Regular',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.4px',
    textAlign: 'left',
    marginBottom: 15
  },
  dash: {
    color: '#3987CF',
    marginRight: 3
  }
}))
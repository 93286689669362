import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button } from '@material-ui/core'

import { LoginScreen } from './LoginScreen'

import { useLogic, useStore } from '../hooks/storeHook'
import { useHistory } from 'react-router-dom'
import { appRoutes } from '../common/appRoutes'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

export const AccountScreen = observer(
  () => {
    const logic = useLogic()
    const history = useHistory()
    const classes = useStyles()
    const {user} = useStore()

    if (!user) {
      return <LoginScreen/>
    }

    return (
      <div className="body_wrapper_zero">
        <Box padding={5}>
          <Box marginBottom={3}>
            Вы авторизованы: {[user.name, user.email].filter(_ => _).join(', ')}.
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-around" width="300px">
            {user.isSuperAdmin &&
             <button
               className={clsx('default-button', classes.superAdminButton)}
               onClick={() => history.push(appRoutes.AdminUsers)}
             >
               <span>Супер-админка</span>
             </button>
            }

            <Box>
              <Button onClick={logic.logout} variant="contained" color="primary">
                Выйти
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    )
  })

const useStyles = makeStyles({
  superAdminButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    height: 36.5,
    margin: 0
  }
})
import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormLabel, Grid } from '@material-ui/core'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

export const MoySkladWebhookPanel =
  observer(
    () => {
      const {showSuccess, showError} = useMessages()

      const {syncStore} = useStore()

      const logic = useLogic()
      const [isLoading, setIsLoading, setNotLoading] = useBoolState()
      const classes = useStyles()

      const onLoad = async () => {
        setIsLoading()

        try {
          await logic.verifyMoySkladWebhooks()
          showSuccess('Вебхуки проверены успешно!')
        } catch (e) {
          console.error('Failed to verify MoySklad webhooks', e)
          showError('Не удалось проверить')
        } finally {
          setNotLoading()
        }
      }

      if (!syncStore.moySkladAccount) {
        return null
      }

      return (
        <Box className={classes.pageContainer}>
          <Grid item xs={12}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <FormLabel className={classes.label}>
                      Отслеживание статуса заказа
                    </FormLabel>

                  </Box>


                  <button
                    className={clsx(classes.saveButton, 'default-button')}
                    disabled={isLoading}
                    onClick={onLoad}>
                    <p>
                      {
                        isLoading ? 'Проверяем...' : 'Проверить статус вебхука'
                      }
                    </p>
                  </button>

                </Box>
              </Grid>
            </Grid>
          </Grid>

        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12
    },
    helperText: {
      fontSize: 11,
      lineHeight: '12px',
      marginTop: 5
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    label: {
      fontSize: 12,
      fontWeight: 600,
      color: '#1F364D'
    },
    header: {
      fontSize: 18,
      fontWeight: 600,
      color: '#000',
      padding: 5,
      lineHeight: '21.6px',
      margin: '10px 10px 30px 10px'
    },
    saveButton: {
      width: 250,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    }
  })
)
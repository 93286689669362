import React from 'react'
import { observer } from 'mobx-react-lite'
import { sortBy } from 'lodash'

import { SelectProps } from '@material-ui/core/Select/Select'
import { FormHelperText, MenuItem, Select } from '@material-ui/core'

import { StorePickerBaseProps } from './WarehousePicker'
import { BootstrapInput } from './MoySkladAttributeSelect'

import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { required } from '../common/objectUtils'
import { fixMsAppScrollProps } from '../common/msAppUtils'

import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'

export type MsStorePickerProps =
  StorePickerBaseProps
  & {
  msStores: MoySkladStoreModel[]

  filterMatchedStores?: boolean

  onChange: (msStore: MoySkladStoreModel | null) => void
} & ({
  allowEmpty: true
  msStoreId: string | undefined
} | {
  allowEmpty?: false
  msStoreId: string
})

export const MoySkladStorePicker =
  observer(
    ({msStoreId, formData, msStores, filterMatchedStores, ...props}: MsStorePickerProps) => {
      const menuClasses = useDefaultMenuItemStyles()

      const selectedMsStore = msStores.find(_ => _.id === msStoreId)

      const availableMsStores =
        sortBy(
          filterMatchedStores
          ? msStores.filter(msStore => formData.stores.every(store => !store.msStoreIds.includes(msStore.id)))
                    .concat(selectedMsStore ?? [])
          : msStores,
          _ => _.name, _ => _.id)

      const onSelectChange: SelectProps['onChange'] =
        ({target: {value: newValue}}) => {
          if (props.allowEmpty && !newValue) {
            props.onChange(null)
            return
          }

          if (typeof newValue === 'string') {
            props.onChange(required(msStores.find(_ => _.id === newValue)))
          } else {
            throw new Error(`Bad value for MS store select: ${newValue}, ${typeof newValue}`)
          }
        }

      return (
        <>
          <Select
            value={msStoreId ?? ''}
            onChange={onSelectChange}
            MenuProps={fixMsAppScrollProps}
            input={<BootstrapInput/>}
            style={{maxWidth: '100%', overflow: 'hidden'}}
          >
            {
              props.allowEmpty &&
              <MenuItem>&mdash;</MenuItem>
            }

            {
              !props.allowEmpty && !selectedMsStore &&
              <MenuItem disabled className={menuClasses.menuItem} value={msStoreId}>
                <FormHelperText error>Не найден в МоёмСкладе: {msStoreId}</FormHelperText>
              </MenuItem>
            }

            {
              availableMsStores.map(msStore =>
                <MenuItem
                  className={menuClasses.menuItem}
                  key={msStore.id}
                  value={msStore.id}>
                  {msStore.name}
                </MenuItem>)
            }
          </Select>
        </>
      )
    })
import { Constrained, EnumKey } from '../common/tsUtils'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'
import { AccountModelBase } from '../server/mpsklad_core/Models/AccountModelBase'
import { MoySkladAccountModel } from '../server/mpsklad_core/Models/MoySkladAccountModel'
import { YandexMarketAccountModel } from '../server/mpsklad_core/Models/YandexMarketAccountModel'

// TODO: Replace with IntegrationType where possible
export type IntegrationTypeKey = EnumKey<typeof IntegrationType>

export type IntegrationAccountTypeMap =
  Constrained<{ [I in IntegrationTypeKey]: AccountModelBase }, {
    Ozon: OzonAccountModel,
    Wildberries: WbAccountModel,
    MoySklad: MoySkladAccountModel,
    YandexMarket: YandexMarketAccountModel
  }>

export type SyncAccountInfo<I extends IntegrationTypeKey> = {
  type: I

  account: IntegrationAccountTypeMap[I]

  displayName: string
}

export const integrationNames: {
  [integration in IntegrationTypeKey]: string
} = {
  Ozon: 'Ozon',
  Wildberries: 'Wildberries',
  MoySklad: 'МойСклад',
  YandexMarket: 'Yandex.Market'
}

export const integrationTypeNames: {
  [integrationType in IntegrationType]: string
} = {
  [IntegrationType.Ozon]: integrationNames.Ozon,
  [IntegrationType.Wildberries]: integrationNames.Wildberries,
  [IntegrationType.MoySklad]: integrationNames.MoySklad,
  [IntegrationType.YandexMarket]: integrationNames.YandexMarket
}

export const integrationTypeByKey: {
  [integration in IntegrationTypeKey]: IntegrationType
} = {
  Ozon: IntegrationType.Ozon,
  Wildberries: IntegrationType.Wildberries,
  MoySklad: IntegrationType.MoySklad,
  YandexMarket: IntegrationType.YandexMarket
}

export type AllowedIntegrationsMap = {
  [storeType in StoreType]: boolean
}
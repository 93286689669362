import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid } from '@material-ui/core'

import { ImportStatusTable } from './ImportStatusTable'
import { ImportStocksForm, ImportStocksFormProps } from './ImportStocksForm'
import { ImportProductsForm, ImportProductsFormProps } from './ImportProductsForm'

import { IServerTableData } from '../types/tableTypes'
import { StoreAccountRequiredProps } from '../types/accountProps'
import { IntegrationTypeKey } from '../types/integrationTypeUtils'

import { StoreProductModel } from '../server/mpsklad_core/Models/StoreProductModel'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'

export type ImportProductsProps<
  TProducts extends IServerTableData<TRow, TColumnId>,
  TRow extends StoreProductModel,
  TColumnId> = {
  products: TProducts
}

export type ImportScreenProps<
  TProducts extends IServerTableData<TRow, TColumnId>,
  TRow extends StoreProductModel,
  TColumnId> =
  StoreAccountRequiredProps<StoreAccountModelBase>
  & ImportProductsFormProps<TProducts, TRow, TColumnId>
  & ImportStocksFormProps<TProducts, TRow, TColumnId>
  & ImportProductsProps<TProducts, TRow, TColumnId>
  & {
  type: Exclude<IntegrationTypeKey, 'MoySklad'>

  TableComponent: FunctionComponent<ImportProductsProps<TProducts, TRow, TColumnId>>
}

export const ImportScreen =
  observer(
    <TProducts extends IServerTableData<TRow, TColumnId>,
      TRow extends StoreProductModel,
      TColumnId>(
      {
        account,
        type,
        importProductsFunc,
        importStocksFunc,
        products,
        TableComponent
      }: ImportScreenProps<TProducts, TRow, TColumnId>) =>
      <Box padding={3}>
        <Grid container direction="column">
          <Grid item lg={10} xl={3} xs={6}>
            <ImportStatusTable type={type} states={account.syncStates}/>
          </Grid>

          <Grid item lg={10} xl={8}>
            <ImportProductsForm<TProducts, TRow, TColumnId>
              account={account}
              importProductsFunc={importProductsFunc}
              products={products}
            />
          </Grid>

          <Grid item lg={10} xl={8}>
            <ImportStocksForm<TProducts, TRow, TColumnId>
              account={account}
              importStocksFunc={importStocksFunc}
              products={products}
            />
          </Grid>

          <Grid item>
            <TableComponent products={products}/>
          </Grid>
        </Grid>
      </Box>
  )
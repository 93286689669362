import React from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { AppRoute } from './AppRoute'
import { StatusScreen } from './StatusScreen'
import { WbSyncTabsScreen } from './WbSyncScreen'
import { OzonSyncTabsScreen } from './OzonSyncScreen'
import { MoySkladSyncScreen } from './MoySkladSyncScreen'
import { createAllowedTabs } from './RouteTabs'
import { YandexMarketSyncTabsScreen } from './YandexMarketSyncScreen'

import { useLogic, useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { useLazyEffect } from '../hooks/commonHooks'

export const SyncRoute = observer(() => {
  const {allowedIntegrations, getHomeNav} = useStore()
  const history = useHistory()
  const location = useLocation()
  const homeNav = getHomeNav()
  const {getSubRoute} = useLogic()

    const allowedTabs = [
      ...createAllowedTabs(
        allowedIntegrations, [{
          type: StoreType.Ozon,
          title: 'Ozon',
          route: appRoutes.Sync.ozon
        }, {
          type: StoreType.Wildberries,
          title: 'Wildberries',
          route: appRoutes.Sync.wildberries
        }, {
          type: StoreType.YandexMarket,
          title: 'Yandex.Market',
          route: appRoutes.Sync.yandexmarket
        }]), {
        title: 'МойСклад',
        route: appRoutes.Sync.moySklad
      }, {
        title: 'Статусы',
        route: appRoutes.Sync.status
      }]

  // TODO: (redesign) Why the is routing backwards? Why do we react to location? That's router's job!
  useLazyEffect(() => {
    const subRoute = getSubRoute(homeNav?.storeType)

    if (subRoute && location.pathname === appRoutes.Sync.base) {
      history.push(`${appRoutes.Sync.base}/${subRoute}`)
    }
  }, [homeNav?.storeType, location.pathname])

  // TODO: Map and filter allowedTabs within the Switch?
  return (
    <div className="body_wrapper_zero">
      <Box flex={1}>
        <Switch>
          {
            allowedIntegrations[StoreType.Ozon] &&
            <AppRoute path={appRoutes.Sync.ozon} component={OzonSyncTabsScreen}/>
          }

          {
            allowedIntegrations[StoreType.Wildberries] &&
            <AppRoute path={appRoutes.Sync.wildberries} component={WbSyncTabsScreen}/>
          }

          {
            allowedIntegrations[StoreType.YandexMarket] &&
            <AppRoute path={appRoutes.Sync.yandexmarket} component={YandexMarketSyncTabsScreen}/>
          }

          <AppRoute path={appRoutes.Sync.moySklad} component={MoySkladSyncScreen}/>

          <AppRoute path={appRoutes.Sync.status} component={StatusScreen}/>

          <Redirect from="*" to={allowedTabs[0].route}/>
        </Switch>
      </Box>
    </div>
  )
})
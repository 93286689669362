import React from 'react'
import { observer } from 'mobx-react-lite'

import { useApi, useLogic, useStore } from '../hooks/storeHook'

import { SetAccountToggleModel } from '../server/mpsklad_core/Models/SetAccountToggleModel'
import { AccountToggleSwitch } from './AccountToggleSwitch'

export const MoySkladUseNumerationRow =
  observer(
    () => {
      const {syncStore: {moySkladAccount}} = useStore()

      const api = useApi()
      const logic = useLogic()

      if (!moySkladAccount) {
        return null
      }

      const onConfirm =
        async (isEnabled: boolean) =>
          !isEnabled
          || await logic.showDialog(
            `Будет использоваться автоматическая генерация названий в МоёмСкладе!
             Внимание! Для корректной генерации необходимо изменить название последнего заказа и последней отгрузки на ваш шаблон,
             иначе будут использоваться старые номера.`)

      const onSubmit =
        ({isEnabled}: SetAccountToggleModel) =>
          api.userSync.setMoySkladNumeration(isEnabled)

      return <AccountToggleSwitch
        account={moySkladAccount}
        onConfirm={onConfirm}
        onSubmit={onSubmit}
        getAccountToggle={() => moySkladAccount.useMoySkladNumeration}
        setAccountToggle={isEnabled => moySkladAccount.useMoySkladNumeration = isEnabled}
        getSuccessMessage={isEnabled => `Используем нумерацию ${isEnabled ? 'МоегоСклада' : 'Маркетплейса'}`}
      />
    })
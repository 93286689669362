import { makeStyles } from '@material-ui/core/styles'

export const useDefaultInputStyles = makeStyles(() => ({
  input: {
    '& .MuiInputBase-input': {
      color: '#000',
      height: 36,
      fontSize: 12
    }
  }
}))
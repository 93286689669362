import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'

import { useLogic, useStore } from '../hooks/storeHook'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

export const AppDialog = observer(
  () => {
    const {dialog} = useStore()
    const classes = useStyles()

    const logic = useLogic()

    if (dialog === null) {
      return null
    }

    return (
      <Dialog open onClose={logic.declineDialog}>
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          {
            dialog.title &&
            <Box className={classes.title}>
              {dialog.title}
            </Box>
          }
        </Box>

        <DialogContent>
          <DialogContentText className={classes.content}>
            {dialog.text}
          </DialogContentText>
        </DialogContent>

        <DialogActions className={classes.actions}>

          <button
            className={clsx(classes.cancelButton, 'default-button')}
            onClick={logic.declineDialog}>
            <p>{dialog.declineButton}</p>
          </button>

          <button
            className={clsx(classes.saveButton, 'default-button')}
            onClick={logic.acceptDialog}
          >
            <p>{dialog.acceptButton}</p>
          </button>

        </DialogActions>
      </Dialog>
    )
  })

const useStyles = makeStyles(
  () => ({
    title: {
      padding: 20,
      fontFamily: 'Roboto Regular',
      fontSize: '20px',
      color: '#1F364D'
    },
    content: {
      fontFamily: 'Roboto Regular',
      fontSize: '17px',
      whiteSpace: 'pre-line'
    },
    actions: {
      justifyContent: 'center !important'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    }
  }))
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLogic, useStore } from '../hooks/storeHook'
import { observer } from 'mobx-react-lite'

import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { AccountSyncSwitch } from './AccountSyncSwitch'

import { integrationTypeKeyByNavStoreType, SideBarTab } from '../types/navStore'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'
import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'

import { accountNavigationLinks, appRoutes } from '../common/appRoutes'

import productsIcon from '../assets/products.svg'
import ordersIcon from '../assets/order.svg'
import settingsIcon from '../assets/setting.svg'
import printIcon from '../assets/print.svg'
import importIcon from '../assets/import.svg'
import logsIcon from '../assets/logs.svg'

export type AccountNavigationProps = {
  isSelected: boolean;
  account: StoreAccountModelBase;
  hasMoySkladApp: boolean;
  onAccountChange: (accountIndex: number, actionIndex: number) => void;
  accountIndex: number;
};

const tabData = {
  [SideBarTab.Products]: {imgSource: productsIcon, text: 'Товары'},
  [SideBarTab.Orders]: {imgSource: ordersIcon, text: 'Заказы'},
  [SideBarTab.Configuration]: {imgSource: settingsIcon, text: 'Настройка'},
  [SideBarTab.Print]: {imgSource: printIcon, text: 'Печать'},
  [SideBarTab.Import]: {imgSource: importIcon, text: 'Импорт'},
  [SideBarTab.Logs]: {imgSource: logsIcon, text: 'Логи'}
}

export const AccountNavigation =
  observer(
    ({
       isSelected,
       account,
       onAccountChange,
       accountIndex
     }: AccountNavigationProps) => {

      const store = useStore()
      const logic = useLogic()

      const classes = useStyles()

      const handleAccountChange = () => {
        onAccountChange(accountIndex, store.homeNavRequired.sideBarTab ?? SideBarTab.Products)
      }

      const onTabClick = (newTabIndex: number) => {
        // TODO: (redesign) Don't use index - we have enums for these tabs
        store.setSideBarTab(newTabIndex)
      }

      const currentTab = store.getHomeNav()?.sideBarTab ?? SideBarTab.Products

      // TODO: (redesign) Make this a method in logic
      const getSetAccountSyncFunc = (storeType: NavStoreType) => {
        switch (storeType) {
          case NavStoreType.Ozon:
            return logic.setOzonAccountSync
          case NavStoreType.YandexMarket:
            return logic.setYandexMarketAccountSync
          case NavStoreType.Wildberries:
            return logic.setWbAccountSync
          default:
            throw new Error('Unsupported NavStoreType')
        }
      }

      return (
        <Box className={clsx('nav_account', isSelected && 'nav_account_selected')}>
          <Box
            className={classes.accountInfo}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={handleAccountChange}
            aria-disabled={isSelected}>
            <Typography className={classes.accountName}>{account.name}</Typography>
            <AccountSyncSwitch account={account} setSync={getSetAccountSyncFunc(account.storeType)}/>
          </Box>
          {isSelected && (
            <Box className="nav_account_actions">
              <List>
                {accountNavigationLinks.map((link, index) => {
                  const {imgSource, text} = tabData[link.tab]
                  return (
                    <ListItem
                      key={index}
                      component={NavLink}
                      to={link.route}
                      className={clsx(
                        'nav_account_link',
                        (index === currentTab) && 'nav_account_link_selected',
                        classes.listItem)}
                      onClick={() => onTabClick(index)}
                      button
                    >
                      <ListItemIcon
                        className={
                          clsx(
                            classes.listItemIcon,
                            'nav_account_link',
                            index === currentTab && 'nav_account_link_selected')}>
                        <img src={imgSource} alt={text}/>
                      </ListItemIcon>

                      <ListItemText
                        className={
                          clsx(
                            classes.listItemText,
                            'nav_account_link',
                            index === currentTab && classes.listItemTextSelected)}
                        primary={text}
                      />
                    </ListItem>
                  )
                })}

                <ListItem
                  component={NavLink}
                  to={appRoutes.SyncLogs.route(
                    {integration: integrationTypeKeyByNavStoreType[account.storeType], accountId: account.id})}
                  className={clsx(
                    'nav_account_link', SideBarTab.Logs === currentTab && 'nav_account_link_selected',
                    classes.listItem)}
                  button
                  onClick={() => onTabClick(accountNavigationLinks.length)}
                >
                  <ListItemIcon
                    className={clsx(
                      classes.listItemIcon,
                      'nav_account_link', (SideBarTab.Logs === currentTab) && 'nav_account_link_selected')}>
                    <img src={logsIcon} alt="Логи"/>
                  </ListItemIcon>

                  <ListItemText
                    className={clsx(
                      classes.listItemText,
                      'nav_account_link', (SideBarTab.Logs === currentTab) && classes.listItemTextSelected)}
                    primary="Логи"/>
                </ListItem>

              </List>
            </Box>
          )}
        </Box>
      )
    })

const useStyles = makeStyles(() => ({
  accountName: {
    fontFamily: 'Roboto Regular',
    fontSize: 14,
    fontWeight: 700
  },
  accountInfo: {
    padding: '10px 10px 10px 22px',
    cursor: 'pointer'
  },
  listItemIcon: {
    minWidth: 30
  },
  listItem: {
    padding: '5px 0 0 10px',
    '&:hover': {
      backgroundColor: '#FFF'
    }
  },
  listItemText: {
    '& span': {
      fontFamily: 'Roboto Regular',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '14.4px'
    }
  },
  listItemTextSelected: {
    '& span': {
      color: '#3987CF',
      fontFamily: 'Roboto Regular',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '14.4px'
    }
  }
}))
import React from 'react'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, FormHelperText, TableCell, TableSortLabel } from '@material-ui/core'

import { MyHeadCellProps } from '../../types/tableTypes'
import { selectColumnId } from '../../types/myProductColumnIds'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'

export const MyHeadCell =
  observer(
    <TColumnId extends string, TRow extends object>
    ({
       table: {
         totalRowCount, selectedRowCount,
         toggleAllRowsSelection,
         getColumnOrder, toggleOrder
       },
       column
     }: MyHeadCellProps<TColumnId, TRow>) => {
      const {id, title, titleHint, sortable} = column

      const classes = useStyles()

      if (id === selectColumnId) {
        return (
          <TableCell className="table-border" padding="checkbox">
            <Checkbox
              className={classes.checkbox}
              checked={totalRowCount > 0 && selectedRowCount === totalRowCount}
              indeterminate={selectedRowCount > 0 && selectedRowCount < totalRowCount}
              onChange={toggleAllRowsSelection}
              icon={<CircleUnchecked/>}
              checkedIcon={<CircleCheckedFilled style={{color: '#3987cf'}}/>}
            />
          </TableCell>
        )
      }

      if (!sortable) {
        return (
          <TableCell align="left" className={classes.cell}>{title}
            {
              !!titleHint &&
              <FormHelperText style={{textAlign: 'left'}}>
                {titleHint}
              </FormHelperText>
            }
          </TableCell>
        )
      }

      const {isOrdered, orderDirection} = getColumnOrder(id)

      return (
        <TableCell align="left" className={classes.cell} sortDirection={orderDirection}>
          <TableSortLabel
            active={isOrdered}
            direction={orderDirection}
            onClick={() => toggleOrder(column)}
          >
            {title}

            {
              !!titleHint &&
              <FormHelperText style={{textAlign: 'left'}}>
                {titleHint}
              </FormHelperText>
            }
          </TableSortLabel>
        </TableCell>
      )
    })

const useStyles = makeStyles(
  ({palette}) => ({
    cell: {
      border: '2px solid #D7E7F5',
      padding: '8px 10px',
      fontSize: '12px'
    },
    checkbox: {
      color: palette.mpsklad.background
    }
  }))
import React from 'react'
import { observer } from 'mobx-react-lite'

import { SyncErrorIcon } from './SyncErrorIcon'

import { integrationNames, IntegrationTypeKey } from '../../types/integrationTypeUtils'
import { ExternalProductModel } from '../../server/mpsklad_core/Models/ExternalProductModel'

export type MultiProductSyncErrorsProps = {
  type: IntegrationTypeKey

  products: ExternalProductModel[]
}

export const MultiProductSyncErrors =
  observer(
    ({type, products}: MultiProductSyncErrorsProps) => {
      const errorProducts = products.filter(_ => _.syncErrorId)

      if (errorProducts.length === 0) {
        return null
      }

      return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <span>
            {integrationNames[type]}:
          </span>

          {
            errorProducts.map(product =>
              <SyncErrorIcon key={product.id} type={type} errorId={product.syncErrorId}/>)
          }
        </div>
      )
    })
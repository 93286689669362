import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button, CircularProgress, Typography } from '@material-ui/core'

import { StoreAccountMsData, MoySkladDataForm, MoySkladDataFormProps } from './MoySkladDataForm'

import { useApi } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { useLocalObservableOptional } from '../common/mobxUtils'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'

export type MoySkladDataFormContainerProps =
  Pick<MoySkladDataFormProps, 'onChange'> & {
  storeType: StoreType
  msData: StoreAccountMsData | null

  /**
   * Can be null during account creation.
   */
  storeAccount: StoreAccountModelBase | null
}

export const MoySkladDataFormContainer =
  observer(
    ({storeType, storeAccount, onChange}: MoySkladDataFormContainerProps) => {
      const {showError} = useMessages()

      const api = useApi()

      const msContainer = useLocalObservableOptional<StoreAccountMsData>()
      const [isLoading, setLoading, setLoaded] = useBoolState()

      const onLoadClick = async () => {
        setLoading()

        try {
          const {organizations, counterparties, contracts, salesChannels, projects} =
            await api.userSync.getMoySkladData(storeType, storeAccount?.id)

          if (organizations.length === 0) {
            showError('Нет организаций в МоёмСкладе')
            return
          }

          if (counterparties.length === 0) {
            showError('Нет контрагентов в МоёмСкладе с типом "Юридическое лицо"')
            return
          }

          msContainer.dataOrNull = {
            organization: {
              options: organizations,
              value: organizations.find(_ => _.id === storeAccount?.msOrganization) ?? organizations[0],
              searchTerm: '',
              isSearchLoading: false
            },
            counterparty: {
              options: counterparties,
              value: counterparties.find(_ => _.id === storeAccount?.msCounterparty) ?? counterparties[0],
              searchTerm: '',
              isSearchLoading: false
            },
            contract: {
              options: contracts,
              value: contracts.find(_ => _.id === storeAccount?.msContractId) ?? null,
              searchTerm: '',
              isSearchLoading: false
            },
            salesChannel: {
              options: salesChannels,
              value: salesChannels.find(_ => _.id === storeAccount?.msSalesChannelId) ?? null,
              searchTerm: '',
              isSearchLoading: false
            },
            project: {
              options: projects,
              value: projects.find(_ => _.id === storeAccount?.msProjectId) ?? null,
              searchTerm: '',
              isSearchLoading: false
            },
            get formData() {
              return {
                msOrganization: this.organization.value.id,
                msCounterparty: this.counterparty.value.id,
                msContractId: this.contract.value?.id,
                msSalesChannelId: this.salesChannel.value?.id,
                msProjectId: this.project.value?.id
              }
            }
          }

          onChange(msContainer.dataOrNull.formData)
        } catch (e) {
          console.error('Failed to get MoySklad data', e)
          showError('Не удалось загрузить данные из МоегоСклада')
        } finally {
          setLoaded()
        }
      }

      return (
        <>
          <Box marginBottom={2}>
            <Typography variant="h6">
              МойСклад
            </Typography>
          </Box>

          {
            isLoading
            ? <CircularProgress size={25}/>
            : msContainer.dataOrNull
              ? <MoySkladDataForm msData={msContainer.data} onChange={onChange}/>
              : <Button type="button" variant="contained" onClick={onLoadClick}>Настроить</Button>
          }
        </>
      )
    })
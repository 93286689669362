import { createTheme } from '@material-ui/core'
import { ruRU } from '@material-ui/core/locale'

// TODO: Move to app context?
export const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1080,
      lg: 1440,
      xl: 2160
    }
  },
  palette: {
    primary: {
      main: '#353535'
    },
    secondary: {
      main: '#2178B3'
    },
    error: {
      main: '#FF4242'
    },
    background: {
      default: '#FFF'
    },
    mpsklad: {
      background: '#3987CF',
      backgroundDim: '#78A5CF',
      text: '#FFF',
      textDim: '#E6E6E6'
    },
    moySklad: {
      background: '#1F75A8',
      backgroundDim: '#3987CF',
      text: '#FFF',
      textDim: '#E6E6E6'
    },
    ozon: {
      background: '#005BFF',
      text: '#FFF'
    },
    wildberries: {
      background: '#A11F94',
      text: '#FFF'
    },
    yandexMarket: {
      background: '#FC0',
      text: '#212121'
    }
  }
}, ruRU)
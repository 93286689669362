import React, { useCallback } from 'react'

import { PropFilterItemBase } from './StringPropFilter'
import { MenuItem, Select } from '@material-ui/core'
import { fixMsAppScrollProps } from '../common/msAppUtils'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'
import { BootstrapInput } from './MoySkladAttributeSelect'

export type BooleanPropFilterItem = PropFilterItemBase & {
  isBoolean: true

  onText?: string

  offText?: string

  allowMixed?: boolean

  mixedText?: string
}

export const BooleanPropFilter =
  ({placeholder, value, onText, offText, allowMixed, mixedText, setValue}: BooleanPropFilterItem) => {

    const menuClasses = useDefaultMenuItemStyles()

    const onSelectChange = useCallback(({target: {value}}: {target: {value: unknown}}) => {
      if (typeof value === 'string') {
        setValue(value === resetBooleanValue ? undefined : value)
        return
      }

      throw new Error(`Unsupported value type: ${typeof value}. Expected a string.`)
    }, [setValue])

    return <Select
      value={value ?? resetBooleanValue}
      onChange={onSelectChange}
      MenuProps={fixMsAppScrollProps}
      input={<BootstrapInput/>}
    >
      <MenuItem
        className={menuClasses.menuItem}
        value={resetBooleanValue}
      >
        {placeholder}
      </MenuItem>

      <MenuItem
        className={menuClasses.menuItem}
        value={JSON.stringify(true)}
      >
        {onText ?? 'Включена'}
      </MenuItem>

      <MenuItem
        className={menuClasses.menuItem}
        value={JSON.stringify(false)}
      >
        {offText ?? 'Отключена'}
      </MenuItem>

      {
        allowMixed &&
        <MenuItem
          className={menuClasses.menuItem}
          value={JSON.stringify(null)}
        >
          {mixedText ?? 'Вкл + Откл'}
        </MenuItem>
      }
    </Select>
  }

/**
 * NOTE: This exists because undefined doesn't work (obviously)
 */
const resetBooleanValue = 'none'
import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import {
  Box,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid, Link,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircle, OpenInNewOutlined } from '@material-ui/icons'

import clsx from 'clsx'
import { useBoolState } from '../../../hooks/commonHooks'
import { exact } from '../../../common/tsUtils'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useLogic, useStore } from '../../../hooks/storeHook'

import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditWbAccountModel } from '../../../server/mpsklad_core/Models/EditWbAccountModel'

export const WildberriesApiSettings =
  observer(() => {
    const {showSuccess, showError} = useMessages()

    const logic = useLogic()
    const store = useStore()

    const accountId = store.getHomeNav()?.accountId
    const account = logic.tryGetWbAccount(accountId)

    const [isLoading, setIsLoading, setNotLoading] = useBoolState()

    const [isFBS, setIsFBS] = useState(account?.isFBS ?? true)

    const [newToken, setNewToken] = useState(account?.tokenCombined)
    const [name, setName] = useState(account?.name ?? '')

    const classes = useStyles()

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()

        if (!newToken) {
          showError('Необходимо ввести код подтверждения.')
          return
        }

        setIsLoading()

        try {
          const formData =
            exact<Omit<EditWbAccountModel, 'id'>>({
              isFBS,
              isUpdatePrices: account?.isUpdatePrices ?? false,
              isGeneratingPayments: account?.isGeneratingPayments ?? false,
              isUsingCommissions: account?.isUsingCommissions ?? false,
              ordersCommissionsRecording: account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing,
              documentsCommissionsRecording: account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing,
              name: name || undefined,
              msSalesReturnStoreId: account?.msSalesReturnStoreId,
              tokenCombined: newToken,
              msData: {
                msOrganization: account?.msOrganization ?? '',
                msCounterparty: account?.msCounterparty ?? '',
                msContractId: account?.msContractId ?? undefined,
                msSalesChannelId: account?.msSalesChannelId ?? undefined,
                msProjectId: account?.msProjectId ?? undefined
              }
            })

          if (account) {
            await logic.editWbAccount({id: account.id, ...formData})
          } else {
            await logic.createWbAccount({id: 0, ...formData})
          }

          store.syncStore.setYandexMarketUserCode(parseInt(newToken))
          showSuccess('Сохранено!')
        } catch (e) {
          console.error('Failed to save YandexMarket account', e)
        } finally {
          setNotLoading()
        }
      }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>Настройки API</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>

              <Grid item className={classes.root} xs={12}>

                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>API токен
                    <span className={classes.star}>*</span>
                  </FormLabel>

                </Box>

                <TextField
                  required
                  id="newToken"
                  placeholder="12228"
                  variant="outlined"
                  size="small"
                  value={newToken}
                  onChange={e => setNewToken(e.target.value)}
                  fullWidth
                  InputProps={{
                    className: classes.input
                  }}
                />

                <FormHelperText className={classes.helperText}>
                  Создать токен можно в личном кабинете
                  Wildberries: <Link href="https://seller.wildberries.ru/supplier-settings/access-to-api"
                                     target="_blank">
                  Профиль - Настройки - Доступ к API <OpenInNewOutlined fontSize="inherit"/></Link>.
                  <br/>
                  Необходимо выбрать категории: Контент, Маркетплейс, Статистика, Цены и скидки.
                </FormHelperText>

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>Название аккаунта
                  </FormLabel>
                </Box>

                <TextField
                  required
                  id="campaignId"
                  placeholder="Wildberries 8922"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  fullWidth
                  InputProps={{
                    className: classes.input
                  }}
                />

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>
                    Схема работы
                  </FormLabel>
                </Box>

                <RadioGroup
                  name="ModelType"
                  value={isFBS}
                  onChange={e => setIsFBS(JSON.parse(e.target.value))}
                >
                  <FormControlLabel
                    value={true}
                    className={classes.workflow}
                    control={<Radio checkedIcon={<CheckCircle/>}/>}
                    label={<span className={classes.workflowLabel}>Продажа со своего склада (FBS)</span>}
                  />

                  <FormControlLabel
                    value={false}
                    className={classes.workflow}
                    control={<Radio checkedIcon={<CheckCircle/>}/>}
                    label={<span className={classes.workflowLabel}>Продажа со склада Wildberries (FBO)</span>}
                  />

                </RadioGroup>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box display="flex">
          <button className={clsx(classes.cancelButton, 'default-button')} disabled={isLoading}>
            <p>Отменить</p>
          </button>

          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}>
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>

        </Box>
      </Box>
    )
  })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12,
      paddingTop: 10,
      paddingBottom: 10
    },
    label: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    helperText: {
      fontFamily: 'Roboto Regular',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left'
    },
    textField: {
      '& .Mui-disabled': {
        backgroundColor: '#888888',
        opacity: 0.2
      }
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    star: {
      color: '#3987CF',
      marginLeft: 2
    },
    unifiedPricesLabel: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      color: '#1F364D'
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      margin: '10px 10px 30px 10px'
    },
    workflow: {
      '& span': {
        color: '#1F364D',
        fontFamily: 'Roboto Regular',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
        textAlign: 'left'
      },
      '& .Mui-disabled span': {
        color: '#888888 !important'
      },
      '& span:not(.Mui-disabled)': {
        color: '#3987CF'
      }
    },
    selectedWorkflow: {
      '& span': {
        color: '#3987CF !important',
        fontFamily: 'Roboto Regular',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '14.4px',
        textAlign: 'left'
      }
    },
    workflowLabel: {
      color: '#1F364D !important'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    }
  })
)
import React, { useCallback, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { AppBar, Avatar, Box, Container, Tab, Tabs, Toolbar } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import logo from '../assets/logo.svg'
import profile from '../assets/profile.svg'

import { useLogic, useStore } from '../hooks/storeHook'
import { appRoutes } from '../common/appRoutes'
import { useLazyEffect } from '../hooks/commonHooks'
import { makeStyles } from '@material-ui/core/styles'

import { HeaderTab, headerTabByNavStoreType, SideBarTab } from '../types/navStore'
import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'
import { StoreType } from '../server/mpsklad_core/Entity/StoreType'

import { ImpersonationBar } from './ImpersonationBar'
import { UserSyncWarning } from './UserSyncWarning'
import { MoySkladAppTrialNotificationBar } from './MoySkladAppTrialNotificationBar'
import { WbApiTokensExpirationNotificationBars } from './WbApiTokensExpirationNotificationBars'
import { StoreMatchingWarningBar } from './StoreMatchingWarningBar'
import { required } from '../common/objectUtils'

interface TabInfo {
  label: string;

  storeType?: NavStoreType;

  index: number;

  route?: string;
}

export const Header = observer(() => {
  const logic = useLogic()
  const store = useStore()

  const classes = useStyles()

  // TODO: (redesign) What is this effect supposed to do?
  useLazyEffect(() => {
      if (store.getHomeNav()) {
        setSelectedTabIndex(store.getHomeNav()?.headerTab ?? HeaderTab.MoySklad)
      } else {
        const navStoreType = required(tabs[0].storeType)
        const headerTab = headerTabByNavStoreType[navStoreType]

        store.setHomeNav(navStoreType, undefined, headerTab, SideBarTab.Configuration)
        setSelectedTabIndex(headerTab)
      }
    },
    [store.syncStore.wbAccounts, store.syncStore.ozonAccounts, store.syncStore.yandexMarketAccounts, store.syncStore.moySkladAccount])

  const tabs: TabInfo[] = useMemo(() => {
    const allowedTabs: TabInfo[] = []

    if (store.allowedIntegrations[StoreType.Ozon]) {
      allowedTabs.push({label: 'OZON', storeType: NavStoreType.Ozon, index: 0, route: appRoutes.Home.base})
    }

    if (store.allowedIntegrations[StoreType.Wildberries]) {
      allowedTabs.push({label: 'WILDBERRIES', storeType: NavStoreType.Wildberries, index: 1, route: appRoutes.Home.base})
    }

    if (store.allowedIntegrations[StoreType.YandexMarket]) {
      allowedTabs.push({label: 'Я.МАРКЕТ', storeType: NavStoreType.YandexMarket, index: 2, route: appRoutes.Home.base})
    }

    allowedTabs.push({label: 'МОЙСКЛАД', index: 3, route: appRoutes.Settings.moySklad})

    return allowedTabs
  }, [store.allowedIntegrations])

  // TODO: (redesign) Use mobx store instead of local state, it's there for a reason (I hope)
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

  const onTabChange = useCallback((_event: React.ChangeEvent<{}>, newTabIndex: number) => {
    const tab = tabs[newTabIndex]

    if (tab.storeType) {
      logic.setNavFirstAccountFromSpecifiedStore(tab.storeType)
    } else {
      logic.setHomeNavFirstAccount()
    }

    setSelectedTabIndex(newTabIndex)
  }, [tabs, logic])

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container maxWidth={false}>
        <Toolbar>
          <Box display="flex" justifyContent="space-around" alignItems="center" width="100%">
            <Box>
              <ImpersonationBar/>
              <UserSyncWarning/>
              <MoySkladAppTrialNotificationBar/>
              <WbApiTokensExpirationNotificationBars/>
              <StoreMatchingWarningBar/>
            </Box>
            <Box className="logo_container">
              <img src={logo} alt="Logo"/>
            </Box>
            <Tabs
              value={selectedTabIndex}
              onChange={onTabChange}
              className="navigation"
              indicatorColor="secondary"
              TabIndicatorProps={{
                style: {display: 'none'}
              }}
            >
              {tabs.map(({label, index, route}) => (
                <Tab
                  key={index}
                  label={label}
                  component={NavLink}
                  to={route ?? ''}
                  className={clsx(
                    'nav_link',
                    classes.tab,
                    selectedTabIndex === index && classes.navLinkUnderlined)}
                  disableRipple
                />
              ))}
            </Tabs>
            <Box className="user_account">
              <NavLink to={appRoutes.Account}>
                <Avatar src={profile} alt="Profile" style={{width: 30, height: 30}}/>
              </NavLink>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
})

const useStyles = makeStyles(() => ({
  appBar: {
    color: '#000',
    background: '#FFF',
    borderBottom: '1px solid #DFE4EB'
  },
  tab: {
    minWidth: 50,
    marginRight: 40,
    '& span': {
      fontFamily: 'Roboto Regular',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '19.2px'
    }
  },
  navLinkUnderlined: {
    '& span': {
      fontFamily: 'Roboto Regular',
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '19.2px',
      display: 'inline-block',
      color: '#3987CF',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '-1px',
        height: '2px',
        backgroundColor: '#3987CF',
        display: 'block',
        width: '100%'
      }
    }
  }
}))
import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Box,
  Typography
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers'
import clsx from 'clsx'
import DateFnsUtils from '@date-io/date-fns'
import { useLazyEffect } from '../hooks/commonHooks'
import { useMousePosition } from '../hooks/useMousePositionHook'

interface CustomDateTimePickerProps {
  value: Date;

  onChange: (date: Date) => void;
}

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({value, onChange}) => {
  const classes = useStyles()
  const pickerClasses = usePickerToolbarStyles()

  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | null>(value)
  const [currentView, setView] = useState<'date' | 'hours'>('date')

  const [isMouseDown, setMouseDown] = useState(false)
  const [currentMouseHeight, setCurrentMouseHeight] = useState(0)
  const [currentDistance, setCurrentDistance] = useState(0)
  const [currentColumn, setCurrentColumn] = useState<'hours' | 'minutes'>('hours')
  const mousePosition = useMousePosition()

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }

  const handleTimeChange = (type: 'hours' | 'minutes', value: number) => {
    if (selectedDate) {
      const newDate = new Date(selectedDate)
      if (type === 'hours') {
        newDate.setHours(value)
      } else if (type === 'minutes') {
        newDate.setMinutes(value)
      }
      setSelectedDate(newDate)
    }
  }

  const handleClose = () => {
    if (selectedDate) {
      onChange(selectedDate)
    }
    setOpen(false)
  }

  const openPicker = () => {
    setOpen(true)
  }

  useLazyEffect(() => {
    if (isMouseDown) {

      const distance = currentMouseHeight - mousePosition.y

      if (mousePosition.y === 0) {
        return
      }

      const shift = (currentDistance - distance) / 40

      if (Math.abs(shift) > 1) {
        console.log(shift)

        const direction = shift < 0 ? 1 : -1

        setCurrentDistance(distance)
        if (currentColumn === 'hours') {
          selectedDate?.setHours((selectedDate?.getHours() + direction) % 24)
        } else {
          selectedDate?.setMinutes((selectedDate?.getMinutes() + direction) % 60)
        }
      }
    }
  }, [mousePosition.y])

  const renderTimeItems = (type: 'hours' | 'minutes') => {
    const count = type === 'hours' ? 24 : 60
    const current = type === 'hours' ? selectedDate?.getHours() : selectedDate?.getMinutes()
    const items = []

    if (current !== undefined) {
      for (let i = -2; i <= 2; i++) {
        let value = (current + i + count) % count
        const distance = Math.abs(i)
        const size = distance === 0 ? 5 : (2.5 - (distance - 1) * 0.5)
        const opacity = 1 - distance * 0.3
        const transform = `translateY(${i * 30}px) scale(${1 - distance * 0.1})`

        items.push(
          <div
            key={value}
            className={classes.timeItem}
            style={{
              fontSize: `${size}rem`,
              zIndex: 5 - distance,
              color: distance === 0 ? `rgb(74, 125, 172, ${opacity}` : `rgb(210, 210, 210, ${opacity}`,
              transform
            }}
            onClick={() => handleTimeChange(type, value)}
          >
            {value < 10 ? `0${value}` : value}
          </div>
        )
      }
    }

    return (
      <>
        <div className={classes.spacer}></div>
        {items}
        <div className={classes.spacer}></div>
      </>
    )
  }

  const getStyleClass = (view: 'date' | 'hours' | 'minutes') =>
    currentView === view ? pickerClasses.selected : pickerClasses.unselected

  return (
    <>
      <div className={classes.dateTimePicker}>
        <TextField
          variant="outlined"
          value={selectedDate?.toLocaleString()}
          onClick={openPicker}
          InputProps={{className: pickerClasses.input}}/>
      </div>
      <Dialog open={open} onClose={handleClose} onClick={() => setMouseDown(false)}>
        <Box padding={5}>
          <Box className={classes.dateTimePicker}>
            <Box className={clsx(pickerClasses.dateDisplay, getStyleClass('date'))} onClick={() => setView('date')}>
              <EventIcon className={pickerClasses.icon}/>
              <Typography className={pickerClasses.text} variant="body1">
                {selectedDate?.toLocaleDateString('ru-RU', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}
              </Typography>
            </Box>
            <Box className={clsx(pickerClasses.timeDisplay, getStyleClass('hours'))} onClick={() => setView('hours')}>
              <AccessTimeIcon className={pickerClasses.icon}/>
              <Typography className={pickerClasses.text} variant="body1">
                {selectedDate?.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
              </Typography>
            </Box>
          </Box>
        </Box>
        <DialogContent>
          {currentView === 'date' && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box className={classes.calendarContainer}>
                <Calendar date={selectedDate} onChange={handleDateChange}/>
              </Box>
            </MuiPickersUtilsProvider>
          )}
          {currentView === 'hours' && (
            <div className={classes.timePickerContainer}>
              <div className={classes.timeColumn}
                   onMouseDown={() => {
                     setCurrentColumn('hours')
                     setMouseDown(true)
                     setCurrentMouseHeight(mousePosition.y)
                   }}
                   onMouseUp={() => {
                     setMouseDown(false)
                     setCurrentMouseHeight(mousePosition.y)
                   }}>
                {renderTimeItems('hours')}</div>
              <span style={{
                color: 'rgb(74, 125, 172)',
                fontSize: '4rem',
                height: 'fit-content'
              }}>:</span>
              <div className={classes.timeColumn}
                   onMouseDown={() => {
                     setCurrentColumn('minutes')
                     setMouseDown(true)
                     setCurrentMouseHeight(mousePosition.y)
                   }}
                   onMouseUp={() => {
                     setMouseDown(false)
                     setCurrentMouseHeight(mousePosition.y)
                   }}>{renderTimeItems('minutes')}</div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const usePickerToolbarStyles = makeStyles({
  dateTimePicker: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none'
      }
    }
  },
  paper: {
    padding: '24px',
    borderRadius: '4px',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
    backgroundColor: '#fff',
    textAlign: 'center',
    width: '300px'
  },
  dateDisplay: {
    minWidth: 90,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 20px 8px 8px',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px'
  },
  timeDisplay: {
    minWidth: 90,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 20px 8px 8px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px'
  },
  selected: {
    backgroundColor: '#AED8FF',
    '& p': {
      color: '#4A7DAC'
    },
    '& svg': {
      color: '#4A7DAC'
    }
  },
  unselected: {
    backgroundColor: '#fff',
    border: '1px solid #E7F4FF',
    '& svg': {
      color: '#888'
    }
  },
  icon: {
    marginRight: '8px'
  },
  calendar: {
    marginTop: '16px',
    padding: '16px',
    backgroundColor: '#f4f6f8',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
    width: 100
  },
  text: {
    fontFamily: 'Roboto Regular',
    fontSize: 13
  },
  input: {
    height: 35,
    maxWidth: 270,
    fontSize: 12
  }
})

const useStyles = makeStyles((theme: Theme) => ({
  dateTimePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  timePickerContainer: {
    paddingTop: 40,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
    height: 'fit-content'
  },
  timeColumn: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '90px'
  },
  timeItem: {
    marginTop: 20,
    position: 'absolute',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'color 0.1s, font-size 0.1s, transform 0.1s',
    cursor: 'pointer',
    userSelect: 'none',
    fontFamily: 'Roboto Regular',
    fontWeight: 'bold'
  },
  spacer: {
    height: 80
  },
  calendarContainer: {
    '& .MuiTypography-root': {
      fontSize: '14px !important'
    },
    '& .MuiPickersCalendarHeader-transitionContainer .MuiTypography-root': {
      fontSize: '15px',
      color: '#1F364D',
      fontWeight: 'bold'
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: '#3987CF',
      color: '#fff'
    }
  }
}))

export default CustomDateTimePicker
import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch, useHistory } from 'react-router-dom'

import { appRoutes } from '../../../common/appRoutes'

import { moySkladSettingsRoutes } from '../settingsRoutes'

import { StatusScreen } from '../../StatusScreen'
import { MoySkladProductsTableScreen } from './MoySkladProductsTableScreen'
import { MoySkladSettingsScreen } from './MoySkladSettingsScreen'
import { RoutingTabs } from '../../RoutingTabs'
import { RouteTabItem } from '../../RouteTabItem'

export const MoySkladSettingsPage =
  observer(() => {

    const history = useHistory()

    const routes = useMemo((): RouteTabItem[] => {
      return (moySkladSettingsRoutes)
        .map((_) => ({title: _.title, route: _.route}))
    }, [])

    useEffect(() => {
      if (history.location.pathname === appRoutes.Settings.moySklad) {
        history.push(`${appRoutes.Settings.moySklad}/${routes[0].route}`)
      }
    })

    return (
      <>
        <RoutingTabs
          routeTabs={routes}
          baseRoute={appRoutes.Settings.moySklad}
          subRoute="moysklad">
          <Switch>
            <Route path={`${appRoutes.Settings.moySklad}/${routes[0].route}`}
                   render={() => <StatusScreen/>}/>

            <Route path={`${appRoutes.Settings.moySklad}/${routes[1].route}`}
                   render={() => <MoySkladProductsTableScreen/>}/>

            <Route path={`${appRoutes.Settings.moySklad}/${routes[2].route}`}
                   render={() => <MoySkladSettingsScreen/>}/>
          </Switch>
        </RoutingTabs>
      </>)
  })
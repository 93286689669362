import React, { FormEvent } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { useApi, useLogic } from '../../../hooks/storeHook'
import { useBoolState, useToggleState } from '../../../hooks/commonHooks'
import { useMessages } from '../../../hooks/snackbarHooks'

import { makeStyles } from '@material-ui/core/styles'
import { Box, FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core'

import { IOSSwitch } from '../../IOSSwitch'

import { OzonAccountModel } from '../../../server/mpsklad_core/Models/OzonAccountModel'

export type OzonOrdersSettingsProps = {
  account: OzonAccountModel
}

export const OzonOrdersSettings =
  observer(({account}: OzonOrdersSettingsProps) => {
    const logic = useLogic()

    const {
      userSync: {
        setOzonOrdersSettings
      }
    } = useApi()

    const {showSuccess, showError} = useMessages()
    const [isLoading, setIsLoading, setNotLoading] = useBoolState()

    const [autoShipmentEnabled, toggleAutoShipmentEnabled] =
      useToggleState(account?.autoShipmentEnabled ?? false)

    const [autoConfirmationEnabled, toggleAutoConfirmationEnabled] =
      useToggleState(account?.autoConfirmationEnabled ?? false)

    const [reserveMsOrdersEnabled, toggleReserveMsOrdersEnabled] =
      useToggleState(account?.reserveMsOrdersEnabled ?? false)

    const [ozonRealizationReportsSync, toggleOzonRealizationReportsSync] =
      useToggleState(account?.isSyncingRealizationReports ?? false)

    const classes = useStyles()

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()

        setIsLoading()

        try {
          await setOzonOrdersSettings({
            accountId: account.id,
            isAutoConfirmationEnabled: autoConfirmationEnabled,
            isAutoShipmentEnabled: autoShipmentEnabled,
            isReserveMsOrdersEnabled: reserveMsOrdersEnabled,
            isSyncingRealizationReports: ozonRealizationReportsSync
          })

          await logic.loadUserAccounts()

          showSuccess('Сохранено!')
        } catch (e) {
          console.error('Failed to save YandexMarket account', e)
          showError('Не удалось изменить настройкт заказов!')
        } finally {
          setNotLoading()
        }
      }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>Настройки API</Typography>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <FormLabel className={classes.unifiedPricesLabel}>
                      Автоматическая сборка заказов
                    </FormLabel>

                    <FormHelperText className={classes.helperText}>
                      Перевод заказов из статуса "Новый" в статус "Подтверждён".
                    </FormHelperText>
                  </Box>

                  <IOSSwitch checked={autoConfirmationEnabled} onChange={toggleAutoConfirmationEnabled}/>

                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={9}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <FormLabel className={classes.unifiedPricesLabel}>
                      Автоматическая отгрузка заказов
                    </FormLabel>

                    <FormHelperText className={classes.helperText}>
                      Создание документов отгрузки при переходе заказа в статус "Отгружен".
                    </FormHelperText>
                  </Box>

                  <IOSSwitch checked={autoShipmentEnabled} onChange={toggleAutoShipmentEnabled}/>

                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={9}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <FormLabel className={classes.unifiedPricesLabel}>
                      Резерв товаров в заказах МоегоСклада
                    </FormLabel>

                    <FormHelperText className={classes.helperText}>
                      При создании заказа в МоёмСкладе, его позиции будуь зарезервированы.
                    </FormHelperText>
                  </Box>

                  <IOSSwitch checked={reserveMsOrdersEnabled} onChange={toggleReserveMsOrdersEnabled}/>

                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={9}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <FormLabel className={classes.unifiedPricesLabel}>
                      Синхронизация отчётов о реализации
                    </FormLabel>

                    <FormHelperText className={classes.helperText}>
                      Создание отчёта комиссионера в МоёмСкладе. В него будут добавляться только товары, для которых
                      включена синхронизация.
                    </FormHelperText>
                  </Box>

                  <IOSSwitch checked={ozonRealizationReportsSync} onChange={toggleOzonRealizationReportsSync}/>

                </Box>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <Box display="flex">
          <button className={clsx(classes.cancelButton, 'default-button')} disabled={isLoading}>
            <p>Отменить</p>
          </button>

          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}>
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>

        </Box>
      </Box>
    )
  })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12,
      paddingBottom: 10,
      paddingTop: 5
    },
    label: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    helperText: {
      fontFamily: 'Roboto Regular',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left'
    },
    textField: {
      '& .Mui-disabled': {
        backgroundColor: '#888888',
        opacity: 0.2
      }
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    star: {
      color: '#3987CF',
      marginLeft: 2
    },
    unifiedPricesLabel: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      color: '#1F364D'
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      margin: '10px 10px 30px 10px'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    }
  })
)
import React from 'react'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

export const ImpersonationBar =
  observer(() => {
    const classes = useStyles()

    const api = useApi()

    const {user} = useStore()

    const {showInfo} = useMessages()

    if (!user?.isImpersonating) {
      return null
    }

    const onDeImpersionate =
      async (e: React.FormEvent) => {
        e.preventDefault()

        await api.admin.deImpersonate()

        // Reload the page to reset the impersonated User's state
        window.location.assign(appRoutes.AdminUsers)
      }

    const onIdClick =
      async () => {
        await navigator.clipboard.writeText(user.id)
        showInfo('Id скопирован')
      }

    const onEmailClick =
      async () => {
        await navigator.clipboard.writeText(user.email)
        showInfo('Email скопирован')
      }

    return (
      <div className={classes.wrap}>
        <Alert
          variant="filled"
          severity="warning"
          action={
            <form onSubmit={onDeImpersionate}>
              <Button type="submit" color="inherit" size="small" className={classes.submitButton}>
                Выйти
              </Button>
            </form>
          }
          className={classes.alert}
        >
          <span title={user.id} className={classes.clickableText} onClick={onIdClick}>Пользователь:</span>
          <span title="Скопировать" className={classes.clickableText} onClick={onEmailClick}>{user.email}</span>
        </Alert>
      </div>
    )
  })

const useStyles = makeStyles(
  (/*theme*/) => ({
    wrap: {
      marginTop: '60px',
      position: 'fixed',
      width: 700,
      // Centered
      top: 0,
      left: 'calc(50% - 350px)',
      // On top of sync warning
      zIndex: 1500,
      // Might overlap with navigation UI
      opacity: 0.75,
      pointerEvents: 'none'
    },
    alert: {
      padding: '0 16px'
    },
    // Allow interaction with text and button
    clickableText: {
      pointerEvents: 'auto',
      cursor: 'pointer'
    },
    submitButton: {
      pointerEvents: 'auto'
    }
  }))
import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormControlLabel, Switch } from '@material-ui/core'

import { WbStoresForm } from './WbStoresForm'
import { VatEnabledSwitch } from './VatEnabledSwitch'
import { AccountCommissions } from './AccountCommissions'
import { MoySkladSalesReturnForm } from './MoySkladSalesReturnForm'
import { MoySkladDataFormContainer } from './MoySkladDataFormContainer'
import { ConvertNonRubleOrdersSwitch } from './ConvertNonRubleOrdersSwitch'
import { StoreAccountPriceUpdateForm } from './StoreAccountPriceUpdateForm'
import { MoySkladPropertiesSelectorPropsBase } from './MoySkladOzonPropertiesSelector'

import { useApi } from '../hooks/storeHook'

import { required } from '../common/objectUtils'

import { WbAccountProps } from '../types/accountProps'
import { StoreType } from '../server/mpsklad_core/Entity/StoreType'

export type MoySkladWbPropertiesSelectorProps =
  WbAccountProps & MoySkladPropertiesSelectorPropsBase

export const MoySkladWbPropertiesSelector =
  observer(
    ({
       account,
       isLoading,
       isGeneratingPayments,
       documentsCommissionsRecording,
       ordersCommissionsRecording,
       isUsingCommissions,
       msSalesReturnStoreId,
       isUpdatePrices,
       setMsData,
       setUsingCommissions,
       setDocumentsCommissionsRecording,
       setGeneratingPayments,
       setOrdersCommissionsRecording,
       setMsSalesReturn,
       setUpdatePrices
     }: MoySkladWbPropertiesSelectorProps) => {
      const api = useApi()

      return (
        <Box padding={2}>
          <Box marginBottom={3}>
            <MoySkladDataFormContainer msData={null} storeType={StoreType.Wildberries} storeAccount={account}
                                       onChange={setMsData}/>
          </Box>

          {
            account &&
            <>
              <Box marginBottom={3}>
                <WbStoresForm account={account}/>
              </Box>

              <Box marginBottom={3}>
                <MoySkladSalesReturnForm
                  account={account}
                  selectedStore={msSalesReturnStoreId}
                  onChange={setMsSalesReturn}
                  onSubmitAutoReturnEnabled={api.userSync.setWbAutoReturnEnabled}
                  onSubmitAutoRemoveCancelledMsOrderDocs={api.userSync.setWbAutoRemoveCancelledMsOrderDocs}
                />
              </Box>

              <Box marginBottom={3}>
                <AccountCommissions
                  isUsingCommissions={isUsingCommissions}
                  setUsingCommissions={setUsingCommissions}
                  ordersCommissionsRecording={ordersCommissionsRecording}
                  setOrdersCommissionsRecording={setOrdersCommissionsRecording}
                  documentsCommissionsRecording={documentsCommissionsRecording}
                  setDocumentsCommissionsRecording={setDocumentsCommissionsRecording}
                />
              </Box>

              <Box marginBottom={3}>
                <StoreAccountPriceUpdateForm
                  isUpdatePrices={isUpdatePrices}
                  setUpdatePrices={setUpdatePrices}
                  onCheckPrices={api.prices.checkPriceActivationWb}
                  onEnablePriceSync={api.prices.enablePriceSyncWb}
                  onForceUpdatePrices={() => api.userSync.updatePricesWb(required(account).id)}
                  description={`Все цены, имеющие поле "Цена WB без скидки" больше нуля, будут обновлены, если соответствуют условиям.
 Скидки по промокоду не учитываются. Цены должны быть указаны без копеек.`}
                />
              </Box>

              <Box marginBottom={3} marginTop={4}>
                <VatEnabledSwitch account={account} onSubmit={api.userSync.setWbVatEnabled}/>
              </Box>

              <Box marginBottom={3}>
                <ConvertNonRubleOrdersSwitch account={account} onSubmit={api.userSync.setWbConvertNonRubleOrders}/>
              </Box>

              <Box marginBottom={3}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isLoading}
                      checked={isGeneratingPayments}
                      onChange={({target: {checked}}) => setGeneratingPayments(checked)}
                    />
                  }
                  label="Автоматически создавать входящий платёж"
                />
              </Box>
            </>
          }
        </Box>
      )
    })
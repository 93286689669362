import { TableColumn } from './tableTypes'
import { cellStyleBreakNormal } from './myStorageColumns'
import { MyProductColumnGroupId } from './myProductColumnGroups'
import { MyProductColumnId, MyWbProductColumnId, selectColumnId } from './myProductColumnIds'
import { createColumnGroup, createColumns, GroupedMyColumnsParam, MyColumnParam } from './columnGroupCreator'

import { TableDateCell } from '../components/tableCells/TableDateCell'
import { TableSelectCell } from '../components/tableCells/TableSelectCell'
import { ProductImageCell2, UrlImageCell } from '../components/ProductImageCell'
import { MyProductWbSyncCell } from '../components/tableCells/MyProductWbSyncCell'
import { WbProductSyncLogsCell } from '../components/tableCells/StoreProductSyncLogsCell'

import { formatBalance, formatPrice, formatPriceOrEmpty } from '../common/numberUtils'

import { MyProductModel } from '../server/mpsklad_core/Models/MyProductModel'
import { WbProductModel } from '../server/mpsklad_core/Models/WbProductModel'
import { CurrencyISO4217 } from '../server/mpsklad_core/Entity/CurrencyISO4217'
import { WbPrintOrderModel } from '../server/mpsklad_core/Models/WbPrintOrderModel'

export const myWbColumns =
  createColumnGroup<MyProductColumnId, MyProductModel>()(
    MyProductColumnGroupId.Wildberries, [{
      title: 'Синх. Wildberries',
      id: 'wbSyncEnabled',
      sortable: true,
      CellComponent: MyProductWbSyncCell
    }, {
      title: 'Код размера (chrtId)',
      id: 'wbChrtId',
      sortable: true,
      accessor: _ => _.wbProducts.map(p => p.chrtId)
    }, {
      title: 'Цена',
      id: 'wbPrice',
      accessor: _ => _.wbProducts.map(p => formatPriceOrEmpty(p.price))
    }, {
      title: 'Цена без скидки',
      id: 'wbBasePrice',
      accessor: _ => _.wbProducts.map(p => formatPriceOrEmpty(p.basePrice))
    }, {
      title: 'Остаток',
      id: 'wbBalance',
      accessor: _ => _.wbProducts.map(p => formatBalance(p.balance))
    }] satisfies GroupedMyColumnsParam<MyWbProductColumnId, MyProductModel>) // Ensure filtering of ids

export type WbProductColumnId =
  keyof Pick<WbProductModel, 'imageUrl' | 'nmId' | 'chrtId' | 'barcodes' | 'price' | 'basePrice' | 'balance'
    | 'type' | 'brand' | 'name' | 'color' | 'size' | 'supplierArticle' | 'hasMsProduct'>
  | 'logs' | typeof selectColumnId

const wbProductCommonColumns = [{
  id: 'imageUrl',
  title: 'Фото',
  CellComponent: ProductImageCell2
}, {
  id: 'nmId',
  title: 'Код WB'
}, {
  id: 'chrtId',
  title: 'Код размера (chrtId)'
}, {
  id: 'barcodes',
  title: 'Штрихкод',
  cellStyle: cellStyleBreakNormal
}, {
  id: 'price',
  title: 'Цена',
  accessor: _ => formatPriceOrEmpty(_.price)
}, {
  id: 'basePrice',
  title: 'Цена без скидки',
  accessor: _ => formatPriceOrEmpty(_.basePrice)
}, {
  id: 'balance',
  title: 'Остаток',
  accessor: _ => formatBalance(_.balance)
}, {
  id: 'type',
  title: 'Вид товара'
}, {
  id: 'brand',
  title: 'Бренд'
}, {
  id: 'name',
  title: 'Наименование'
}, {
  id: 'color',
  title: 'Цвет'
}, {
  id: 'size',
  title: 'Размер'
}, {
  id: 'supplierArticle',
  title: 'Артикул поставщика'
}, {
  id: 'hasMsProduct',
  title: 'Сматчен с МоимСкладом'
}] satisfies Array<MyColumnParam<WbProductColumnId, WbProductModel>>

export const wbProductColumns =
  createColumns<WbProductColumnId, WbProductModel>(
    ...wbProductCommonColumns, {
      id: 'logs',
      title: 'Логи',
      CellComponent: WbProductSyncLogsCell
    })

export const wbProductImportColumns =
  createColumns<WbProductColumnId, WbProductModel>({
    id: selectColumnId,
    title: 'Выбрать',
    CellComponent: TableSelectCell
  }, ...wbProductCommonColumns)

export type WbPrintOrderColumnId =
  keyof Pick<WbPrintOrderModel, 'wbId' | 'imageUrl' | 'chrtId' | 'price' | 'currency' | 'name'
    | 'color' | 'size' | 'wbRId' | 'createdAt' | 'barcode'>

export type WbPreviewOrderColumn = TableColumn<WbPrintOrderColumnId, WbPrintOrderModel>

export const wbPrintOrderColumns: WbPreviewOrderColumn[] =
  createColumns<WbPrintOrderColumnId, WbPrintOrderModel>({
    id: 'wbId',
    title: '№ задания'
  }, {
    id: 'imageUrl',
    title: 'Фото',
    CellComponent: UrlImageCell
  }, {
    id: 'createdAt',
    title: 'Дата создания',
    CellComponent: TableDateCell
  }, {
    id: 'name',
    title: 'Наименование'
  }, {
    id: 'size',
    title: 'Размер'
  }, {
    id: 'color',
    title: 'Цвет'
  }, {
    id: 'chrtId',
    title: 'Код размера (chrtId)'
  }, {
    id: 'wbRId',
    title: 'Идентификатор вещи'
  }, {
    id: 'barcode',
    title: 'Штрихкод товара'
  }, {
    id: 'price',
    title: 'Цена продажи',
    accessor: _ => formatPrice(_.price)
  }, {
    id: 'currency',
    title: 'Валюта заказа',
    accessor: _ => _.currency ? CurrencyISO4217[_.currency] : ''
  })
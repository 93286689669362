import React from 'react'

import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core'
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel/FormControlLabel'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { CheckCircle } from '@material-ui/icons'
import clsx from 'clsx'
import { useCheckboxStyles } from '../hooks/checkboxStylesHook'

export type PrintMsBarcodesCheckboxProps = {
  checked: boolean

  onChange: (checked: boolean) => void

  labelProps?: FormControlLabelProps

  checkboxProps?: CheckboxProps
}

export const PrintMsBarcodesCheckbox =
  ({checked, onChange, labelProps, checkboxProps}: PrintMsBarcodesCheckboxProps) => {
    const classes = useCheckboxStyles()

    return <FormControlLabel
      className={clsx(classes.checkbox, checked && classes.checkedCheckbox)}
      label="Штрихкод товара в МоёмСкладе (Code 128)"
      control={
        <Checkbox
          color="primary"
          name="msBarcodesCheckbox"
          checked={checked}
          onChange={(_, checked) => onChange(checked)}
          icon={<CircleUnchecked/>}
          checkedIcon={<CheckCircle/>}
          {...checkboxProps}
        />
      }
      {...labelProps}
    />
  }
import { AccountNavigation } from './AccountNavigation'
import { useLogic, useStore } from '../hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { Box, FormHelperText } from '@material-ui/core'
import { AddAccountButton } from './AddAccountButton'
import { required } from '../common/objectUtils'
import { appRoutes } from '../common/appRoutes'
import { Link, useHistory } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { HomeTab } from './HomeTabTitle'
import { exact } from '../common/tsUtils'
import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { makeOzonAccountName, makeWbAccountName, makeYandexMarketAccountName } from '../common/accountUtils'
import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'
import { HeaderTab, headerTabByNavStoreType, SideBarTab } from '../types/navStore'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

export const SideBarNavigation = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const {
    syncStore: {moySkladAccount, ozonAccounts, wbAccounts, yandexMarketAccounts},
    allowedIntegrations
  } = store

  const history = useHistory()

  const selectedStoreType = store.getHomeNav()?.storeType

  const classes = useStyles()

  const getTabs = useCallback(() => {
    const result: HomeTab[] = []

    if (allowedIntegrations[StoreType.Ozon] && selectedStoreType === NavStoreType.Ozon) {
      result.push(
        ...ozonAccounts.map(ozonAccount => exact<HomeTab>({
          ozonAccount,
          title: makeOzonAccountName(ozonAccount),
          storeType: NavStoreType.Ozon
        })))
    }

    if (allowedIntegrations[StoreType.Wildberries] && selectedStoreType === NavStoreType.Wildberries) {
      result.push(
        ...wbAccounts.map(wbAccount => exact<HomeTab>({
          wbAccount,
          title: makeWbAccountName(wbAccount),
          storeType: NavStoreType.Wildberries
        })))
    }

    if (allowedIntegrations[StoreType.YandexMarket] && selectedStoreType === NavStoreType.YandexMarket) {
      result.push(
        ...yandexMarketAccounts.map(yandexMarketAccount => exact<HomeTab>({
          yandexMarketAccount,
          title: makeYandexMarketAccountName(yandexMarketAccount),
          storeType: NavStoreType.YandexMarket
        })))
    }

    return result
  }, [allowedIntegrations, selectedStoreType, ozonAccounts, wbAccounts, yandexMarketAccounts])

  const tabs = getTabs()

  const [tabIndex, setTabIndex] = useState(0)

  const onAddMoySkladAccount = () => {
    store.setHeaderTab(HeaderTab.MoySklad)
  }

  useEffect(() => {
    logic.setNavStateByRoute(history.location.pathname)
  }, [logic, history.location])

  useEffect(() => {
    setTabIndex(0)
  }, [selectedStoreType])

  if (moySkladAccount == null) {
    return <Box className={clsx(classes.hint, 'default-border')}>
      <FormHelperText className={classes.helperText}>
        Для работы с товарами добавьте
        <Link
          to={`${appRoutes.Settings.moySklad}/settings`}
          style={{textDecoration: 'none'}}
          onClick={onAddMoySkladAccount}> аккаунт
          МоегоСклада</Link>.
      </FormHelperText>
    </Box>
  }

  if (!store.getHomeNav()
      || (ozonAccounts.length === 0 && wbAccounts.length === 0 && yandexMarketAccounts.length === 0)) {
    return <Box padding={3} minWidth={200}>
      <AddAccountButton withHelpText/>
    </Box>
  }

  const onTabChange =
    (newTabIndex: number) => {
      // TODO: (redesign) Why are change handlers so indirect and nested?
      const newTab = tabs[newTabIndex]

      const newAccount = required(newTab.ozonAccount ?? newTab.wbAccount ?? newTab.yandexMarketAccount)
      store.setHomeNav(
        newAccount.storeType,
        newAccount.id,
        headerTabByNavStoreType[newAccount.storeType],
        SideBarTab.Products)

      history.push(appRoutes.Home.base)

      setTabIndex(newTabIndex)
    }

  return (
    <div className="sidebar_container">
      <Box className="sticky_sidebar" style={{minWidth: 200}}>
        {tabs.map(((_, index) =>
          <AccountNavigation
            key={index}
            isSelected={index === tabIndex}
            account={required(_.ozonAccount ?? _.wbAccount ?? _.yandexMarketAccount)}
            hasMoySkladApp={store.hasMoySkladApp}
            accountIndex={index}
            onAccountChange={onTabChange}
          />))}

        <AddAccountButton/>
      </Box>
    </div>
  )
})

const useStyles = makeStyles(
  () => ({
    hint: {
      margin: 20,
      padding: 20,
      maxWidth: 160,
      maxHeight: 60,
      backgroundColor: '#FBFBFD'
    },
    helperText: {
      fontFamily: 'Roboto Regular',
      fontSize: 11,
      fontWeight: 400,
      marginTop: 5
    }
  })
)
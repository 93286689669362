import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid } from '@material-ui/core'
import { MoySkladProductsTable } from '../../MoySkladProductsTable'

export const MoySkladProductsTableScreen =
  observer(
    () => {

      return (
        <Box padding={3} width={'calc(100vw - 305px)'}>
          <Grid lg={12}>
            <MoySkladProductsTable/>
          </Grid>
        </Box>
      )
    })
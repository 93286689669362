import React, { useEffect, useMemo, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { Box, CircularProgress } from '@material-ui/core'

import { RoutingTabs } from '../../RoutingTabs'
import { RouteTabItem } from '../../RouteTabItem'
import { PricesSettings } from '../PricesSettings'
import { OzonApiSettings } from './OzonApiSettings'
import { CompanySettings } from '../CompanySettings'
import { OzonStoresForm } from '../../OzonStoresForm'
import { OzonOrdersSettings } from './OzonOrdersSettings'
import { OzonReturnsSettings } from './OzonReturnsSettings'
import { StoreAccountMsData } from '../../MoySkladDataForm'
import { CommissionsSettings } from '../CommissionsSettings'
import { AdditionalPropertiesSettings } from '../AdditionalPropertiesSettings'

import { settingsRoutes } from '../settingsRoutes'

import { useLazyEffect } from '../../../hooks/commonHooks'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'

import { exact } from '../../../common/tsUtils'
import { appRoutes } from '../../../common/appRoutes'
import { RouteItem } from '../../../common/routeItem'
import { required } from '../../../common/objectUtils'
import { useLocalObservableOptional } from '../../../common/mobxUtils'

import { StoreType } from '../../../server/mpsklad_core/Entity/StoreType'
import { EditOzonAccountModel } from '../../../server/mpsklad_core/Models/EditOzonAccountModel'
import { MoySkladAttributeModel } from '../../../server/mpsklad_core/Models/MoySkladAttributeModel'
import { MoySkladApiEntityOption } from '../../../server/mpsklad_core/Models/MoySkladApiEntityOption'
import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditStoreAccountMsDataModel } from '../../../server/mpsklad_core/Models/EditStoreAccountMsDataModel'

export const OzonSettingsPage = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const api = useApi()
  const history = useHistory()
  const {showSuccess, showError} = useMessages()

  const accountId = store.getHomeNav()?.accountId
  const account = logic.tryGetOzonAccount(accountId)

  const msContainer = useLocalObservableOptional<StoreAccountMsData>()

  const [msStores, setMsStores] = useState<MoySkladApiEntityOption[]>([])

  const [msAttributes, setMsAttributes] = useState<MoySkladAttributeModel[] | null>(null)

  const availableTabs: RouteItem[] = useMemo(() => {
    return accountId ? settingsRoutes : settingsRoutes.map((route: RouteItem, index) => ({
      ...route,
      isDisabled: index !== 0
    }))
  }, [accountId])

  const routes = useMemo((): RouteTabItem[] => {
    return (availableTabs)
      .map((_) => ({title: _.title, route: _.route, isDisabled: _.isDisabled}))
  }, [availableTabs])

  useEffect(() => {
    if (history.location.pathname === appRoutes.Settings.ozon) {
      history.push(`${appRoutes.Settings.ozon}/${routes[0].route}`)
    }
  })

  const loadMsStores = async () => {

    try {
      const msStores = await api.userSync.getMoySkladStores()

      setMsStores(msStores)
    } catch (e) {
      console.error('Failed to load', e)
    }
  }

  const loadMoySkladData = async () => {
    try {
      const {organizations, counterparties, contracts, salesChannels, projects} =
        await api.userSync.getMoySkladData(StoreType.Ozon, account?.id)

      if (organizations.length === 0) {
        showError('Нет организаций в МоёмСкладе')
        return
      }

      if (counterparties.length === 0) {
        showError('Нет контрагентов в МоёмСкладе с типом "Юридическое лицо"')
        return
      }

      msContainer.dataOrNull = {
        organization: {
          options: organizations,
          value: organizations.find(_ => _.id === account?.msOrganization) ?? organizations[0],
          searchTerm: '',
          isSearchLoading: false
        },
        counterparty: {
          options: counterparties,
          value: counterparties.find(_ => _.id === account?.msCounterparty) ?? counterparties[0],
          searchTerm: '',
          isSearchLoading: false
        },
        contract: {
          options: contracts,
          value: contracts.find(_ => _.id === account?.msContractId) ?? null,
          searchTerm: '',
          isSearchLoading: false
        },
        salesChannel: {
          options: salesChannels,
          value: salesChannels.find(_ => _.id === account?.msSalesChannelId) ?? null,
          searchTerm: '',
          isSearchLoading: false
        },
        project: {
          options: projects,
          value: projects.find(_ => _.id === account?.msProjectId) ?? null,
          searchTerm: '',
          isSearchLoading: false
        },
        get formData() {
          return {
            msOrganization: this.organization.value.id,
            msCounterparty: this.counterparty.value.id,
            msContractId: this.contract.value?.id,
            msSalesChannelId: this.salesChannel.value?.id,
            msProjectId: this.project.value?.id
          }
        }
      }
    } catch (e) {
      console.error('Failed to get MoySklad data', e)
      showError('Не удалось загрузить данные из МоегоСклада')
    }
  }

  const onSubmit =
    async (data: EditStoreAccountMsDataModel) => {

      try {
        const formData =
          exact<Omit<EditOzonAccountModel, 'id'>>({
            isFBS: account?.isFBS ?? false,
            isUpdatePrices: account?.isUpdatePrices ?? false,
            isGeneratingPayments: account?.isGeneratingPayments ?? false,
            isUsingCommissions: account?.isUsingCommissions ?? false,
            ordersCommissionsRecording: account?.ordersCommissionsRecording
                                        ?? CommissionsRecordingType.Nothing,
            documentsCommissionsRecording: account?.documentsCommissionsRecording
                                           ?? CommissionsRecordingType.Nothing,
            clientId: account?.clientId ?? 0,
            apiKey: account?.apiKey ?? '',
            name: account?.name,
            msSalesReturnStoreId: account?.msSalesReturnStoreId,
            msData: data
          })

        if (account) {
          await logic.editOzonAccount({id: account.id, ...formData})
        } else {
          await logic.createOzonAccount({id: 0, ...formData})
        }

        showSuccess('Сохранено!')
      } catch (e) {
        console.error('Failed to save Ozon account', e)
      } finally {
      }
    }

  const loadMsAttributes = async () => {
    try {
      const msAttr = await api.userSync.getMoySkladAttributes()
      setMsAttributes(msAttr)
    } catch (e) {
      console.error('Failed to get MoySklad attrs', e)
      showError('Не удалось загрузить')
    }
  }

  useLazyEffect(() => {
    setTimeout(loadMoySkladData)
    setTimeout(loadMsAttributes)
    setTimeout(loadMsStores)
  }, [accountId])

  if (!msContainer.dataOrNull) {
    return <Box
      width="calc(100vw - 240px)" height="100vh" display="flex" alignItems="center"
      justifyContent={'center'}>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <CircularProgress/>
        <p>Загружаем данные из Моего Склада, подождите!</p>
      </Box>
    </Box>
  }

  return <>
    <RoutingTabs
      routeTabs={routes}
      baseRoute={appRoutes.Settings.ozon}
      subRoute={logic.getSubRoute(store.getHomeNav()?.storeType)}>
      <Switch>
        <Route
          path={`${appRoutes.Settings.ozon}/api`}
          render={() => <OzonApiSettings/>}/>

        {account &&
         <>
           <Route
             path={`${appRoutes.Settings.ozon}/company`}
             render={() =>
               <CompanySettings
                 msData={msContainer.data}
                 onChange={(a) => onSubmit(a)}/>}
           />
           <Route
             path={`${appRoutes.Settings.ozon}/orders`}
             render={() =>
               <OzonOrdersSettings account={account}/>}
           />

           <Route
             path={`${appRoutes.Settings.ozon}/warehouses`}
             render={() => <OzonStoresForm account={account}/>}
           />

           <Route
             path={`${appRoutes.Settings.ozon}/returns`}
             render={() =>
               <OzonReturnsSettings msStores={msStores}/>}
           />
           <Route
             path={`${appRoutes.Settings.ozon}/commissions`}
             render={() =>
               <CommissionsSettings account={account}/>
             }
           />
           <Route
             path={`${appRoutes.Settings.ozon}/prices`}
             render={() =>
               <PricesSettings
                 account={account}
                 onCheckPrices={api.prices.checkPriceActivationOzon}
                 onEnablePriceSync={api.prices.enablePriceSyncOzon}
                 onForceUpdatePrices={() => api.userSync.updatePricesOzon(required(account).id)}
               />}
           />
           <Route
             path={`${appRoutes.Settings.ozon}/additional`}
             render={() =>
               <AdditionalPropertiesSettings
                 msAttributes={required(msAttributes)}
                 onCreate={(createdAttr: MoySkladAttributeModel) => setMsAttributes(
                   [...required(msAttributes), createdAttr])}/>}/>
         </>
        }
      </Switch>
    </RoutingTabs>
  </>
})
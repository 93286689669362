import React, { FormEvent, useState } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box, FormLabel, Grid, TextField } from '@material-ui/core'

import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

export const MoySkladAccountForm =
  observer(
    () => {
      const {showSuccess} = useMessages()

      const {syncStore} = useStore()
      const {moySkladAccount} = syncStore

      const logic = useLogic()
      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const [accessToken, setAccessToken] = useState(moySkladAccount?.accessToken ?? '')

      const classes = useStyles()

      useLazyEffect(
        () => reaction(
          // NOTE: Access through the syncStore to trigger the reaction
          () => syncStore.moySkladAccount?.accessToken,
          accessToken => setAccessToken(accessToken ?? '')
        ))

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()
          setIsLoading()

          try {
            await logic.editMoySkladAccount({accessToken})
            showSuccess('Сохранено!')
          } catch (e) {
            console.error('Failed to save MoySklad account', e)
          } finally {
            setNotLoading()
          }
        }

      return (
        <Box className={classes.pageContainer}>
          <Grid item xs={12}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    API токен
                  </FormLabel>
                </Box>

                <Box>

                  <TextField
                    required
                    id="accessToken"
                    placeholder="be0813e9"
                    variant="outlined"
                    size="small"
                    value={accessToken}
                    onChange={e => setAccessToken(e.target.value)}
                    fullWidth
                    InputProps={{
                      className: classes.input
                    }}
                  />

                </Box>

                <button
                  className={clsx(classes.saveButton, 'default-button')}
                  disabled={isLoading}
                  onClick={onSubmit}>
                  <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0px 40px 40px 40px'
    },
    root: {
      padding: '13px 13px 13px 25px'
    },
    settingsTopic: {
      borderRadius: 12,
      paddingTop: 10
    },
    helperText: {
      fontSize: 11,
      lineHeight: '12px',
      marginTop: 5
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    label: {
      fontSize: 12,
      fontWeight: 600,
      color: '#1F364D',
      paddingBottom: 15
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left'
    }
  })
)
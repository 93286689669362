import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, FormLabel, Grid, MenuItem, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { useMessages } from '../../hooks/snackbarHooks'
import { useLogic } from '../../hooks/storeHook'
import { useBoolState, useToggleState } from '../../hooks/commonHooks'

import { required } from '../../common/objectUtils'
import { fixMsAppScrollProps } from '../../common/msAppUtils'
import { valuesOfNumericEnum } from '../../common/tsUtils'
import { commissionsRecordingTypeNames } from '../../types/commissionsRecordingTypeNames'

import { StoreAccountModelBase } from '../../server/mpsklad_core/Models/StoreAccountModelBase'
import { CommissionsRecordingType } from '../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'

import { BootstrapInput } from '../MoySkladAttributeSelect'
import { IOSSwitch } from '../IOSSwitch'
import { useDefaultMenuItemStyles } from '../../hooks/defaultSelectStylesHook'

export type CommissionsSettingsProps = {
  account: StoreAccountModelBase
}
export const CommissionsSettings =
  observer(({account}: CommissionsSettingsProps) => {
    const {showSuccess, showError} = useMessages()

    const logic = useLogic()

    const accountId = required(account.id)

    const [isLoading, setIsLoading, setNotLoading] = useBoolState()

    const [isUsingCommissions, toggleIsUsingCommissions] = useToggleState(account?.isUsingCommissions ?? false)

    const [ordersCommissionsRecording, setOrdersCommissionsRecording] =
      useState(account.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing)

    const [documentsCommissionsRecording, setDocumentsCommissionsRecording] =
      useState(account.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing)

    const classes = useStyles()
    const menuClasses = useDefaultMenuItemStyles()

    const onSubmit = async () => {
      try {
        setIsLoading()

        await logic.setCommissionsSettings({
          accountId,
          isUsingCommissions,
          ordersCommissionsRecordingType: ordersCommissionsRecording,
          documentsCommissionsRecording: documentsCommissionsRecording,
          storeType: account.storeType
        })

        showSuccess('Настройки коммиссий обновлены!')
      } catch (e) {
        showError('Произошла ошибка при настройке коммиссий!')
      } finally {
        setNotLoading()
      }
    }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>НАСТРОЙКИ КОМИССИЙ</Typography>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>


                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Автоматический учёт коммисии
                  </FormLabel>
                  <IOSSwitch checked={isUsingCommissions} onChange={toggleIsUsingCommissions}/>
                </Box>

                <FormHelperText className={classes.helperText}>
                  Обновляется вместе с заказами
                </FormHelperText>

              </Grid>
            </Grid>
          </Grid>
          {isUsingCommissions && <>
            <Grid item xs={7}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>

                  <Box>
                    <FormLabel component="legend" className={classes.label}>
                      <span className={classes.dash}>—</span>
                      Учитывать в документах
                    </FormLabel>
                  </Box>

                  <Select
                    value={documentsCommissionsRecording}
                    onChange={({target: {value}}) => setDocumentsCommissionsRecording(
                      value as CommissionsRecordingType)}
                    MenuProps={fixMsAppScrollProps}
                    fullWidth
                    input={<BootstrapInput/>}
                  >
                    {
                      valuesOfNumericEnum(CommissionsRecordingType)
                        .map(value =>
                          <MenuItem
                            className={menuClasses.menuItem}
                            key={value}
                            value={value}
                          >
                            {commissionsRecordingTypeNames[value]}
                          </MenuItem>)
                    }

                  </Select>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>

                  <Box>
                    <FormLabel component="legend" className={classes.label}>
                      <span className={classes.dash}>—</span>
                      Учитывать в заказах
                    </FormLabel>
                  </Box>

                  <Select
                    value={ordersCommissionsRecording}
                    onChange={({target: {value}}) => setOrdersCommissionsRecording(
                      value as CommissionsRecordingType)}
                    MenuProps={fixMsAppScrollProps}
                    fullWidth
                    input={<BootstrapInput/>}
                  >
                    {
                      [CommissionsRecordingType.Nothing, CommissionsRecordingType.Commission]
                        .map(value =>
                          <MenuItem
                            className={menuClasses.menuItem}
                            key={value}
                            value={value}
                          >
                            {commissionsRecordingTypeNames[value]}
                          </MenuItem>)
                    }

                  </Select>

                </Grid>
              </Grid>
            </Grid>
          </>
          }
        </Grid>
        <Box display="flex">

          <button className={clsx(classes.cancelButton, 'default-button')} disabled={isLoading}>
            <p>Отменить</p>
          </button>

          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}>
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>

        </Box>
      </Box>
    )
  })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    root: {
      padding: 15,
      marginLeft: 20,
      marginRight: 20
    },
    settingsTopic: {
      borderRadius: 12,
      paddingBottom: 10
    },
    label: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    helperText: {
      fontFamily: 'Roboto Regular',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left'
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    unifiedPricesLabel: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      color: '#1F364D'
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      margin: '10px 10px 30px 10px'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    }
  })
)
import { MsProductType } from '../../server/mpsklad_core/Entity/MsProductType'
import { WbProductModel } from '../../server/mpsklad_core/Models/WbProductModel'
import { OzonProductModel } from '../../server/mpsklad_core/Models/OzonProductModel'
import { YandexMarketProductModel } from '../../server/mpsklad_core/Models/YandexMarketProductModel'

export const makeOzonProductLabel =
  (ozonProduct: OzonProductModel | null | undefined): string =>
    ozonProduct
    ? makeLabel(ozonProduct.article, ozonProduct.name, ...ozonProduct.barcodes)
    : ''

export const makeWbProductLabel =
  (wbProduct: WbProductModel | null | undefined): string =>
    wbProduct
    ? makeLabel(
      wbProduct.chrtId.toString(),
      ...wbProduct.barcodes,
      wbProduct.type,
      wbProduct.brand,
      wbProduct.supplierArticle,
      wbProduct.size)
    : ''

export const makeYandexMarketProductLabel =
  (yandexMarketProduct: YandexMarketProductModel | null | undefined): string =>
    yandexMarketProduct
    ? makeLabel(yandexMarketProduct.shopSku, yandexMarketProduct.vendorCode, ...yandexMarketProduct.barcodes)
    : ''

const makeLabel =
  (...components: Array<string | undefined>) =>
    components.filter(_ => _).join(' - ')

export const msProductTypeNames: {
  [type in MsProductType]: string
} = {
  [MsProductType.Product]: 'Товар',
  [MsProductType.Variant]: 'Модификация',
  [MsProductType.Bundle]: 'Комплект'
}
import React, { ReactElement, ReactNode, useCallback } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import clsx from 'clsx'

import { AppBar, Tab, Tabs } from '@material-ui/core'

import { useStyles } from './AutoTabs'
import { tabA11yProps } from './TabPanel'

import { required } from '../common/objectUtils'
import { AppRoutePath } from '../common/appRoutes'

import { AllowedIntegrationsMap } from '../types/integrationTypeUtils'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'

export type RouteTabItem = {
  title: ReactNode

  route: AppRoutePath
}

export type RouteTabsProps = {
  /**
   * Must not be empty.
   */
  routes: RouteTabItem[]

  /**
   * Must be a single Switch component.
   */
  children: ReactElement
}

export const RouteTabs =
  ({routes, children}: RouteTabsProps) => {
    const classes = useStyles()

    const history = useHistory()

    const activeTabIndex = Math.max(0, routes.findIndex(_ => _.route === history.location.pathname))

    const onTabsChange = useCallback(
      (event: unknown, newTabIndex: number) =>
        history.push(required(routes[newTabIndex]).route),
      [history, routes])

    // TODO: Map and filter allowedRoutes within this component, get rid of children
    if (!children || children.type !== Switch) {
      throw new Error('The children prop must have a single element that is a Switch component')
    }

    if (!routes.length) {
      throw new Error('The routes must not be empty')
    }

    // TODO: Alternative indicator for active tab to fix multi-row bugs or is Tabs[scrollable] good enough?
    return <>
      <AppBar position="static" className={clsx(classes.appBar, classes.appBarPrimary)}>
        <Tabs variant="scrollable" value={activeTabIndex} onChange={onTabsChange}>
          {
            routes.map(
              ({title, route}, index) =>
                <Tab key={index} label={title} {...tabA11yProps(index)}/>)
          }
        </Tabs>
      </AppBar>

      {children}
    </>
  }

export const createAllowedTabs =
  (allowedIntegrations: AllowedIntegrationsMap,
   tabs: Array<RouteTabItem & {type: StoreType}>): RouteTabItem[] =>
    // TODO: useMemo?
    tabs.filter(_ => allowedIntegrations[_.type])
        .map(({route, title}) => ({route, title}))
import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { Box } from '@material-ui/core'

import { EditProductsButton } from './EditProductsButton'
import { ExportProductsButton } from './ExportProductsButton'
import { MyProductsPropFilter } from './MyProductsPropFilter'
import { MyProductsColumnsMenu } from './MyProductsColumnsMenu'
import { MatchProductsDropdownButton } from './MatchProductsDropdownButton'

import { useStore } from '../hooks/storeHook'
import { useBoolState, useLazyUnEffect } from '../hooks/commonHooks'

import search from '../assets/search.svg'
import filter from '../assets/filter.svg'
import column from '../assets/column.svg'

export const MyProductsFilter =
  observer(() => {
    const {
      productStore: {myProducts: {selectedRowCount, setGlobalFilter}}
    } = useStore()

    const [isFilterOpen, setFilterOpen, setFilterClosed] = useBoolState()

    const [columnSettingsAnchor, setColumnSettingsAnchor] = useState<HTMLButtonElement | null>(null)

    useLazyUnEffect(() => setGlobalFilter(null))

    return <>
      <Box display="flex" alignItems="center" padding={2}>

        <div className="main_input_container">
          <input
            className="main_input"
            type="text"
            placeholder="Название, код или артикул..."
            onChange={e => setGlobalFilter(e.target.value)}
          />
          <img className="search-icon" src={search} alt="Поиск"/>
        </div>

        <button
          onClick={isFilterOpen ? setFilterClosed : setFilterOpen}
          className={clsx('btn', isFilterOpen && 'btn_isactive')}
        >
          <p>Фильтр</p>
          <img src={filter} alt={'Фильтр'}/>
        </button>

        <button
          onClick={e => setColumnSettingsAnchor(e.currentTarget)}
          className={clsx(
            'btn'
          )}
        >
          <p>Столбцы</p>
          <img src={column} alt={'Столбцы'}/>
        </button>

        {
          selectedRowCount > 0 &&
          <>
            <button
              disabled
              type="button"
              className="button_counter"
            >
              Выбрано: {selectedRowCount}
            </button>

            <EditProductsButton/>
          </>
        }

        <ExportProductsButton/>

        <MatchProductsDropdownButton/>
      </Box>

      {
        isFilterOpen &&
        <MyProductsPropFilter/>
      }

      <MyProductsColumnsMenu anchorEl={columnSettingsAnchor} onClose={() => setColumnSettingsAnchor(null)}/>
    </>
  })
import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import { CustomRadioGroupProps, CustomRadioOption, DefaultRadioGroup } from './DefaultRadioGroup'

import { StoreRelationType } from '../server/mpsklad_core/Entity/Base/StoreRelationType'

export type StoreRelationTypeRadioProps =
  Pick<
    CustomRadioGroupProps<StoreRelationType>,
    'disabled' | 'value' | 'onChange'>

const storeRelationTypeOptions: CustomRadioOption<StoreRelationType>[] = [{
  value: StoreRelationType.Direct,
  label: 'Базовое сопоставление',
  hint: 'Сопоставляем склады один к одному.'
}, {
  value: StoreRelationType.SumFromMoySklad,
  label: 'Суммирование',
  hint: 'Остатки суммируются с нескольких складов МоегоСклада на один склад маркетплейса.'
}, {
  value: StoreRelationType.SplitFromMoySklad,
  label: 'Разделение',
  hint: 'Остатки разделяются по нескольким складам маркетплейса. Разделение устанавливается в процентах.'
}]

export const StoreRelationTypeRadio =
  observer(
    (props: StoreRelationTypeRadioProps) => {
      const classes = useStyles()

      return (
        <DefaultRadioGroup
          options={storeRelationTypeOptions}
          optionRadioClassName={classes.relationRadio}
          optionClassName={classes.relationOption}
          optionLabelClassName={clsx(classes.subHeader, classes.relationLabel)}
          {...props}
        />
      )
    })

const useStyles = makeStyles(
  ({spacing}) => ({
    subHeader: {
      fontFamily: 'Roboto Regular',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '16.8px',
      textTransform: 'uppercase'
    },
    relationRadio: {
      marginTop: -22
    },
    relationOption: {
      marginBottom: spacing(3),
      '& span': {
        color: '#1F364D',
        fontFamily: 'Roboto Regular',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
        textAlign: 'left'
      },
      '& .Mui-disabled span': {
        color: '#888888 !important'
      },
      '& span:not(.Mui-disabled)': {
        color: '#3987CF'
      }
    },
    relationLabel: {
      color: '#1F364D !important'
    }
  }))
import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'

import { PhoneNumberInput } from './PhoneNumberInput'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useStore } from '../hooks/storeHook'

import { required } from '../common/objectUtils'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircle } from '@material-ui/icons'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'

export type MoySkladNamePhoneFormPanelProps = {
  onFinish: VoidFunction
}

export const MoySkladNamePhoneFormPanel =
  observer(
    ({onFinish}: MoySkladNamePhoneFormPanelProps) => {
      const {showWarning, showSuccess} = useMessages()

      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const user = required(useStore().user)
      const [name, setName] = useState(user.name)
      const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)
      const [isOfferAccepted, setIsOfferAccepted] = useState(false)

      const api = useApi()

      const classes = useStyles()

      const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if (!name || !phoneNumber) {
          showWarning('Поля не могут быть пустыми.')
          return
        }

        if (!isOfferAccepted) {
          showWarning('Вы должны согласиться с условиями оферты.')
          return
        }

        setIsLoading()

        try {
          await api.auth.editUserInfo({name, phoneNumber})

          user.name = name
          user.phoneNumber = phoneNumber

          showSuccess('Сохранено!')
          onFinish()
        } catch (e) {
          console.error('Failed to save personal data form', e)
        } finally {
          setNotLoading()
        }
      }

      return (
        <Box className={classes.root}>

          <Box className={clsx(classes.helpBox, 'default-border')}>
            <FormLabel className={classes.helpBoxLabel}>
              Зачем это нужно:
            </FormLabel>
            <br/>
            <br/>
            <FormHelperText>
              Мы отправим инструкцию и будем информировать вас о новых обновлениях сервиса.
            </FormHelperText>
          </Box>

          <form onSubmit={onSubmit}>
            <Grid container className={clsx('default-border', classes.credentialsForm)} alignContent="center">
              <Grid item xs={11}>
                <Box padding={3} width="100%">
                  <Box marginBottom={3}>
                    <Typography className={classes.formLabel}>
                      Ввод личных данных
                    </Typography>
                  </Box>

                  <Grid container direction="column" spacing={3}>

                    <Grid item xs={12}>

                      <Box>
                        <FormLabel component="legend" className={classes.label}>
                          <span className={classes.dash}>—</span>Имя
                          <span className={classes.star}>*</span>
                        </FormLabel>
                      </Box>

                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="name"
                        autoComplete="fname"
                        InputLabelProps={{shrink: true}}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        InputProps={{
                          className: classes.input
                        }}
                      />

                    </Grid>
                  </Grid>

                  <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>

                      <Box>
                        <FormLabel component="legend" className={classes.label}>
                          <span className={classes.dash}>—</span>Телефон
                          <span className={classes.star}>*</span>
                        </FormLabel>
                      </Box>

                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="phone"
                        name="phone"
                        autoComplete="tel"
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                          inputComponent: PhoneNumberInput,
                          inputProps: {onAccept: setPhoneNumber},
                          className: classes.input
                        }}
                      />

                      <FormHelperText className={classes.helperText}>
                        Поможем с настройкой, отправим инструкцию
                      </FormHelperText>

                    </Grid>
                  </Grid>

                  <Box marginTop={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isOfferAccepted}
                          onChange={(e) => setIsOfferAccepted(e.target.checked)}
                          name="offerAccepted"
                          checkedIcon={<CheckCircle/>}
                          icon={<CircleUnchecked/>}
                        />
                      }
                      label={
                        <Typography className={classes.offerText}>
                          Используя сервис, вы соглашаетесь с условиями договора
                          оферты
                        </Typography>
                      }
                    />
                  </Box>

                </Box>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent={'center'}>
              <button className={clsx(classes.cancelButton, 'default-button')} disabled={isLoading} onClick={onFinish}>
                <p>Пропустить</p>
              </button>

              <button
                className={clsx(classes.saveButton, 'default-button')}
                disabled={isLoading}
                onClick={onSubmit}>
                {isLoading ? 'Сохраняем...' : 'Далее'}
              </button>

            </Box>
          </form>

        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    root: {
      padding: 15,
      position: 'relative'
    },
    helpBox: {
      padding: 20,
      minWidth: 300,
      position: 'absolute',
      left: 'calc(100% + 50px)',
      backgroundColor: '#FBFBFD',
      height: 'fit-content'
    },
    helpBoxLabel: {
      marginBottom: 20,
      fontWeight: 'bold',
      fontSize: 14,
      color: '#F19F94'
    },
    credentialsForm: {
      borderRadius: 12,
      paddingTop: 10,
      paddingBottom: 10
    },
    label: {
      fontSize: 12,
      marginBottom: 15
    },
    helperText: {
      fontSize: 11,
      fontWeight: 400,
      marginTop: 5
    },
    input: {
      fontSize: 12,
      fontWeight: 400
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    star: {
      color: '#3987CF',
      marginLeft: 2
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    },
    offerText: {
      fontSize: 12,
      fontWeight: 700,
      marginTop: 5
    },
    formLabel: {
      fontSize: 20,
      textTransform: 'uppercase'
    }
  })
)
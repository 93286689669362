import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormControlLabel } from '@material-ui/core'

import { PropFilter } from './PropFilter'
import { OzonProductsTableProps } from './OzonProductsTable'

import { emDash } from '../common/stringUtility'

export type OzonProductsArchivedFilterProps =
  Pick<OzonProductsTableProps, 'products'>

export const OzonProductsArchivedFilter =
  observer(
    ({products}: OzonProductsArchivedFilterProps) =>
      <FormControlLabel
        labelPlacement="start"
        label={<span style={{fontSize: '12px'}}>В архиве:</span>}
        control={
          <Box marginLeft={1}>
            <PropFilter
              filter={{
                isBoolean: true,
                placeholder: emDash,
                onText: 'да',
                offText: 'нет',
                value: products.findPropFilterValue('isArchived'),
                setValue: _ => products.setPropFilter('isArchived', _)
              }}
            />
          </Box>}
      />)
import React from 'react'
import { observer } from 'mobx-react-lite'

import { Add } from '@material-ui/icons'
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  MenuItem, Select,
  styled
} from '@material-ui/core'

import { MoySkladAttributeModel } from '../server/mpsklad_core/Models/MoySkladAttributeModel'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'
import { fixMsAppScrollProps } from '../common/msAppUtils'

export type MoySkladAttributeSelectProps = {
  value: string | undefined

  setValue: (value: string | undefined) => void

  options: MoySkladAttributeModel[]

  isCreating: boolean

  onCreate: () => Promise<unknown>
}

export const MoySkladAttributeSelect =
  observer(
    ({value, setValue, options, isCreating, onCreate}: MoySkladAttributeSelectProps) => {
      const classes = useDefaultMenuItemStyles()

      return (
        <Box>
          <Select
            value={value}
            onChange={e => setValue(e.target.value as string)}
            style={{minWidth: 'calc(100% - 70px)'}}
            MenuProps={fixMsAppScrollProps}
            input={<BootstrapInput/>}
          >
            <MenuItem className={classes.menuItem}>&mdash;</MenuItem>

            {
              options.map(_ =>
                <MenuItem
                  className={classes.menuItem}
                  key={_.id}
                  value={_.id}
                >
                  {_.name}
                </MenuItem>)
            }
          </Select>

          <IconButton
            title="Создать новое поле"
            disabled={isCreating}
            onClick={onCreate}
            className={classes.iconButtonOutlined}
            style={{marginLeft: 10}}
          >
            {
              isCreating
              ? <CircularProgress size={20}/>
              : <Add/>
            }
          </IconButton>
        </Box>
      )
    })

export const BootstrapInput = styled(InputBase)(({theme}) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'Roboto Regular',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))
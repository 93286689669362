import React, { FunctionComponent, useState } from 'react'

import { SyncLogsDialog } from '../components/SyncLogsDialog'

import { useApi } from './storeHook'
import { useBoolState } from './commonHooks'
import { useMessages } from './snackbarHooks'

import { IntegrationTypeKey, integrationTypeByKey } from '../types/integrationTypeUtils'

import { SyncLogModel } from '../server/mpsklad_core/Models/SyncLogModel'

export type SyncErrorHookData = {
  isLoading: boolean

  error: SyncLogModel | null

  onErrorClick: VoidFunction

  resetError: VoidFunction

  SyncErrorDialog: FunctionComponent
}

export const useSyncError =
  (integrationType: IntegrationTypeKey, errorId: string | undefined): SyncErrorHookData => {
    const api = useApi()

    const {showWarning, showError} = useMessages()

    const [error, setError] = useState<SyncLogModel | null>(null)
    const [isLoading, setLoading, setLoaded] = useBoolState()

    const onErrorClick =
      async () => {
        if (!errorId) {
          showWarning('Ошибка не была записана')
          return
        }

        setLoading()

        try {
          const error = await api.userSync.syncLog(integrationTypeByKey[integrationType], errorId)
          setError(error)
        } catch (e) {
          console.error(`Failed to load error #${errorId}`, e)
          showError('Не удалось загрузить ошибку')
        } finally {
          setLoaded()
        }
      }

    const resetError = () => setError(null)

    return {
      isLoading, error, onErrorClick, resetError,
      SyncErrorDialog: () =>
        <SyncLogsDialog isLoading={isLoading} logs={error ? [error] : null} reset={resetError}/>
    }
  }
import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, FormHelperText, FormLabel, Grid } from '@material-ui/core'

import { SyncStartTableRow } from './SyncStartTableRow'
import { MoySkladSyncSwitch } from './MoySkladSyncSwitch'
import { WbMultiProductSwitch } from './WbMultiProductSwitch'
import { OzonMultiProductSwitch } from './OzonMultiProductSwitch'
import { MoySkladUseNumerationRow } from './MoySkladUseNumerationRow'
import { YandexMarketMultiProductSwitch } from './YandexMarketMultiProductSwitch'

import { useApi, useStore } from '../hooks/storeHook'

export const MoySkladSyncInfo =
  observer(
    () => {
      const {syncStore: {moySkladAccount}} = useStore()
      const {userSync: {setMoySkladSyncStartDate}} = useApi()

      const classes = useStyles()

      if (!moySkladAccount) {
        return (
          <Box padding={3}>
            Подключите аккаунт МоегоСклада для синхронизации товаров.
          </Box>
        )
      }

      return (
        <Box className={classes.pageContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>
                  <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel className={classes.unifiedPricesLabel}>
                        Синхронизировать
                      </FormLabel>
                    </Box>

                    <MoySkladSyncSwitch/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>
                  <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel className={classes.unifiedPricesLabel}>
                        Дата начала синхронизации заказов
                      </FormLabel>

                      <FormHelperText className={classes.helperText}>
                        Будут синхронизированы заказы, созданные позже этой даты.
                      </FormHelperText>
                    </Box>
                  </Box>

                  <Box className={classes.syncStart}>
                    <SyncStartTableRow account={moySkladAccount} onSubmit={setMoySkladSyncStartDate}/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>
                  <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel className={classes.unifiedPricesLabel}>
                        Использовать нумерацию МоегоСклада
                      </FormLabel>

                      <FormHelperText className={classes.helperText}>
                        Использовать номера заказов и отгрузок МоегоСклада вместо номеров Маркетплейса.
                      </FormHelperText>
                    </Box>

                    <MoySkladUseNumerationRow/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>
                  <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel className={classes.unifiedPricesLabel}>
                        Разрешить привязку нескольких товаров Ozon
                      </FormLabel>
                    </Box>

                    <OzonMultiProductSwitch/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>
                  <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel className={classes.unifiedPricesLabel}>
                        Разрешить привязку нескольких товаров Wildberries
                      </FormLabel>
                    </Box>

                    <WbMultiProductSwitch/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>
                  <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel className={classes.unifiedPricesLabel}>
                        Разрешить привязку нескольких товаров Yandex.Market
                      </FormLabel>
                    </Box>

                    <YandexMarketMultiProductSwitch/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 0px 40px'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12,
      paddingBottom: 10,
      paddingTop: 5,
      marginBottom: 15
    },
    label: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 15
    },
    helperText: {
      fontSize: 11,
      fontWeight: 400,
      marginTop: 5
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left'
    },
    textField: {
      '& .Mui-disabled': {
        backgroundColor: '#888888',
        opacity: 0.2
      }
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    star: {
      color: '#3987CF',
      marginLeft: 2
    },
    unifiedPricesLabel: {
      fontSize: 12,
      fontWeight: 600,
      color: '#1F364D'
    },
    header: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '21.6px',
      margin: '10px 10px 30px 10px'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    },
    syncStart: {
      marginTop: 20
    }
  })
)
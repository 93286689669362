import React from 'react'

import { Info } from '@material-ui/icons'
import { useTheme } from '@material-ui/core/styles'
import { Palette } from '@material-ui/core/styles/createPalette'

import { SyncLogsButtonBase } from './SyncLogsButtonBase'

import { useApi } from '../../hooks/storeHook'

import { integrationTypeNames } from '../../types/integrationTypeUtils'
import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'

export type SyncLogsTypeProps = {
  type: IntegrationType.MoySklad
  accountId?: undefined
} | {
  type: Exclude<IntegrationType, IntegrationType.MoySklad>
  accountId: number
}

export type SyncLogsExtraIdProps = {
  productId: number
  orderId?: undefined
} | {
  orderId: number
  productId?: undefined
}

export type SyncLogsButtonProps =
  SyncLogsTypeProps
  & SyncLogsExtraIdProps
  & {
  themed?: boolean
}

export const SyncLogsButton =
  ({type, themed, accountId, productId, orderId}: SyncLogsButtonProps) => {
    const api = useApi()

    const loadLogs =
      async () => {
        if (!productId && !orderId) {
          throw new Error('Either productId or orderId is required')
        }

        return await api.userSync.syncLogs({integration: type, accountId, productId, orderId})
      }

    return <SyncLogsButtonBase
      title={`${integrationTypeNames[type]} #${productId ?? orderId}`}
      icon={<SyncLogsIcon themed={themed} type={type}/>}
      loadLogs={loadLogs}
    />
  }

const SyncLogsIcon =
  ({themed, type}: Pick<SyncLogsButtonProps, 'themed' | 'type'>) => {
    const {palette} = useTheme()

    // NOTE: The i symbol in the icon is transparent, hard to recolor
    return (
      <Info
        color={themed ? undefined : 'action'}
        htmlColor={themed ? getThemedIconColor(palette, type) : undefined}
      />
    )
  }

const getThemedIconColor =
  (palette: Palette, type: IntegrationType): string => {
    switch (type) {
      case IntegrationType.MoySklad:
        return '#3987CF'

      case IntegrationType.Ozon:
        return '#3987CF'

      case IntegrationType.Wildberries:
        return '#3987CF'

      case IntegrationType.YandexMarket:
        return '#3987CF'

      default:
        throw new Error(`Unhandled IntegrationType: ${type}`)
    }
  }
import React from 'react'
import { observer } from 'mobx-react-lite'

import { StoresFormDirect } from './StoresFormDirect'
import { AccountStoresServerData } from './AccountStoresForm'
import { StoresFormSumFromMoySklad } from './StoresFormSumFromMoySklad'
import { StoresFormSplitFromMoySklad } from './StoresFormSplitFromMoySklad'

import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'
import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { EditStoresModel } from '../server/mpsklad_core/Models/EditStoresModel'
import { StoreRelationType } from '../server/mpsklad_core/Entity/Base/StoreRelationType'

export type WarehouseKey = number | string

export type WarehouseKeyProps<TWarehouse extends WarehouseModel> = {
  whKeySelector: (warehouse: TWarehouse) => WarehouseKey

  storeWhKeySelector: (store: EditStoreModel) => WarehouseKey
}

export type WarehouseDisplayProps<TWarehouse extends WarehouseModel> = {
  formatWarehouseName: (warehouse: TWarehouse) => string

  formatWarehouseDisplayName: (warehouse: TWarehouse) => string
}

export type AccountStoresInnerProps<TWarehouse extends WarehouseModel> =
  WarehouseKeyProps<TWarehouse>
  & Pick<WarehouseDisplayProps<TWarehouse>, 'formatWarehouseDisplayName'>
  & {
  disabled: boolean

  storeType: NavStoreType

  serverData: AccountStoresServerData<TWarehouse>

  formData: EditStoresModel

  onImport: (warehouse: TWarehouse) => Promise<void>
}

export const StoresFormByRelation =
  observer(
    <TWarehouse extends WarehouseModel>
    (props: AccountStoresInnerProps<TWarehouse>) => {
      switch (props.formData.relationType) {
        case StoreRelationType.Direct:
          return <StoresFormDirect {...props}/>

        case StoreRelationType.SumFromMoySklad:
          return <StoresFormSumFromMoySklad {...props}/>

        case StoreRelationType.SplitFromMoySklad:
          return <StoresFormSplitFromMoySklad {...props}/>

        default:
          throw new Error(`Unprocessed value of StoreRelationType: ${props.formData.relationType}.`)
      }
    })
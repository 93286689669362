import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, TextField } from '@material-ui/core'

import { MyServerTable } from './MyServerTable'

import { useStore } from '../hooks/storeHook'

import { useMoySkladProductColumns } from '../types/moySkladColumns'
import { makeStyles } from '@material-ui/core/styles'

export const MoySkladProductsTable =
  observer(() => {
    const {syncStore: {moySkladAccount}, productStore: {moySkladProducts}} = useStore()
    const msProductColumns = useMoySkladProductColumns()

    const classes = useStyles()

    if (moySkladAccount == null) {
      return null
    }

    return (
      <>
        <Box marginY={2} marginLeft={2} display="inline-flex">
          <Box>
            <TextField
              id="search-ms"
              size="small"
              variant="outlined"
              label="Поиск"
              helperText="Id / GUID / Код / Артикул / Наименование / Штрихкод"
              onChange={e => moySkladProducts.setGlobalFilter(e.target.value)}
              InputProps={{
                className: classes.input
              }}/>
          </Box>
        </Box>

        <MyServerTable
          tableProps={{size: 'small'}}
          table={moySkladProducts}
          columns={msProductColumns}
          labelNoRows="Нет товаров"
          labelNotFound="Товары не найдены"
          labelRowsPerPage="Товаров на странице:"
        />
      </>
    )
  })

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 12
  }
}))
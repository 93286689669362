import { SyncLogsScreenParams } from '../components/SyncLogsScreen'

import { exactKeyOf } from './tsUtils'
import { createRoute, createRouteWithChildren, RoutesPath } from './routeUtils'

import { MoySkladAppScreenParams } from '../types/moySkladAppTypes'
import { SideBarTab } from '../types/navStore'

const moySkladAppRouteBase = '/moySkladApp'

const moySkladAppRoute = {
  template: `${moySkladAppRouteBase}/:${exactKeyOf<MoySkladAppScreenParams>()('appType')}`
} as const

export const appRoutes = {
  Home: createRouteWithChildren('/products')('ozon', 'wildberries', 'yandexmarket', 'moySklad'),
  Diffs: '/diffs',
  // TODO: Children with parameters - integration, accountId. Use the new route creator.
  //  How would the base work?
  Orders: createRouteWithChildren('/orders')('ozon', 'wildberries', 'yandexmarket', 'moySklad'),
  Sync: createRouteWithChildren('/sync')('ozon', 'wildberries', 'yandexmarket', 'moySklad', 'status'),
  Settings: createRouteWithChildren('/settings')('ozon', 'wildberries', 'yandexmarket', 'moySklad'),
  Print: createRouteWithChildren('/print')('ozon', 'wildberries', 'yandexmarket'),
  Import: createRouteWithChildren('/import')('ozon', 'wildberries', 'yandexmarket'),
  Account: '/account',
  AdminUsers: '/admin/users',
  // TODO: Replace camelCase with kebab-case for better readability?
  //  MS app is hardcoded in the descriptor
  //  Add redirects for backward compatibility
  SyncLogs: createRoute<SyncLogsScreenParams>()`/syncLogs/${'integration'}/${'accountId'}`,
  MoySkladApp: moySkladAppRoute,
  MoySkladAppOld: moySkladAppRouteBase,
  StatusOld: '/status'
} as const

export const appAuthRoutes = {
  Login: '/login',
  Register: '/register',
  PasswordReset: '/password-reset',
  MoySkladApp: moySkladAppRoute,
  MoySkladAppOld: moySkladAppRouteBase
} as const

export const isMoySkladAppRoute = () =>
  window.location.pathname.toLowerCase().startsWith(moySkladAppRouteBase.toLowerCase())

export type AppRoutePath = RoutesPath<typeof appRoutes>

export type AppAuthRoutePath = RoutesPath<typeof appAuthRoutes>

export const accountNavigationLinks = [
  {route: appRoutes.Home.base, tab: SideBarTab.Products},
  {route: appRoutes.Orders.base, tab: SideBarTab.Orders},
  {route: appRoutes.Settings.base, tab: SideBarTab.Configuration},
  {route: appRoutes.Print.base, tab: SideBarTab.Print},
  {route: appRoutes.Import.base, tab: SideBarTab.Import}
]
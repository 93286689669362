import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@material-ui/core'
import { Switch } from 'react-router-dom'

import { useStore } from '../../hooks/storeHook'
import { useRedirectToSubRoute } from '../../hooks/useRedirectToSubRoute'

import { appRoutes } from '../../common/appRoutes'

import { AppRoute } from '../AppRoute'
import { WildberriesPrintPage } from './Wildberries/WildberriesPrintPage'
import { OzonPrintPage } from './Ozon/OzonPrintPage'
import { YandexMarketPrintPage } from './YandexMarket/YandexMarketPrintPage'

import { StoreType } from '../../server/mpsklad_core/Entity/StoreType'

export const PrintRoute = observer(() => {
  const {allowedIntegrations} = useStore()

  useRedirectToSubRoute(appRoutes.Print.base)

  return (
    <div className="body_wrapper_zero">
      <Box flex={1}>
        <Switch>
          {
            allowedIntegrations[StoreType.YandexMarket] &&
            <AppRoute
              path={appRoutes.Print.yandexmarket}
              component={YandexMarketPrintPage}/>
          }

          {
            allowedIntegrations[StoreType.Wildberries] &&
            <AppRoute path={appRoutes.Print.wildberries}
                      component={WildberriesPrintPage}/>
          }

          {
            allowedIntegrations[StoreType.Ozon] &&
            <AppRoute path={appRoutes.Print.ozon}
                      component={OzonPrintPage}/>
          }
        </Switch>
      </Box>
    </div>
  )
})
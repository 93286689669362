import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Box, CircularProgress } from '@material-ui/core'

import { required } from '../../../common/objectUtils'
import { exact } from '../../../common/tsUtils'
import { appRoutes } from '../../../common/appRoutes'
import { useLocalObservableOptional } from '../../../common/mobxUtils'
import { useLazyEffect } from '../../../hooks/commonHooks'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { useMessages } from '../../../hooks/snackbarHooks'

import { settingsRoutes } from '../settingsRoutes'

import { StoreAccountMsData } from '../../MoySkladDataForm'
import { RouteTabItem } from '../../RouteTabItem'
import { RoutingTabs } from '../../RoutingTabs'
import { YandexMarketApiSettings } from './YandexMarketApiSettings'
import { CompanySettings } from '../CompanySettings'
import { YandexMarketOrdersSettings } from './YandexMarketOrdersSettings'
import { PricesSettings } from '../PricesSettings'
import { AdditionalPropertiesSettings } from '../AdditionalPropertiesSettings'
import { YandexMarketReturnsSettings } from './YandexMarketReturnsSettings'
import { CommissionsSettings } from '../CommissionsSettings'

import { StoreType } from '../../../server/mpsklad_core/Entity/StoreType'
import { EditYandexMarketAccountModel } from '../../../server/mpsklad_core/Models/EditYandexMarketAccountModel'
import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditStoreAccountMsDataModel } from '../../../server/mpsklad_core/Models/EditStoreAccountMsDataModel'
import { MoySkladAttributeModel } from '../../../server/mpsklad_core/Models/MoySkladAttributeModel'
import { MoySkladApiEntityOption } from '../../../server/mpsklad_core/Models/MoySkladApiEntityOption'
import { YandexMarketModelType } from '../../../server/mpsklad_core/Entity/YandexMarket/Enums/YandexMarketModelType'
import { RouteItem } from '../../../common/routeItem'

export const YandexMarketSettingsPage = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const api = useApi()
  const history = useHistory()
  const {showSuccess, showError} = useMessages()

  const accountId = store.getHomeNav()?.accountId
  const account = logic.tryGetYandexMarketAccount(accountId)

  const msContainer = useLocalObservableOptional<StoreAccountMsData>()

  const [msStores, setMsStores] = useState<MoySkladApiEntityOption[]>([])

  const [msAttributes, setMsAttributes] = useState<MoySkladAttributeModel[] | null>(null)

  const availableTabs: RouteItem[] = useMemo(() => {
    return accountId ? settingsRoutes : settingsRoutes.map((route: RouteItem, index) => ({
      ...route,
      isDisabled: index !== 0
    }))
  }, [accountId])

  const routes = useMemo((): RouteTabItem[] => {
    return (availableTabs)
      .map((_) => ({title: _.title, route: _.route, isDisabled: _.isDisabled}))
  }, [availableTabs])

  useEffect(() => {
    if (history.location.pathname === appRoutes.Settings.yandexmarket) {
      history.push(`${appRoutes.Settings.yandexmarket}/${routes[0].route}`)
    }
  })

  const loadMsStores = async () => {

    try {
      const msStores = await api.userSync.getMoySkladStores()

      setMsStores(msStores)
    } catch (e) {
      console.error('Failed to load', e)
    }
  }

  const loadMoySkladData = async () => {
    try {
      const {organizations, counterparties, contracts, salesChannels, projects} =
        await api.userSync.getMoySkladData(StoreType.YandexMarket, account?.id)

      if (organizations.length === 0) {
        showError('Нет организаций в МоёмСкладе')
        return
      }

      if (counterparties.length === 0) {
        showError('Нет контрагентов в МоёмСкладе с типом "Юридическое лицо"')
        return
      }

      msContainer.dataOrNull = {
        organization: {
          options: organizations,
          value: organizations.find(_ => _.id === account?.msOrganization) ?? organizations[0],
          searchTerm: '',
          isSearchLoading: false
        },
        counterparty: {
          options: counterparties,
          value: counterparties.find(_ => _.id === account?.msCounterparty) ?? counterparties[0],
          searchTerm: '',
          isSearchLoading: false
        },
        contract: {
          options: contracts,
          value: contracts.find(_ => _.id === account?.msContractId) ?? null,
          searchTerm: '',
          isSearchLoading: false
        },
        salesChannel: {
          options: salesChannels,
          value: salesChannels.find(_ => _.id === account?.msSalesChannelId) ?? null,
          searchTerm: '',
          isSearchLoading: false
        },
        project: {
          options: projects,
          value: projects.find(_ => _.id === account?.msProjectId) ?? null,
          searchTerm: '',
          isSearchLoading: false
        },
        get formData() {
          return {
            msOrganization: this.organization.value.id,
            msCounterparty: this.counterparty.value.id,
            msContractId: this.contract.value?.id,
            msSalesChannelId: this.salesChannel.value?.id,
            msProjectId: this.project.value?.id
          }
        }
      }
    } catch (e) {
      console.error('Failed to get MoySklad data', e)
      showError('Не удалось загрузить данные из МоегоСклада')
    }
  }

  const onSubmit =
    async (data: EditStoreAccountMsDataModel) => {

      try {
        const formData =
          exact<Omit<EditYandexMarketAccountModel, 'id'>>({
            campaignId: account?.campaignId ?? 0,
            userCode: store.syncStore.yandexMarketUserCode ?? 0,
            usingUnifiedPrices: account?.usingUnifiedPrices ?? false,
            isFBS: account?.isFBS ?? false,
            modelType: account?.modelType ?? YandexMarketModelType.None,
            isUpdatePrices: account?.isUpdatePrices ?? false,
            isGeneratingPayments: account?.isGeneratingPayments ?? false,
            isUsingCommissions: account?.isUsingCommissions ?? false,
            ordersCommissionsRecording: account?.ordersCommissionsRecording
                                        ?? CommissionsRecordingType.Nothing,
            documentsCommissionsRecording: account?.documentsCommissionsRecording
                                           ?? CommissionsRecordingType.Nothing,
            name: account?.name || undefined,
            msSalesReturnStoreId: account?.msSalesReturnStoreId,
            msData: data
          })

        if (account) {
          await logic.editYandexMarketAccount({id: account.id, ...formData})
        } else {
          await logic.createYandexMarketAccount({id: 0, ...formData})
        }

        showSuccess('Сохранено!')
      } catch (e) {
        console.error('Failed to save YandexMarket account', e)
      } finally {
      }
    }

  const loadMsAttributes = async () => {
    try {
      const msAttr = await api.userSync.getMoySkladAttributes()
      setMsAttributes(msAttr)
    } catch (e) {
      console.error('Failed to get MoySklad attrs', e)
      showError('Не удалось загрузить')
    }
  }

  useLazyEffect(() => {
    setTimeout(loadMoySkladData)
    setTimeout(loadMsAttributes)
    setTimeout(loadMsStores)
  }, [accountId])

  if (!msContainer.dataOrNull) {
    return <Box
      width="calc(100vw - 240px)" height="100vh" display="flex" alignItems="center"
      justifyContent={'center'}>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <CircularProgress/>
        <p>Загружаем данные из Моего Склада, подождите!</p>
      </Box>
    </Box>
  }

  return <>
    <RoutingTabs
      routeTabs={routes}
      baseRoute={appRoutes.Settings.yandexmarket}
      subRoute={logic.getSubRoute(store.getHomeNav()?.storeType)}>
      <Switch>
        <Route path={`${appRoutes.Settings.yandexmarket}/api`} render={() => <YandexMarketApiSettings/>}/>
        {account &&
         <>
           <Route
             path={`${appRoutes.Settings.yandexmarket}/company`}
             render={() =>
               <CompanySettings
                 msData={msContainer.data}
                 onChange={(a) => onSubmit(a)}/>}
           />
           <Route
             path={`${appRoutes.Settings.yandexmarket}/orders`}
             render={() =>
               <YandexMarketOrdersSettings account={account}/>}
           />
           <Route
             path={`${appRoutes.Settings.yandexmarket}/warehouses`}
             render={() =>
               <YandexMarketOrdersSettings account={account}/>}
           />

           <Route
             path={`${appRoutes.Settings.yandexmarket}/returns`}
             render={() =>
               <YandexMarketReturnsSettings msStores={msStores}/>}
           />
           <Route
             path={`${appRoutes.Settings.yandexmarket}/commissions`}
             render={() =>
               <CommissionsSettings account={account}/>
             }
           />
           <Route
             path={`${appRoutes.Settings.yandexmarket}/prices`}
             render={() =>
               <PricesSettings
                 account={account}
                 onCheckPrices={api.prices.checkPriceActivationYandexMarket}
                 onEnablePriceSync={api.prices.enablePriceSyncYandexMarket}
                 onForceUpdatePrices={() => api.userSync.updatePricesYandexMarket(required(account).id)}
               />}
           />
           <Route
             path={`${appRoutes.Settings.yandexmarket}/additional`}
             render={() =>
               <AdditionalPropertiesSettings
                 msAttributes={required(msAttributes)}
                 onCreate={(createdAttr: MoySkladAttributeModel) => setMsAttributes(
                   [...required(msAttributes), createdAttr])}/>}/>
         </>
        }
      </Switch>
    </RoutingTabs>
  </>
})
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../hooks/storeHook'

import { Box, Grid } from '@material-ui/core'

import { MoySkladSyncInfo } from '../../MoySkladSyncInfo'
import { MoySkladAccountForm } from '../../MoySkladAccountForm'
import { MoySkladStatusMapPanel } from '../../MoySkladStatusMapPanel'
import { MoySkladWebhookPanel } from '../../MoySkladWebhookPanel'

export const MoySkladSettingsScreen =
  observer(
    () => {
      const {hasMoySkladApp} = useStore()

      return (
        <Box padding={3} style={{width: 'calc(100vw - 320px)'}}>
          <Grid container direction="column">
            <Grid item lg={7} xl={7}>
              <MoySkladSyncInfo/>
            </Grid>

            {
              !hasMoySkladApp &&
              <Grid item lg={7} xl={7}>
                <MoySkladAccountForm/>
              </Grid>
            }

            <Grid item lg={7} xl={7}>
              <MoySkladStatusMapPanel/>
            </Grid>

            <Grid item lg={7} xl={7}>
              <MoySkladWebhookPanel/>
            </Grid>

          </Grid>
        </Box>
      )
    })
import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { BoxLoader } from './BoxLoader'

import { useStore } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'

import { required } from '../common/objectUtils'

import { integrationTypeNames } from '../types/integrationTypeUtils'
import { AccountOptionalProps, StoreAccountRequiredProps } from '../types/accountProps'

import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'

export interface StoreScreenTabsBaseProps<TAccount extends StoreAccountModelBase> {
  canAddAccount?: boolean

  canRemoveAccount?: boolean,

  ScreenComponent: FunctionComponent<StoreAccountRequiredProps<TAccount>>
}

export interface StoreScreenTabsProps<TAccount extends StoreAccountModelBase>
  extends StoreScreenTabsBaseProps<TAccount> {
  storeType: IntegrationType.Ozon | IntegrationType.Wildberries | IntegrationType.YandexMarket

  storeAccounts: TAccount[]

  onRemoveAccount: (accountId: number) => Promise<void>

  makeAccountName: (account: TAccount) => string

  AddAccountComponent: FunctionComponent<AccountOptionalProps<TAccount>>
}

export const StoreScreenTabs =
  observer(
    <TAccount extends StoreAccountModelBase>
    ({
       storeType, storeAccounts,
       canAddAccount, ScreenComponent
     }: StoreScreenTabsProps<TAccount>) => {
      const {syncStore: {moySkladAccount}, getHomeNav} = useStore()

      const [isDeleting] = useBoolState()

      const homeNav = getHomeNav()

      if (homeNav == null || !homeNav.accountId) {
        return <BoxLoader text="Не выбран аккаунт"/>
      }

      if (moySkladAccount == null) {
        return <Box padding={3}>Добавьте аккаунт МоегоСклада для работы с маркетплейсами.</Box>
      }

      if (!canAddAccount && storeAccounts.length === 0) {
        return <Box padding={3}>У вас нет аккаунтов {integrationTypeNames[storeType]}.</Box>
      }

      if (isDeleting) {
        return <BoxLoader text="Аккаунт удаляется. Пожалуйста, подождите."/>
      }

      return (
        <ScreenComponent
          key={homeNav.accountId}
          account={required(storeAccounts.find(_ => _.id === homeNav.accountId))}
        />
      )
    })
import React from 'react'
import { TablePagination2 } from './TablePagination'

import { ITableData } from '../types/tableTypes'

type OrdersTableProps<TRow extends object, TColumnId> = {
  table: ITableData<TRow, TColumnId>
}
export const OrdersTopPagination =
  <TRow extends object, TColumnId>
  ({table}: OrdersTableProps<TRow, TColumnId>) => {

    return (
      <div
        style={{
          maxWidth: 'calc(100vw - 40px)',
          position: 'absolute',
          left: 0,
          top: '140px',
          width: '100%'
        }}
      >
        <TablePagination2
          table={table}
          labelRowsPerPage="Заказов на странице:"
          style={{
            position: 'absolute',
            top: 0,
            right: 90,
            color: '#888888',
            fontFamily: 'Roboto Regular',
            fontSize: 10
          }}
        />
      </div>
    )
  }
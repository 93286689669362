import React from 'react'
import { observer } from 'mobx-react-lite'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  TablePagination as PaperTablePagination,
  TablePaginationProps as PaperTablePaginationProps
} from '@material-ui/core'
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination/TablePagination'

import { TablePaginationHelpers, tableRowsPerPageOptions } from '../hooks/tableHooks'

import { fixMsAppScrollProps } from '../common/msAppUtils'

import { ITableData } from '../types/tableTypes'

export type TablePaginationProps =
// NOTE: Omit breaks these types
  Pick<PaperTablePaginationProps, 'count' | 'labelRowsPerPage' | 'labelDisplayedRows'>
  & {
  pagination: TablePaginationHelpers
}

export const TablePagination =
  ({pagination, ...passProps}: TablePaginationProps) => {
    const classes = useStyles()

    return (
      <PaperTablePagination
        component="div"
        page={pagination.page}
        rowsPerPage={pagination.rowsPerPage}
        rowsPerPageOptions={tableRowsPerPageOptions}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        classes={{spacer: classes.paginationSpacer}}
        SelectProps={fixMsAppScrollSelectProps}
        {...passProps}
      />
    )
  }

export type TablePagination2Props<TRow extends object, TColumnId> =
// NOTE: Omit breaks these types
  Pick<PaperTablePaginationProps, 'labelRowsPerPage' | 'classes' | 'className' | 'style'>
  & {
  table: ITableData<TRow, TColumnId>
}

export const TablePagination2 =
  observer(
    <TRow extends object, TColumnId>
    ({
       table: {filteredRowCount, pageIndex, pageSize, setPageIndex, setPageSize},
       ...passProps
     }: TablePagination2Props<TRow, TColumnId>) => {
      const classes = useStyles()

      return <PaperTablePagination
        component="div"
        page={pageIndex}
        count={filteredRowCount}
        rowsPerPage={pageSize}
        rowsPerPageOptions={tableRowsPerPageOptions}
        labelDisplayedRows={labelDisplayedRows}
        onPageChange={(_, page) => setPageIndex(page)}
        onRowsPerPageChange={e => setPageSize(parseInt(e.target.value))}
        SelectProps={fixMsAppScrollSelectProps}
        className={'table_pagination_2'}
        classes={{toolbar: classes.paginationToolbar}}
        {...passProps}
      />
    }
  )

const labelDisplayedRows =
  ({from, to, count}: LabelDisplayedRowsArgs) =>
    `${from}-${to} из ${count}`

const fixMsAppScrollSelectProps = {MenuProps: fixMsAppScrollProps}

const useStyles =
  makeStyles(() =>
    createStyles({
      paginationSpacer: {
        display: 'none'
      },
      paginationToolbar: {
        padding: 0
      }
    }))
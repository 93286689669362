import React from 'react'
import { observer } from 'mobx-react-lite'

import { Sync } from '@material-ui/icons'
import { Box, Button, CircularProgress } from '@material-ui/core'

import { StatusSyncTable } from './StatusSyncTable'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

export const StatusScreen =
  observer(() => {
    const logic = useLogic()

    const {showSuccess} = useMessages()

    const [isRefreshing, setRefreshing, setRefreshed] = useBoolState()

    // No loader, silent update
    useLazyEffect(logic.loadUserAccounts)

    const onRefreshClick = async () => {
      try {
        setRefreshing()
        const {
          productsSuccess, productsTotal,
          pushMsSuccess, pushMsTotal,
          pullStocksSuccess, pullStocksTotal
        } = await logic.syncUser()

        showSuccess(`Получение остатков и заказов: ${pullStocksSuccess}/${pullStocksTotal}`)
        showSuccess(`Отправка остатков: ${pushMsSuccess}/${pushMsTotal}`)
        showSuccess(`Получение товаров: ${productsSuccess}/${productsTotal}`)
      } finally {
        setRefreshed()
      }
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="flex-start" marginBottom={2} marginLeft={4}>
        <Box margin={1} marginBottom={2}>
          <StatusSyncTable/>
        </Box>

        <Box>
          {
            isRefreshing
            ? <CircularProgress title="Синхронизируем..." size={20}/>
            :
            <Button
              variant="contained"
              color="secondary"
              onClick={onRefreshClick}
              disabled={isRefreshing}
              startIcon={<Sync/>}
            >
              Синхронизировать
            </Button>
          }
        </Box>
      </Box>
    )
  })
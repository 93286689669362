import { makeStyles } from '@material-ui/core/styles'

export const useDefaultMenuItemStyles = makeStyles(() => ({
  menuItem: {
    fontSize: '14px',
    fontFamily: 'Roboto Regular',
    fontWeight: 400,
    lineHeight: '12px',
    textAlign: 'left',
    backgroundColor: '#FFF !important',
    color: '#888888',
    '&:hover': {
      color: '#4A7DAC',
      backgroundColor: '#FFF'
    },
    '&.Mui-selected': {
      color: '#3987CF'
    },
    '&[aria-selected="true"]': {
      color: '#3987CF'
    }
  },
  iconButtonOutlined: {
    border: '1px solid #D2D2D2',
    borderRadius: '8px',
    height: 40,
    width: 40
  }
}))
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { useApi, useStore } from '../../../hooks/storeHook'
import { ImportScreen } from '../../ImportScreen'
import { WbProductImportTable } from '../../WbProductImportTable'

import { WbProductsTableData } from '../../../store/productStore'

import { WbProductColumnId } from '../../../types/wbColumns'
import { WbProductModel } from '../../../server/mpsklad_core/Models/WbProductModel'

export const WildberriesImportPage =
  observer(
    () => {
      const api = useApi()
      const {productStore: {createWbProductsTable}, getHomeWbAccount} = useStore()

      const account = getHomeWbAccount()

      const products = useMemo(
        () => createWbProductsTable(account.id),
        [createWbProductsTable, account])

      return (
        <ImportScreen<WbProductsTableData, WbProductModel, WbProductColumnId>
          account={account}
          type="Wildberries"
          importProductsFunc={api.import.importProductsWb}
          importStocksFunc={api.import.importStocksWb}
          products={products}
          TableComponent={WbProductImportTable}
        />
      )
    })
import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, TextField } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'

export const RedesignPasswordForm =
  observer(() => {
    const classes = useStyles()

    const logic = useLogic()
    const {showSuccess, showError} = useMessages()

    const [password, setPassword] = useState('')

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()

        const allowRedesign = await logic.requestRedesign(password)

        if (allowRedesign) {
          showSuccess('Доступ разрешён!')
        } else {
          showError('Неправильный пароль')
        }
      }

    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <form onSubmit={onSubmit}>
          <TextField
            type="password"
            autoComplete="none"
            id="redesign-password"
            label="Пароль"
            onChange={e => setPassword(e.target.value)}
          />

          <button type="submit" className={clsx(classes.submitButton, 'default-button')}>
            <p>Отправить</p>
          </button>
        </form>
      </Box>
    )
  })

const useStyles = makeStyles(
  () => ({
    submitButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    }
  }))
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Box, Typography, Link } from '@material-ui/core'

export const Footer = () => {
  const classes = useStyles()

  return (
    <Box className={classes.footerContainer}>
      <Box className={classes.footerSection}>
        <Typography className={classes.text}>Наша инструкция:</Typography>
        <Link href="https://help.mp360.ru/" target="_blank" rel="noopener"
              className={classes.link}>https://help.mp360.ru/</Link>
      </Box>
      <Box className={classes.footerSection}>
        <Typography className={classes.text}>Следите за нашими новостями:</Typography>
        <Link href="https://t.me/mp360_news" target="_blank" rel="noopener" className={classes.link}>
          @mp360_news
        </Link>
      </Box>
      <Box className={classes.footerSection}>
        <Typography className={classes.text}>Мы в telegram: <Link href="https://t.me/mpsklad2023" target="_blank"
                                                                  rel="noopener"
                                                                  className={classes.link}>@mpsklad2023</Link></Typography>
        <Typography className={classes.text}>Мы в whatsApp: <Link href="tel:+79990944959"
                                                                  className={classes.link}>+79990944959</Link></Typography>
        <Typography className={classes.text}>Телефон: <Link href="tel:88005553449" className={classes.link}>8 (800)
          555-34-49</Link></Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap'
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(20),
    marginBottom: theme.spacing(2)
  },
  link: {
    fontFamily: 'Roboto Regular',
    marginBottom: theme.spacing(0.5),
    color: '#4A7DAC',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    fontSize: 13
  },
  text: {
    fontFamily: 'Roboto Regular',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 13
  }
}))
import React, { ChangeEvent, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import uniqueId from 'lodash/uniqueId'

import { CircularProgress } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { AccountModelBase } from '../server/mpsklad_core/Models/AccountModelBase'
import { IOSSwitch } from './IOSSwitch'

export type AccountSyncSwitchProps<TAccount extends AccountModelBase> = {
  account: TAccount

  setSync: (syncEnabled: boolean, accountId: number) => Promise<void>

  disabled?: boolean
}

export const AccountSyncSwitch =
  observer(
    <TAccount extends AccountModelBase>
    ({account, setSync, disabled}: AccountSyncSwitchProps<TAccount>) => {
      const {confirmSyncEnable, confirmSyncDisable} = useLogic()

      const {showSuccess, showError} = useMessages()
      const [isSubmitting, setSubmitting, setFinished] = useBoolState()

      const switchName = useRef(uniqueId('StoreAccountSyncSwitch_')).current

      const onSyncChange =
        async (e: ChangeEvent<HTMLInputElement>) => {
          if (disabled) {
            return
          }

          const syncEnabled = e.target.checked

          if (syncEnabled && !await confirmSyncEnable()) {
            return
          }

          if (!syncEnabled && !await confirmSyncDisable()) {
            return
          }

          setSubmitting()

          try {
            await setSync(syncEnabled, account.id)
            showSuccess(`Синхронизация ${syncEnabled ? 'включена' : 'отключена'}`)
          } catch (e) {
            console.log('Failed to toggle store sync', e)
            showError('Ошибка при сохранении')
          } finally {
            setFinished()
          }
        }

      return isSubmitting
             ? <CircularProgress size={19} title="Переключаем..."/>
             : <IOSSwitch
               color="primary"
               name={switchName}
               title={`${account.syncEnabled ? 'Отключить' : 'Включить'} синхронизацию`}
               disabled={disabled}
               checked={account.syncEnabled}
               onChange={onSyncChange}
               onClick={e => e.stopPropagation() /* prevent tab switching */}
             />
    })